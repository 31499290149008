
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Autocomplete
} from "@mui/material";
import { Formik,  } from "formik";
import * as yup from "yup";
import * as React from "react";
import { useSelector } from "react-redux";



const itemUnitSchema = yup.object().shape({
destination_details: yup.string().required("required"),
team: yup.string().required("required"),
driver_or_alternative: yup.string().required("required"),
remarks: yup.string()
});


const TravelMatrixDetailForm = (props) => {

  const {index, 
      data,
      handleRemoveItemRow, 
      setItemDetailFormSubmit, 
      handleDestinationDetailsChange,
      handleTeamChange,
      handleDriverOrAlternativeChange,
      handleRemarksChange
      } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  
 

  const initialValuesPurchase = {
      destination_details: data.destination_details,
      team: data.team,
      driver_or_alternative: data.driver_or_alternative,
      remarks: data.remarks
      };
  

  const handleFormSubmit = async (values, onSubmitProps) => {
      setItemDetailFormSubmit(values);
     
  };

  return (
          
      <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValuesPurchase}
          validationSchema={itemUnitSchema}
          
      >
          {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          }) => (
          <form onSubmit={handleSubmit}>
              <Box
                  sx={{
                      display: "flex",
                      flexDirection: "row",
                      width:"100%"
                  }}
              >
                  <Box >
                      <p >#{index+1}</p>
                  </Box>
                  <Box
                      display="grid"
                      gap="30px"
                      p="0.5rem"
                      gridTemplateColumns="repeat(14, minmax(0, 1fr))"
                      sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                      }}
                  >
                      <>
                          <TextField
                              label="DESTINATION, ACTIVITY AND DATES"
                              size="small"
                              multiline
                              rows={4}
                              onBlur={handleBlur}
                              onChange={(e)=>{
                                  handleChange(e);
                                  handleDestinationDetailsChange(e,index);
                              }}
                              value={values.destination_details}
                              name="destination_details"
                              error={Boolean(touched.destination_details) && Boolean(errors.destination_details)}
                              helperText={touched.destination_details && errors.destination_details}
                              sx={{ gridColumn: "span 3" }}
                          />

                          <TextField
                              label="TEAM"
                              size="small"
                              multiline
                              rows={4}
                              onBlur={handleBlur}
                              onChange={(e)=>{
                                  handleChange(e);
                                  handleTeamChange(e,index);
                              }}
                              value={values.team}
                              name="team"
                              error={Boolean(touched.team) && Boolean(errors.team)}
                              helperText={touched.team && errors.team}
                              sx={{ gridColumn: "span 3" }}
                          />

                          <TextField
                              label="DRIVER/ALTERNATIVE"
                              size="small"
                              multiline
                              rows={4}
                              onBlur={handleBlur}
                              onChange={(e)=>{
                                  handleChange(e);
                                  handleDriverOrAlternativeChange(e,index);
                              }}
                              value={values.driver_or_alternative}
                              name="driver_or_alternative"
                              error={
                              Boolean(touched.driver_or_alternative) && Boolean(errors.driver_or_alternative)
                              }
                              helperText={touched.driver_or_alternative && errors.driver_or_alternative}
                              sx={{ gridColumn: "span 2" }}
                          /> 

                          <TextField
                              label="ATTACH TRAVEL REQUEST"
                              size="small"
                              select
                              onBlur={handleBlur}
                              onChange={(e)=>{
                                  handleChange(e);
                                  handleRemarksChange(e,index);
                              }}
                              value={values.remarks}
                              name="remarks"
                              error={Boolean(touched.remarks) && Boolean(errors.remarks)}
                              helperText={touched.remarks && errors.remarks}
                              sx={{ gridColumn: "span 3" }}
                          />        

                          <TextField
                              label="REMARKS"
                              size="small"
                              multiline
                              rows={4}
                              onBlur={handleBlur}
                              onChange={(e)=>{
                                  handleChange(e);
                                  handleRemarksChange(e,index);
                              }}
                              value={values.remarks}
                              name="remarks"
                              error={Boolean(touched.remarks) && Boolean(errors.remarks)}
                              helperText={touched.remarks && errors.remarks}
                              sx={{ gridColumn: "span 3" }}
                          />   


                          
                          <Button
                              value={index}
                              onClick={() => {
                                  handleRemoveItemRow(data.data_id);
                              }}                                                     
                              size="small"
                              variant="contained"
                              sx={{ 
                                  // width: "20%",
                                  margin:"20px",
                                }}
                              >
                              Remove
                          </Button>
                      </>
                  {/* )} */}
                  </Box>
              </Box>

              
          </form>
          )}
      </Formik>
                              
                          
         );
};

export default TravelMatrixDetailForm;
