import { Box, useTheme, Grid, Button, Paper, InputBase, TextField, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePaymentRequisitionList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from "@mui/material/Chip";
import moment from "moment";


export default function PaymentRequisitions ({setIsSuccess, projectId}) {
  const theme = useTheme();
  const {paymentRequisitionList, isLoadingPaymentRequisitionList, setNewPaymentRequisitionData, setSearchPaymentRequisition, setUseStatusFilter} = usePaymentRequisitionList(1, projectId,"True");
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState("");
  const[searchContent, setSearchContent] = useState("");
  const token =  useSelector((state) => state.token);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  const handleClickOpenRequisition = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/details/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    navigate("/paymentRequisitionViewForm", { state: response.data.data})
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchPaymentRequisition(searchContent)
  };

  const handleChangeFilter = async (e)=> {
    setUseStatusFilter(e.target.value);
  }

  const renderStatusPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="PENDING" size="small" color="warning" />;
			case 1:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="IN PROGRESS" size="small" color="primary" />;
			case 2:
				return <Chip variant="outlined" sx={{ backgroundColor: "#edfff2", fontSize:"10px", fontWeight:700 }} label="FIXED" size="small" color="success" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffeded", fontSize:"10px", fontWeight:700 }} label="DELAYED" size="small" color="danger" />;
		}
	};


  return (
    <Box>
        <h3>Requisition List</h3>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Requisition Purpose" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xs >
              <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    m:"8px"
                }}
              >
                <h4>Select List Filter:</h4>
                
                  <TextField
                    select
                    defaultValue={false}
                    onChange={(e)=>{ handleChangeFilter(e) }}
                    margin="dense" 
                    variant="standard"
                    sx={{ minWidth: 170, maxWidth:200, marginX:"10px", padding:"0px", backgroundColor: "transparent" }}
                  >
                    <MenuItem value={false}>None</MenuItem>
                    <MenuItem value={1}>Unauthorized</MenuItem>
                    <MenuItem value={2}>Authorized</MenuItem>
                    <MenuItem value={3}>Rejected</MenuItem>
                  </TextField>
            
              </Box>
              
            </Grid>
            {/* <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Make Requisition</Button>
              <ResponsiveDialog
              dialogButtonName = "Add Requisition "
              dialogHeading = "Add a New Requisition"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <ItemForm 
                              setOpen={setOpen} setNewPaymentRequisitionData={setNewPaymentRequisitionData} setIsSuccess={setIsSuccess}
                            />
                          </Box>
                        }
              >
                
              </ResponsiveDialog>
            </Grid> */}
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <TableRow>
                  <TableCell
                        style={{  minWidth: 50}}
                      >
                        No.
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Date Created
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 150 }}
                      >
                        Purpose
                      </TableCell>
                      {/* <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Requisition Domain
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Project
                      </TableCell> */}

                      {/* <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Approval Step
                      </TableCell> */}

                    
                      <TableCell style={{ minWidth: 50 }}>
                        Status
                      </TableCell>

                      <TableCell style={{ textAlign: "center", minWidth: 150  }}>
                        Actions
                      </TableCell>
                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentRequisitionList.length===0  ? (
                    
                      <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <h3>No Data found</h3>
                        </TableCell>
                      </TableRow>
                    
                  ): (paymentRequisitionList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          
                          <TableCell key={row.id}>
                            {row.id}
                          </TableCell>
                          <TableCell key={row.id}>
                            {moment(row.date_added ).format('LL')}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.fields.title}
                          </TableCell>
                          {/* <TableCell key={row.id}>
                            {row.related_project_document.document_domain.document_domain_name}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.related_project_document.related_project.project_name}
                          </TableCell> */}
                          {/* <TableCell key={row.id}>
                            
                          </TableCell> */}
                          <TableCell key={row.id}>
                            {/* {renderStatusPills(row.status)} */}
                          </TableCell>
                            
                            
                          
                            <TableCell style={{ textAlign: "center" }}>
                                <Button value={row.id} size="small" onClick={(e) => handleClickOpenRequisition(e)}>View Details </Button>
                                <Button value={row.id} size="small" disabled>Add to Budget </Button>
                            </TableCell>
                          
                        </TableRow>
                      );
                    }))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paymentRequisitionList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
      </Box>
    </Box>
  );
};