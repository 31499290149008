import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMemoRequestList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";



    const filterOptions = [
      {name:'Supplier'},
    ]

export default function ProjectMemos ({setIsSuccess, projectId}) {
  const theme = useTheme();
  const {memoRequestList, isLoadingMemoRequestList, setNewMemoRequestData, setSearchMemoRequest} = useMemoRequestList(1, projectId)
  const navigate = useNavigate();
  
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")
  const token =  useSelector((state) => state.token);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  const handleClickOpenMemo = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/details/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    navigate("/memoViewForm", { state: response.data.data})
    
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchMemoRequest(searchContent)
  };


  return (
    <Box>
        <h4>Memo List</h4>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Memo Title" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xs >
              {/* <NativeSelectDemo
                inputName = "Filter By"
                itemOptions={filterOptions}
              /> */}
              
            </Grid>
            
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <TableRow>
                      <TableCell
                        style={{  minWidth: 50}}
                      >
                        No.
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Date Created
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 150 }}
                      >
                        Title
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Memo Domain
                      </TableCell>
                    
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Created By
                      </TableCell>

                      <TableCell style={{ textAlign: "center", minWidth: 150 }}>
                        Actions
                      </TableCell>
                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memoRequestList.length===0 ? (
                    
                      <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <h3>No Data found</h3>
                        </TableCell>
                      </TableRow>
                    
                  ): (memoRequestList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          
                            <TableCell key={row.id}>
                              {row.id}
                            </TableCell>
                            <TableCell key={row.id}>
                              {moment(row.date_added ).format('LL')}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.fields.title}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.related_project_document.document_domain.document_domain_name}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.added_by.first_name} {row.added_by.last_name}
                            </TableCell>
                          
                            <TableCell style={{ textAlign: "center" }}>
                                <Button value={row.id} size="small" onClick={(e) => handleClickOpenMemo(e)}>View Details </Button>

                            </TableCell>
                          
                        </TableRow>
                      );
                    }))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={memoRequestList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
      </Box>
    </Box>
  );
};