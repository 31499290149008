
import { Box,Button,TextField,useMediaQuery,MenuItem } from "@mui/material";
import * as React from "react";
import { odmasApi } from "api/pesabackend";
import { useProjectList, useDepartmentStationList } from "FetchData";
  



const ProjectsAttchedDetailForm = (props) => {

    const {index, 
        data,
        projectList,
        token,
        departmentStationList,
        handleRemoveItemRow, 
        handleLevelOfEffortChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [projectRoleList, setProjectRoleList] = React.useState([]);
    const [isLoadingProjectRoleList, setIsLoadingProjectRoleList] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [remainingTotalEffort, setRemainingTotalEffort] = React.useState(100);
    
    const fetchProjectRoleList = async (projectId) => {
        try {
            const response = await odmasApi.get(`/projects/roles?project_id=${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProjectRoleList(response.data.data);
            setIsLoadingProjectRoleList(false);
        } catch (e) {
            setError(true);
            setIsLoadingProjectRoleList(false);
        }
    };
    
    return (
        
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>
                    <TextField
                        label="Project"
                        onBlur={handleBlur}
                        size="small"
                        onChange={(e) =>{ 
                            handleChange(e); 
                            fetchProjectRoleList(e.target.value);
                        }}
                        select
                        value={data.related_project}
                        name={`${name}.related_project`}
                        error={Boolean( touched?.projects_attached && touched?.projects_attached[index]?.related_project) && Boolean(errors?.projects_attached && errors?.projects_attached[index]?.related_project)}
                        helperText={ touched?.projects_attached && touched?.projects_attached[index]?.related_project && errors?.projects_attached && errors?.projects_attached[index]?.related_project}
                        sx={{ gridColumn: "span 3" }}
                    >
                        {projectList.map((row)=>{
                            return(
                                <MenuItem value={row.id} selected={data.related_project===row.id ? true :false }>{row.project_name}</MenuItem>
                                )})
                        }
                        
                    </TextField>


                    <TextField
                        label="Station"
                        size="small"
                        select
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.related_station}
                        name={`${name}.related_station`}
                        error={Boolean( touched?.projects_attached && touched?.projects_attached[index]?.related_station) && Boolean(errors?.projects_attached && errors?.projects_attached[index]?.related_station)}
                        helperText={ touched?.projects_attached && touched?.projects_attached[index]?.related_station && errors?.projects_attached && errors?.projects_attached[index]?.related_station}
                        sx={{ gridColumn: "span 3" }}
                        >
                        {departmentStationList.map((row)=>{
                            return(
                                <MenuItem value={row.id} selected={data.related_station===row.id ? true :false }> {row.station_name}</MenuItem>
                                )})
                        }
                        
                    </TextField> 

                    <TextField
                        label="Project Role"
                        size="small"
                        select
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.related_project_role}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.related_project_role`}
                        error={Boolean( touched?.projects_attached && touched?.projects_attached[index]?.related_project_role) && Boolean(errors?.projects_attached && errors?.projects_attached[index]?.related_project_role)}
                        helperText={ touched?.projects_attached && touched?.projects_attached[index]?.related_project_role && errors?.projects_attached && errors?.projects_attached[index]?.related_project_role}
                        sx={{ gridColumn: "span 3" }}
                    >
                        {projectRoleList.map((row)=>{
                            return(
                                <MenuItem value={row.id} selected={data.related_project_role===row.id ? true :false }>{row.role_name}</MenuItem>
                                )})
                        }
                        
                    </TextField>         
                    <TextField
                        label="Level of Effort(%)"
                        size="small"
                        type="number"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            handleLevelOfEffortChange(e,index);
                        }}
                        value={data.level_of_effort}
                        inputProps={{
                            min: 0,
                            max: remainingTotalEffort
                        }}
                        name={`${name}.level_of_effort`}
                        error={Boolean( touched?.projects_attached && touched?.projects_attached[index]?.level_of_effort) && Boolean(errors?.projects_attached && errors?.projects_attached[index]?.level_of_effort)}
                        helperText={ touched?.projects_attached && touched?.projects_attached[index]?.level_of_effort && errors?.projects_attached && errors?.projects_attached[index]?.level_of_effort}
                        sx={{ gridColumn: "span 2" }}
                    />                              
                    
                    <Button
                        value={index}
                        onClick={() => {
                            handleRemoveItemRow(index);
                        }}                                                     
                        size="small"
                        variant="contained"
                        sx={{ 
                            width: "20%",
                            marginBottom:"20px",
                            }}
                    >
                        Remove
                    </Button>
                </>
            {/* )} */}
            </Box>
        </Box> 
    );
  };
  
  export default ProjectsAttchedDetailForm;
  