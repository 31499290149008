import { Box, useTheme, Grid, Button, Paper, InputBase, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useProjectUserDocumentApprovalList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import moment from "moment";



export default function VerificationRequests () {
  const theme = useTheme();
  const {projectUserDocumentApprovalList, isLoadingProjectUserDocumentApprovalList, setNewProjectUserDocumentApprovalData, setSearchProjectUserDocumentApproval} = useProjectUserDocumentApprovalList(1)
  const navigate = useNavigate();
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")
  const token =  useSelector((state) => state.token);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleClickViewDetails = async (e) => {
    
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/details/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    if (response.status === 200) {

      if (response.data.data.related_document_request.related_project_document.document_type.document_type_name==="MEMO") {
        navigate("/memoApprovalViewForm", { state: response.data.data})
      }

      if (response.data.data.related_document_request.related_project_document.document_domain.document_domain_name==="PAYMENT") {
        navigate("/paymentRequisitionApprovalViewForm", { state: response.data.data})
      }

      if (response.data.data.related_document_request.related_project_document.document_domain.document_domain_name==="PROCUREMENT") {
        navigate("/procurementRequisitionApprovalViewForm", { state: response.data.data})
      }

      if (response.data.data.related_document_request.related_project_document.document_domain.document_domain_name==="TRANSPORT") {
        navigate("/travelRequisitionApprovalViewForm", { state: response.data.data})
      }

    }
    
    
  };

 

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchProjectUserDocumentApproval(searchContent)
  };


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999,
              ml: "10px"
            }}
          > 
               {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
                ) : null
                                                
              }
              
              <h3>Pending Approvals List</h3>
              <Box
                p="1rem"
                m ="1rem"
                borderRadius="0.5rem"
                
              >
                <Grid container spacing={4} className="alignHorizantal">
                  <Grid xs >
                    <Paper
                      sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
                    >
                      <InputBase placeholder="Search Memo Title" 
                        value={searchContent}
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        
                      />
                      <IconButton
                        onClick={handleClickSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Grid>
                  <Grid xs >
                    {/* <NativeSelectDemo
                      inputName = "Filter By"
                      itemOptions={filterOptions}
                    /> */}
                    
                  </Grid>
                  
                </Grid>

              </Box>
            
            <Box>
              <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: "100vh" }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                      <TableHead>
                        <TableRow>
                            
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Date Created
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 150 }}
                            >
                              Title
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Request Type
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Request Domain
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Project
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Station From
                            </TableCell>
                          
                            <TableCell style={{ minWidth: 50 }}>
                              Created By
                            </TableCell>

                            <TableCell style={{ textAlign: "center", minWidth: 150 }}>
                              Actions
                            </TableCell>
                            
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectUserDocumentApprovalList.length===0  ? (
                          
                            <TableRow>
                              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                <h3>No Data found</h3>
                              </TableCell>
                            </TableRow>
                          
                        ): (projectUserDocumentApprovalList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                
                                  
                                  <TableCell key={row.id}>
                                    {moment(row.date_added).format('LL')}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.fields.title}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.is_project_document ? row.related_project_document.document_domain.document_domain_name : 'DEPARTMENT REQUEST'}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.is_project_document ? row.related_project_document.document_type.document_type_name : 'DEPARTMENT DOCUMENT'}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.is_project_document ? row.related_project_document.related_project.project_name : 'N/A'}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.is_project_document ? row.related_project_document.related_region_project.station_name : 'N/A'}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.added_by.first_name} {row.added_by.last_name}
                                  </TableCell>
                                
                                  <TableCell style={{ textAlign: "center" }}>
                                      <Button 
                                        value={row.id} 
                                        size="small" 
                                        onClick={(e) => {
                                        handleClickViewDetails(e);
                                      }}
                                      >
                                          View Details 
                                      </Button>
                                    
                                  </TableCell>
                                
                              </TableRow>
                            );
                          }))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={projectUserDocumentApprovalList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </Paper>
            </Box>
   
          </Box>
        </Box>
    </Box>
    
  );
};