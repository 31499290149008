
import {Box,Button,MenuItem,TextField} from "@mui/material";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {  useState } from "react";
import moment from "moment/moment";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


const LeaveRequestCreateForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const token =  useSelector((state) => state.token);


  const initialFormValues = {
    
    request_date: moment().format('YYYY-MM-DD'),
    related_user: location.state.related_user.id ,
    present_annual_leave_entitlement: location.state.present_annual_leave_entitlement ,
    last_leave_return_date: location.state.last_leave_return_date ? location.state.last_leave_return_date : 'N/A',
    number_of_days_taken: location.state.number_days_taken,
    balance_of_days: location.state.balance_of_days,
    leave_days_required: 0,
    leave_start_date: moment().format('YYYY-MM-DD'),
    leave_end_date: "",
    leave_type: "",
    address_while_on_leave: "",
  
  };

  const formSchema = yup.object().shape({
  
    request_date: yup.date(),
    present_annual_leave_entitlement: yup.number(),
    last_leave_return_date: yup.string(),
    number_of_days_taken: yup.number(),
    balance_of_days: yup.number(),
    leave_days_required: yup.number().required("required").min(1, 'One day is the least you can request').max(initialFormValues.balance_of_days, 'You cannot request more days than the balance days'),
    leave_start_date: yup.date().required("required"),
    leave_end_date: yup.date().required("required"),
    leave_type: yup.string().required("required"),
    address_while_on_leave: yup.string().required("required"),
  
  });

  const [toDate,setToDate] = useState(initialFormValues.leave_end_date);
  const [fromDate,setFromDate] = useState(initialFormValues.leave_start_date);
  const [leaveRequired,setLeaveRequired] = useState(0);
  const [additionalLeave,setadditionalLeave] = useState(initialFormValues.leave_type);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState("");

  const handleToDate = (fromDate, leaveRequired ) =>{
    setToDate(moment(fromDate, "YYYY-MM-DD").add(leaveRequired,'days').format('YYYY-MM-DD'));
  };
 
  

  const saveLeaveRequest = async (values, onSubmitProps) => {
   
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const savedItemResponse = await axios.post(`${API_URL}/requests/leave_request`, JSON.stringify(values), {
      headers: { 
        "Content-Type": "application/json", 
      },
      body: JSON.stringify(values),
    });

    if (savedItemResponse.status===200) {
      // props.setIsSuccess(true);
      onSubmitProps.resetForm();
      navigate(`/myLeaveRequests`);
    }; 

    if (savedItemResponse.status===404){
      setIsFailedError(savedItemResponse.errors)
      setIsFailed(true);
    };
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveLeaveRequest(values, onSubmitProps);
  };
  
  return (
      <Box>
          <Navbar />
          <Box 
              sx={{
                display:"flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
                <Box 
                  sx={{
                    flexGrow: 1
                  }}
                >
                    <Sidebar isSidebar={isSidebar}/>  
                </Box>
                <Box 
                  sx={{
                    flexGrow: 999,
                    paddingX: "40px"
                  }}
                >
                    
                  <h3>Leave Request Form</h3>

                 <Box>
                  <Formik
                      onSubmit={handleFormSubmit}
                      
                      initialValues={initialFormValues}
                      validationSchema={formSchema}
                  >
                      {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      resetForm
                      
                      }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              marginBottom: "20px"
                          }}
                          >
                          
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width:"40%"
                            }}
                          >        
                    
                            <p> <b>Current Request Date:</b> {initialFormValues.request_date}</p>
                            <p> <b>Date of return from last leave:</b>  {initialFormValues.last_leave_return_date}</p>
                            
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                marginTop:"5px"
                                // width: "45%",
                              }}
                            >
                      
                              <label><b>Select Leave Type* </b> </label>
                              <TextField
                                // label="Select Leave Type"
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                  handleChange(e);
                                  setSelectedLeaveType(e.target.value)
                                }}
                                select
                                value={values.leave_type}
                                name="leave_type"
                                error={
                                    Boolean(touched.leave_type) && Boolean(errors.leave_type)
                                }
                                helperText={touched.leave_type && errors.leave_type}
                                sx={{
                                  width: "49%",
                                  marginBottom: "15px"
                                }}
                              >
                                <MenuItem value="NORMAL">NORMAL</MenuItem>
                                <MenuItem value="PATERNAL">PATERNAL</MenuItem>
                                <MenuItem value="MATERNAL">MATERNAL </MenuItem>
                                <MenuItem value="SICK">SICK</MenuItem>
                              </TextField>
                            </Box>
                            
                            
                                           
                            
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width:"40%"
                            }}
                          >        
                            {
                              selectedLeaveType==="NORMAL" ? 
                                <>
                                  <p> <b>Present Annual Leave entitlement:</b>  {initialFormValues.present_annual_leave_entitlement} days</p>
                                  <p> <b>Number of days taken:</b> {initialFormValues.number_of_days_taken}</p>
                                  <p> <b>Balance:</b>  {initialFormValues.balance_of_days} days</p>
                                </>
                              : selectedLeaveType==="PATERNAL" ?
                                  <>
                                    <p> <b>Paternal Leave entitlement:</b>  {location.state.balance_of_paternal_days} days</p>
                                    <p> <b>Number of days taken:</b> {initialFormValues.number_of_days_taken}</p>
                                    <p> <b>Balance:</b>  {initialFormValues.balance_of_days} days</p>
                                  </>
                              : selectedLeaveType==="MATERNAL" ?
                                  <>
                                    <p> <b>Maternal Leave entitlement:</b>  {location.state.balance_of_maternal_days} days</p>
                                    <p> <b>Number of days taken:</b> {initialFormValues.number_of_days_taken}</p>
                                    <p> <b>Balance:</b>  {initialFormValues.balance_of_days} days</p>
                                  </>
                              : selectedLeaveType==="SICK" ? null : null

                            }
                                           
                          </Box>

                        </Box>
                         
                        <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width:"100%"
                            }}
                        >
                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width:"100%",
                                justifyContent: "space-around"
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%",
                              }}
                            >
                              <label>Leave Now Required(In Days):</label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                  handleChange(e);
                                  handleToDate(fromDate, e.target.value);
                                  setLeaveRequired(e.target.value);
                                }}
                                value={values.leave_days_required}
                                name="leave_days_required"
                                type="number"
                                error={Boolean(touched.leave_days_required) && Boolean(errors.leave_days_required)}
                                helperText={touched.leave_days_required && errors.leave_days_required}
                                inputProps={{ 
                                  min:1, 
                                  max: initialFormValues.balance_of_days
                                  }}
                                sx={{ 
                                  width: "100%",
                                  marginBottom: "15px",
                                  
                                }}
                            />
                            </Box>
                            
                            <TextField
                              label="Address While on Leave"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              multiline
                              rows={2}
                              value={values.address_while_on_leave}
                              name="address_while_on_leave"
                              error={
                                  Boolean(touched.address_while_on_leave) && Boolean(errors.address_while_on_leave)
                              }
                              helperText={touched.address_while_on_leave && errors.address_while_on_leave}
                              sx={{
                                width: "45%",
                                marginBottom: "15px"
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width:"100%",
                                justifyContent: "space-around"
                            }}
                          >
                            
                            
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%",
                              }}
                            >
                      
                              <label>From Date:  </label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                  handleChange(e);
                                  handleToDate(e.target.value,leaveRequired);
                                  setFromDate(e.target.value);
                                }}
                                value={values.leave_start_date}
                                name="leave_start_date"
                                type="date"
                                error={
                                Boolean(touched.leave_start_date) && Boolean(errors.leave_start_date)
                                }
                                inputProps={{ 
                                    min: moment().format('YYYY-MM-DD')
                                    }}
                                helperText={touched.leave_start_date && errors.leave_start_date}
                                sx={{
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "45%",
                              }}
                            >
                        
                                <label>To Date:  </label>
                                <TextField
                                  label=""
                                  onBlur={handleBlur}
                                  disabled
                                  onChange={handleChange}
                                  value={values.leave_end_date=toDate}
                                  name="leave_end_date"
                                  type="date"
                                  error={
                                  Boolean(touched.leave_end_date) && Boolean(errors.leave_end_date)
                                  }
                                  inputProps={{ 
                                      min: values.leave_start_date
                                      }}
                                  helperText={touched.leave_end_date && errors.leave_end_date}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                                />
                            </Box>
                          </Box>

                           
                        </Box>
                       

                        {/* BUTTONS */}
                        <Box>
                          <Button
                             
                              type="submit"
                              variant="contained"
                              color="success"
                              size="small"
                              sx={{marginX:"5px"}}
                          >
                              Save Request
                          </Button>
                          <Button
                                variant="contained"
                                size="small"
                                color="error"
                                sx={{marginX:"5px"}}
                                onClick={()=>{
                                  navigate("/myLeaveRequests");
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>
                      </form>
                      )}
                  </Formik>
                 </Box>
                </Box>
          </Box>
      </Box>
  );
};

export default LeaveRequestCreateForm;
