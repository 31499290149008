
import {Box,Button,TextField,useMediaQuery,} from "@mui/material";
import moment from "moment";


const PreTripRequestDetailForm = (props) => {

    const {index, 
        data,
        handleRemovePreTripItemRow,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    

    return (
            
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                width:"100%"
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
             
                <TextField
                    label="Date of Travel"
                    size="small"
                    onBlur={handleBlur}
                    type="date"
                    onChange={handleChange}
                    inputProps={{
                        min: moment().format('YYYY-MM-DD')
                    }}
                    value={data.date_of_travel}
                    name={`${name}.date_of_travel`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.date_of_travel) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.date_of_travel)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.date_of_travel && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.date_of_travel}
                    sx={{ gridColumn: "span 2" }}
                />

                <TextField
                    label="Origin(Location)"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={data.origin_location}
                    name={`${name}.origin_location`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.origin_location) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.origin_location)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.origin_location && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.origin_location}
                    sx={{ gridColumn: "span 2" }}
                />

                <TextField
                    label="Destination(Location)"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={data.destination_location}
                    name={`${name}.destination_location`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.destination_location) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.destination_location)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.destination_location && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.destination_location}
                    sx={{ gridColumn: "span 2" }}
                />    

                <TextField
                    label="Mode of Transport"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={data.transport_mode}
                    name={`${name}.transport_mode`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.transport_mode) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.transport_mode)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.transport_mode && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.transport_mode}
                    sx={{ gridColumn: "span 2" }}
                /> 
                
                <TextField
                    label="Time of Day"
                    size="small"
                    onBlur={handleBlur}
                    type="time"
                    onChange={handleChange}
                    value={data.time_of_day}
                    name={`${name}.time_of_day`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.time_of_day) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.time_of_day)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.time_of_day && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.time_of_day}
                    sx={{ gridColumn: "span 1" }}
                /> 

                <TextField
                    label="Number of Travellers"
                    size="small"
                    onBlur={handleBlur}
                    type="number"
                    onChange={handleChange}
                    value={data.number_of_travelers}
                    name={`${name}.number_of_travelers`}
                    error={Boolean( touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.number_of_travelers) && Boolean(errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.number_of_travelers)}
                    helperText={ touched?.pre_trip_request_fields && touched?.pre_trip_request_fields[index]?.number_of_travelers && errors?.pre_trip_request_fields && errors?.pre_trip_request_fields[index]?.number_of_travelers}
                    sx={{ gridColumn: "span 2" }}
                /> 

                <Button
                    value={index}
                    onClick={() => {
                        handleRemovePreTripItemRow(index);
                    }}                                                     
                    size="small"
                    variant="contained"
                    sx={{ 
                        width: "20%",
                        marginBottom:"20px",
                        }}
                    >
                    Remove
                </Button>
                
            </Box>
        </Box>                   
    );
  };
  
  export default PreTripRequestDetailForm;
  