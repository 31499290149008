
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const itemUnitSchema = yup.object().shape({
  comment: yup.string().required("required"),
  
});



const initialValuesItemUnit = {
  comment: "",
};


const ApprovalBasisForm = (props) => {

  let {id, isDeffered, approvalStepDetail, commentName} = props;
  const navigate = useNavigate();
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token =  useSelector((state) => state.token);

  const saveApproval = async (values, onSubmitProps) => {
    values.approval_step_id = approvalStepDetail.id
    values.related_document_request = props.relatedDocumentRequest
    values.related_document_approval_step = approvalStepDetail.related_document_approval_step.id
    values.status = props.status
    

    const savedApprovalResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/projects/requests/approve", {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(values),
    });
    const savedApproval = await savedApprovalResponse.json();

    
    if (savedApproval) {
      props.setOpen(false);
      onSubmitProps.resetForm();
      // props.setIsSuccess(true)
      navigate("/approvals");
    }
  };

  

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveApproval(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItemUnit}
      validationSchema={itemUnitSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            {isDeffered ? (<TextField
              label="Deffer To"
              hidden="true"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.defer}
              name="defer"
              error={Boolean(touched.defer) && Boolean(errors.defer)}
              helperText={touched.defer && errors.defer}
              sx={{ gridColumn: "span 2" }}
            />) : null}

            <TextField
              label={("BASIS OF " + commentName)}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.comment}
              multiline
              rows={6}
              name="comment"
              error={Boolean(touched.comment) && Boolean(errors.comment)}
              helperText={touched.comment && errors.comment}
              sx={{ gridColumn: "span 4" }}
            />


          </Box>

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              type="submit"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ApprovalBasisForm;
