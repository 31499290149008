
import {Box, Button, TextField, useMediaQuery, MenuItem, useTheme, Alert, Grid, Paper, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TravelMatrixDetailForm from "./travelMatrixDetailForm";
import { useProjectCostCenterList } from "FetchData";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment/moment";
import { odmasApi } from "api/pesabackend";
import { useTravelRequisitionList} from "FetchData";
  
  
  const requisitionSchema = yup.object().shape({
    
    week_of_travel: yup.date().required("required"),
    year: yup.number(),
    week_to: yup.date(),
    week_from: yup.date()
  });

  // function createData(name, calories, fat, carbs, protein, price) {
  //   return {
  //     name,
  //     calories,
  //     fat,
  //     carbs,
  //     protein,
  //     price,
  //     history: [
  //       {
  //         date: '2020-01-05',
  //         customerId: '11091700',
  //         amount: 3,
  //       },
  //       {
  //         date: '2020-01-02',
  //         customerId: 'Anonymous',
  //         amount: 1,
  //       },
  //     ],
  //   };
  // }

  // function Row(props) {
  //   const { row } = props;
  //   const [open, setOpen] = React.useState(false);
  
  //   return (
  //     <React.Fragment>
  //       <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
  //         <TableCell>
  //           <IconButton
  //             aria-label="expand row"
  //             size="small"
  //             onClick={() => setOpen(!open)}
  //           >
  //             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //           </IconButton>
  //         </TableCell>
  //         <TableCell component="th" scope="row">
  //           {row.name}
  //         </TableCell>
  //         <TableCell align="right">{row.calories}</TableCell>
  //         <TableCell align="right">{row.fat}</TableCell>
  //         <TableCell align="right">{row.carbs}</TableCell>
  //         <TableCell align="right">{row.protein}</TableCell>
  //       </TableRow>
  //       <TableRow>
  //         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
  //           <Collapse in={open} timeout="auto" unmountOnExit>
  //             <Box sx={{ margin: 1 }}>
  //               <Typography variant="h6" gutterBottom component="div">
  //                 History
  //               </Typography>
  //               <Table size="small" aria-label="purchases">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell>Date</TableCell>
  //                     <TableCell>Customer</TableCell>
  //                     <TableCell align="right">Amount</TableCell>
  //                     <TableCell align="right">Total price ($)</TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <TableBody>
  //                   {row.history.map((historyRow) => (
  //                     <TableRow key={historyRow.date}>
  //                       <TableCell component="th" scope="row">
  //                         {historyRow.date}
  //                       </TableCell>
  //                       <TableCell>{historyRow.customerId}</TableCell>
  //                       <TableCell align="right">{historyRow.amount}</TableCell>
  //                       <TableCell align="right">
  //                         {Math.round(historyRow.amount * row.price * 100) / 100}
  //                       </TableCell>
  //                     </TableRow>
  //                   ))}
  //                 </TableBody>
  //               </Table>
  //             </Box>
  //           </Collapse>
  //         </TableCell>
  //       </TableRow>
  //     </React.Fragment>
  //   );
  // }
  
  
  
  const TravelMatrixCreateForm = ({ row } ) => {
    const [isSidebar, setIsSidebar] = useState(true);
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const {projectId, relatedProjectDocument} = useParams();
    const { palette } = useTheme();
    const navigate = useNavigate();
    const {projectCostCenterList} = useProjectCostCenterList(projectId);
    const [isFailedError, setIsFailedError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed]  = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const {travelRequisitionList, isLoadingTravelRequisitionList, setUseStatusFilter} = useTravelRequisitionList(1, projectId, "False");
  

    
    

    
    useEffect(() =>{
      const timer = setTimeout(() => {
        setIsSuccess(false);
        setIsFailed(false);
      }, 3000);
      return () => clearTimeout(timer);
    }, [isSuccess, isFailed]);
  
    const token =  useSelector((state) => state.token);
  
  
    const initialValues = {
      related_project_document: relatedProjectDocument,
      year: "",
      week_to: "",
      week_from: "",
      week_of_travel: moment().format('YYYY-MM-DD'),
      travel_matrix_details:[]
    };
  
    const[travelMatrixDetailFormData, setTravelMatrixDetailFormData] = useState([{
      budget_code: '', batch: '', week_of_travel: moment().format('YYYY-MM-DD'), amount: '',  description: "", data_id: uuidv4(),
    }]);
  
    const [grandTotal,setGrandTotal] = useState(initialValues.total_amount);
  
  
    const fetchRequisitionData = async (requestId) => {
    
      const response = await odmasApi.get(`projects/requests/details/${requestId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      navigate("/paymentRequisitionViewForm", { state: response.data.data })
    };
  
    
    const saveRequisition = async (values, onSubmitProps) => {
    
      values.travel_matrix_details = travelMatrixDetailFormData
  
      const savedItemResponse = await fetch(`https://api.odms.iolabz.ug/api/v1/projects/requests/payment_requisition`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json", 
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(values),
      });
      const savedItem = await savedItemResponse.json();
  
      if (savedItem.status===201) {
        setIsSuccess(true);
        setTimeout(() => {
          onSubmitProps.resetForm();  
          fetchRequisitionData(savedItem.id);
        }, 1500);
      }; 
  
      if (savedItem.status===404){
        setIsFailedError(savedItem.errors)
        setIsFailed(true);
      };
  
    };
  
  
    const handleGrandTotal = (newArray) =>{
      const grandTotalCalculation = newArray.reduce((total,newArray)=>{
        return Number(total) + Number(newArray.amount)
      }, 0);
      setGrandTotal(parseFloat(grandTotalCalculation).toFixed(2));
    };
  
    const handleBudgetCodeChange = (event, index) =>{
      const {value} = event.target;
      const newArray = travelMatrixDetailFormData.map((item, idx) => {
          if (idx === index) {
              return {...item, budget_code: value}
          }
          return item
      })
      setTravelMatrixDetailFormData(newArray)
    };
  
    const handleAmountChange = (event, index) =>{
      const {value} = event.target;
      const newArray = travelMatrixDetailFormData.map((item, idx) => {
          if (idx === index) {
              return {...item, amount: Number(value)}
          }
          return item
      })
      setTravelMatrixDetailFormData(newArray);
      handleGrandTotal(newArray);
    };
  
    
    const handleItemDescriptionChange = (event, index) =>{
    const {value} = event.target;
      const newArray = travelMatrixDetailFormData.map((item, idx) => {
          if (idx === index) {
              return {...item, description: value}
          }
          return item
      })
      setTravelMatrixDetailFormData(newArray);
    };
  
      
  
    const handleAddProductDetailSubmitData = () => {
      const oneRow = {
        budget_code: '', batch: '', week_of_travel: moment().format('YYYY-MM-DD'), amount: '',  description: "", data_id: uuidv4()
      };
      setTravelMatrixDetailFormData([...travelMatrixDetailFormData, oneRow])
    }; 
  
  
    const handleRemoveItemRow = (data_id) => {
      const newFormData = travelMatrixDetailFormData.filter((i) => i.data_id !== data_id)
      setTravelMatrixDetailFormData(newFormData);
      handleGrandTotal(newFormData);      
    };
  
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      await saveRequisition(values, onSubmitProps);
    };
    
    return (
        <Box>
            <Navbar />
            <Box 
                sx={{
                  display:"flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                  <Box 
                    sx={{
                      flexGrow: 1
                    }}
                  >
                      <Sidebar isSidebar={isSidebar}/>  
                  </Box>
                  <Box 
                    sx={{
                      flexGrow: 999,
                      paddingX: "40px",
                      justifyContent: "space-between"
                    }}
                  >
                    {
                      isSuccess ? ( 
                          <Alert sx={{ m:"15px" }} variant="filled" severity="success">Travel Matrix created successfully!</Alert>
                          
                      ) : isFailed ? (
                          <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
                      ) : null
                                                      
                    }
                    <h4>Travel Matrix Create Form</h4>
                    <Grid container spacing={4} className="alignHorizantal">
                      <Grid xs sx={{ mx: '4px'}} >
                        <Box marginTop={"40px"}>
                          <Formik
                              onSubmit={handleFormSubmit}
                              
                              initialValues={initialValues}
                              validationSchema={requisitionSchema}
                          >
                              {({
                              values,
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              resetForm
                              
                              }) => (
                              <form onSubmit={handleSubmit}>
                                <Box
                                  sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-around"
                                  }}
                                  >
                                  
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width:"40%"
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                
                                        <label>Week of travel:  </label>
                                        <TextField
                                          label=""
                                          onBlur={handleBlur}
                                          onChange={(e)=>{
                                            handleChange(e);
                                            console.log(e.target.value)
                                        }}
                                          value={values.week_of_travel}
                                          name="week_of_travel"
                                          type="week"
                                          error={
                                          Boolean(touched.week_of_travel) && Boolean(errors.week_of_travel)
                                          }
                                          helperText={touched.week_of_travel && errors.week_of_travel}
                                          sx={{
                                            width: "100%",
                                            marginBottom: "15px",
                                          }}
                                        />
                                    </Box>
                                  </Box>
                                </Box>

                                <Box
                                gridColumn="span 4"
                                border={`1px solid ${palette.neutral.medium}`}
                                borderRadius="2px"
                                p="0.5rem"
                                >
                                    <h5>Input the travel records here:</h5> 
                                    
                                    {travelMatrixDetailFormData.map((row, index) => {
                                      return (
                                      <TravelMatrixDetailForm key={row.data_id}
                                        index={index}
                                        data={row}
                                        handleRemoveItemRow={handleRemoveItemRow}
                                        setTravelMatrixDetailFormData={setTravelMatrixDetailFormData}
                                        handleBudgetCodeChange = {handleBudgetCodeChange}
                                        handleAmountChange = {handleAmountChange}
                                        handleItemDescriptionChange = {handleItemDescriptionChange}
                                        
                                      />
                                        )
                                      })}
                                    
                                      <Button
                                          onClick={()=>{
                                            handleAddProductDetailSubmitData();
                                          }}
                                          sx={{
                                            m: "1rem 0",
                                          }}
                                          size="small"
                                          variant="contained"
                                      >
                                          Add Record
                                      </Button>
                                    
                                </Box>  
      
                                {/* BUTTONS */}
                                <Box>
                                  <Button
                                      
                                      type="submit"
                                      variant="contained"
                                      size="small"
                                      sx={{
                                      m: "0.5rem ",
                                      p: "0.5rem",
                                      }}
                                  >
                                      Save Travel Matrix
                                  </Button>
                                  <Button
                                      onClick={()=>{
                                        resetForm();
                                        navigate(`/projects/${projectId}`);
                                      }}
                                      variant="contained"
                                      color="error"
                                      size="small"
                                      sx={{
                                      m: "1rem",
                                      p: "0.5rem",
                                      }}
                                  >
                                      Cancel
                                  </Button>
                                </Box>
                              </form>
                              )}
                          </Formik>
                        </Box>
                      </Grid>
                              
                        
                      <Grid xs sx={{ mx: '4px'}} >
                      <Paper 
                          sx={{ width: '100%',
                                padding: "10px",
                                margin: "40px",
                              }}
                          elevation={3}
                      >
                        
                        <h3 >Approved Travels List</h3 >
                        <TableContainer sx={{ maxHeight: "100vh" }}>
                          <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                            <TableHead>
                              <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell
                                    style={{  minWidth: 50}}
                                  >
                                    Date of Approval
                                  </TableCell>
                                  <TableCell
                                    style={{  minWidth: 150 }}
                                  >
                                    Title
                                  </TableCell>
                                  <TableCell
                                    style={{  minWidth: 150 }}
                                  >
                                    Requested By 
                                  </TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {!travelRequisitionList ? (
                                <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                              ) : (
                                travelRequisitionList.map((row, index)=>{
                                  return(
                                    <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                      {/* <TableCell>
                                        <IconButton
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => setOpen(!open)}
                                        >
                                          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                      </TableCell>
                                 */}
                                      <TableCell key={row.id}>

                                      </TableCell>
                                      <TableCell key={row.id}>
                                        
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        
                                      </TableCell>
                                                                          
                                  </TableRow>
                                  )
                                })
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                                  
                        </Paper>
                      </Grid>
                    
                    </Grid>
                  </Box>
            </Box>
        </Box>
    );
  };
  
  export default TravelMatrixCreateForm;
  