
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Autocomplete,
  Switch,
  FormControlLabel,
  Select, 
  MenuItem,
  OutlinedInput,
  
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import ItemUnitDetailForm from "./itemUnitDetailForm";
import { odmasApi } from "api/pesabackend";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import moment from "moment";

const itemSchema = yup.object().shape({
  task_name: yup.string().required("required"),
  start_date: yup.string().required("required"),
  end_date: yup.string().required("required"),
  related_task: yup.number(),
  related_activity: yup.number().required("required"),
  task_assignee: yup.array()
  
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};





const TaskCreateForm = (props) => {
  const pageType = props.formType;
  let {activityId} = props; 
  const userId = useSelector((state) => state.user.id);
  
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const token =  useSelector((state) => state.token);

  const [taskList, setTaskList] = useState([]);
  const [isLoadingTaskList, setIsLoadingTaskList] = useState(true);
  const [TaskListError, setTaskListError] = useState(false);

  const [userList, setUserList] = useState([]);
  const [isLoadingUserList, setIsLoadingUserList] = useState(true);
  const [UserListError, setUserListError] = useState(false);
    
  const fetchTaskList = async () => {
      try {
          const response = await odmasApi.get(`/activity/get/${activityId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setTaskList(response.data.data.related_tasks);
          setIsLoadingTaskList(false);
      } catch (e) {
          setTaskListError(true);
          setIsLoadingTaskList(false);
      }
  };


  const fetchUserList = async (search) => {
    try {
        const response = await odmasApi.get(`/tenants/departments/employees`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserList(response.data.data);
        setIsLoadingUserList(false);
    } catch (e) {
        setUserListError(true);
        setIsLoadingUserList(false);
    }
};

  

  const initialValuesItem = {
    task_name: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    has_report: false,
    related_activity : activityId,
    related_task:"",
    task_assignee: []
  };

  const[addHasReports, setAddHasReports]= useState(false);
  const[addParentTask, setAddParentTask]= useState(false);
  const[addTaskAssignee, setAddTaskAssignee]= useState(false);

  
  const handleAddingParentTask = (e) => {
    
    if (e.target.checked === true ) {
      setAddParentTask(true);
    }
    if (e.target.checked === false ) {
      setAddParentTask(false)
    }
  };  

  const handleAddingTaskAssignee = (e) => {
    
    if (e.target.checked === true ) {
      setAddTaskAssignee(true)
    }
    if (e.target.checked === false ) {
      setAddTaskAssignee(false)
    }
  };  

  const handleHasReports = (e) => {
    
    if (e.target.checked === true ) {
      setAddHasReports(true)
    }
    if (e.target.checked === false ) {
      setAddHasReports(false)
    }
  }; 
  

  const saveItem = async (values, onSubmitProps) => {
    
    values.added_by = userId
    
    const savedItemResponse = await fetch("https://api.odms.iolabz.ug/api/v1/task/add", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewTaskData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
    if (savedItem.status===400){
      props.setIsFailedError(savedItem.error)
      props.setIsFailed(true);
      onSubmitProps.resetForm();
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Task Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.task_name}
              name="task_name"
              error={
                Boolean(touched.task_name) && Boolean(errors.task_name)
              }
              helperText={touched.task_name && errors.task_name}
              sx={{ gridColumn: "span 4" }}
            />         
            
            <TextField
              label="Start Date"
              onBlur={handleBlur}
              onChange={handleChange}
              type="date"
              value={values.start_date}
              name="start_date"
              error={Boolean(touched.start_date) && Boolean(errors.start_date)}
              helperText={touched.start_date && errors.start_date}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="End Date"
              onBlur={handleBlur}
              type="date"
              onChange={handleChange}
              value={values.end_date}
              name="end_date"
              error={Boolean(touched.end_date) && Boolean(errors.end_date)}
              helperText={touched.end_date && errors.end_date}
              sx={{ gridColumn: "span 2" }}
            />

            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                // value={values.is_recurring = addParentTask}
                // name="is_recurring"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleAddingParentTask(e)
                    }}
                  />
                }
                label="Is this a task a sub task?:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {addParentTask === true ? (
              <Autocomplete
              onOpen={ ()=> {
                fetchTaskList()
              }}
              onBlur={handleBlur}
              name="related_task"
              error={Boolean(touched.related_task) && Boolean(errors.related_task)}
              helperText={touched.related_task && errors.related_task}
              onChange={(e, value) => {
                setFieldValue(
                  "related_task",
                  value.id
                );
              }}
              sx={{ gridColumn: "span 2" }}
              options={taskList}
              getOptionLabel={(option) => option.task_name}
              renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.task_name}
                  </Box>
                )}
              renderInput={(params) => <TextField 
                                          {...params} 
                                          inputProps={{
                                              ...params.inputProps
                                          }}
                                          label="Task Attached To"
                                          value={values.related_task}
                                          // onChange={(e)=>{
                                          //   fetchTaskList(e.currentTarget.value);
                                          // }}
                                            />}
            />
            ) : (
              null
            )}
            

            <Box sx={{ gridColumn: "span 4", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.has_report}
                name="has_report"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleHasReports(e)
                    }}
                  />
                }
                label="Does this task require a report?:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>


            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                // value={values.is_recurring = addParentTask}
                // name="is_recurring"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleAddingTaskAssignee(e)
                    }}
                  />
                }
                label="Assign task?:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {addTaskAssignee === true ? (
              
              <Select
                label="Task Assignee"
                id="demo-multiple-name"
                multiple
                sx={{ gridColumn: "span 2" }}
                onOpen={ ()=> {
                  fetchUserList('false');
                }}
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {userList.map((name) => (
                  <MenuItem
                    key={name.id}
                    value={name.id}
                    
                  >
                    {name.first_name} {name.last_name}
                  </MenuItem>
                ))}
              </Select>
              
              ) : (
                null
              )}
              
            
            
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default TaskCreateForm;
