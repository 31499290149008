import { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProjectDashboard from "scenes/project";
import FolderIcon from '@mui/icons-material/Folder';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HouseIcon from '@mui/icons-material/House';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { CalendarMonth } from "@mui/icons-material";

const MyProSidebar = () => {

  const navigate = useNavigate();

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const sub_main = theme.palette.secondary.sub_main
  const main = theme.palette.secondary.main
  const white = theme.palette.secondary.white
  const medium = theme.palette.secondary.medium
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const userProjects = useSelector((state) => state.projectsAttached);
  const userType = useSelector((state)=> state.user.account_type)

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        // bottom: 0,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: `${medium} !important`,
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `${medium} !important`,
          backgroundColor: `${medium} !important`,
        },
        position:"sticky",
        top:0
      }}
    >
      <Sidebar 
        collapsed={isCollapsed}
        backgroundColor={medium}
      >
        <Menu iconShape="square" > 
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "5px 0 10px 0",
              color: white,
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} style={{ color: white, }} >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">

              <MenuItem
                icon={<DashboardIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/dashboard")}
              > 
                Dashboard 
              </MenuItem>

              <SubMenu 
                label="Programs"
                icon={<FolderIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
              >
                {userProjects.length===0 ? (
                  <MenuItem><p>No Programs Attched</p></MenuItem>
                  ) : (userProjects.map((row,index)=> {
                    return(
                      <MenuItem
                        style={{ 
                          color: "black", 
                          margin: "5px 0 10px 0",
                          fontSize: "16px"
                        }}
                        onClick={() => {
                          navigate(`/projects/${row.related_project.id}`, {state: {startTab:0, projectName: row.related_project.project_name }})
                        }}
                      > 
                        {row.related_project.project_name} 
                      </MenuItem>
                    )
                      }
                    
                    ))}
                
              </SubMenu>

              <MenuItem
                icon={<ReceiptIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/approvals")}
              > 
                My Approvals
              </MenuItem>

              <MenuItem
                icon={<CalendarMonth/>}
                style={{ 
                  color: white,
                  margin: "5px 0 10px 0",
                  fontSize: "16px" 
                }}
                onClick={() => navigate("/clocking&timesheet")}
              > 
                Clocking & Timesheets 
              </MenuItem>

              <MenuItem
                icon={<HouseIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/activities")}
              > 
                Activities & Tasks  
              </MenuItem>

              <MenuItem
                icon={<PeopleIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/leave")}
              > Leave
              </MenuItem>

              {/* <MenuItem
                icon={<PeopleIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/travelMartix")}
              > Travel Matrix
              </MenuItem>
               */}
              
              {/* <MenuItem
                icon={<BarChartRoundedIcon/>}
                style={{ 
                  color: white, 
                  margin: "5px 0 10px 0",
                  fontSize: "16px"
                }}
                onClick={() => navigate("/reports")}
              > 
                Reporting 
              </MenuItem> */}

              {userType===0 ? 
                <MenuItem
                  icon={<ReceiptIcon/>}
                  style={{ 
                    color: white, 
                    margin: "5px 0 10px 0",
                    fontSize: "16px"
                  }}
                  onClick={() => navigate("/setup")}
                > 
                  Setup 
                </MenuItem> : null
              }
              

              {userType===0 ? 
                <MenuItem
                  icon={<SettingsIcon/>}
                  style={{ 
                    color: white,
                    margin: "5px 0 10px 0",
                    fontSize: "16px" 
                  }}
                > 
                  Settings
                </MenuItem> : null
              }
              
              
            </Box>
          )}

          
        
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
