
import {Box,Button,TextField,useMediaQuery,Typography,FormControlLabel, Switch,useTheme,MenuItem} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import ProjectsAttchedDetailForm from "./projectsAttachedDetailForm";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import { useProjectList, useDepartmentStationList, useDepartmentRoleList } from "FetchData";




const UserCreateForm = (props) => {
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {projectList, isLoadingProjectList} = useProjectList(1)
  const token =  useSelector((state) => state.token);
  const departmentId = useSelector((state)=> state.employeeData.related_department.id);
  const {departmentRoleList, isLoadingDepartmentRoleList} = useDepartmentRoleList(departmentId);
  const {related_tenant, related_department} = useSelector((state)=>state.employeeData);
  const {departmentStationList, isLoadingDepartmentStationList} = useDepartmentStationList(related_tenant.id)
  const [totalEffort,setTotalEffort] = useState(0);

  const initialValuesItem = {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    gender:"",
    phone_number: "",
    related_role: 0,
    has_department_role: "False",
    has_project_role: "False",
    projects_attached:[
      {
        related_project: '', related_project_role: '', related_station: '', level_of_effort: '',  data_id: uuidv4(),
      }
    ],
    effort_sum: 0
  };

  

  const[hasDepartmentRole, setHasDepartmentRole]= useState("False");

  const handleCheckHasDepartmentRole = (e) => {
    
    if (e.target.checked === true ) {
      setHasDepartmentRole("True")
    }
    if (e.target.checked === false ) {
      setHasDepartmentRole("False")
    }
  };  

  const[hasProjectRoles, setHasProjectRoles]= useState("False");

  const handleCheckHasProjectRoles = (e) => {
    
    if (e.target.checked === true ) {
      setHasProjectRoles("True")
    }
    if (e.target.checked === false ) {
      setHasProjectRoles("False")
    }
  };  
  

  const itemSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    email: yup.string().email("Input a valid email").required("required"),
    username: yup.string().required("required"),
    password: yup.string().required("required"),
    gender: yup.string().required("required"),
    phone_number: yup.string().required("required"),
    related_role: hasDepartmentRole === "False" ? yup.number() : yup.number().required("required"),
    projects_attached: yup.array()
    .of(
      yup.object().shape({
        related_project: yup.number().required("required"),
        related_project_role: yup.number().required("required"),
        related_station: yup.number().required("required"),
        level_of_effort: yup.number()
        // .max(remainingTotalEffort, 'This input exceeds the remaing effort')
        .required("required"),
        })
    ),
    effort_sum: hasProjectRoles === "False" ? yup.number() : yup.number().max(100, 'You have exceeded the 100% effort limit').min(100, 'Effort cannot be less than 100%')
    
  });

  const[projectsAttachedDetailFormData, setProjectsAttachedDetailFormData] = useState([{
    related_project: '', related_project_role: '', related_station: '', level_of_effort: '',  data_id: uuidv4(),
  }]);

  const handleCalculateTotalEffort = (newArray) =>{
    const totalEffortCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.level_of_effort)
    }, 0);
    setTotalEffort(totalEffortCalculation);
  };

  const handleLevelOfEffortChange = (event, index) =>{
    const {value} = event.target;
    const newArray = projectsAttachedDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, level_of_effort: Number(value)}
        }
        return item
    })
    setProjectsAttachedDetailFormData(newArray);
    handleCalculateTotalEffort(newArray);
  };

  const handleAddProjectsAttachedDetailSubmitData = () => {
    const oneRow = {
      related_project: '', related_project_role: '', related_station: '', level_of_effort: '',  data_id: uuidv4(),
    };
    setProjectsAttachedDetailFormData([...projectsAttachedDetailFormData, oneRow])
  }; 


  const handleRemoveItemRow = (index) => {
    let newFormData = projectsAttachedDetailFormData
    newFormData.splice(index,1)
    setProjectsAttachedDetailFormData(newFormData);    
    handleCalculateTotalEffort(newFormData);
  };
  

  const saveItem = async (values, onSubmitProps) => {

    values.related_department = related_department.id
    values.related_tenant = related_tenant.id

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/tenants/departments/employees", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` 
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewEmployeeData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              name="first_name"
              error={
                Boolean(touched.first_name) && Boolean(errors.first_name)
              }
              helperText={touched.first_name && errors.first_name}
              sx={{ gridColumn: "span 1" }}
            />
            <TextField
              label="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              name="last_name"
              error={Boolean(touched.last_name) && Boolean(errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Username"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Phone Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone_number}
              name="phone_number"
              error={
                Boolean(touched.phone_number) && Boolean(errors.phone_number)
              }
              helperText={touched.phone_number && errors.phone_number}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Gender"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.gender}
              select
              name="gender"
              error={Boolean(touched.gender) && Boolean(errors.gender)}
              helperText={touched.gender && errors.gender}
              sx={{ gridColumn: "span 1" }}
            >
              <MenuItem value={"FEMALE"}>{"FEMALE"}</MenuItem>
              <MenuItem value={"MALE"}>{"MALE"}</MenuItem>
            </TextField>

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              name="password"
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 1" }}
            />
            
            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.has_department_role = hasDepartmentRole}
                name="has_department_role"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleCheckHasDepartmentRole(e)
                    }}
                  />
                }
                label="Does the User have a department role:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {hasDepartmentRole === "True" ? 
              (
                <TextField
                label="Department Role"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.related_role}
                select
                name="related_role"
                error={Boolean(touched.related_role) && Boolean(errors.related_role)}
                helperText={touched.related_role && errors.related_role}
                sx={{ gridColumn: "span 2" }}
                >
                  {departmentRoleList.map((row)=>{
                    return(
                      <MenuItem value={row.id}>{row.role_name}</MenuItem>
                    )})
                  }
                </TextField>
              ):(null)
            }

            <Box sx={{ gridColumn: "span 4", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.has_project_role = hasProjectRoles}
                name="has_project_role"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleCheckHasProjectRoles(e)
                    }}
                  />
                }
                label="Attach User to a project:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {hasProjectRoles === "True" ? 
              (
                <>
                  <Box
                    gridColumn="span 4"
                    border={`1px solid ${palette.neutral.medium}`}
                    borderRadius="5px"
                    p="1rem"
                  >
                    <h5>Select the projects the user is attached to and input their details:</h5>

                    <FieldArray
                      name="projects_attached"
                      render={(arrayHelpers)=>{
                        const projects_attached = values.projects_attached
                      
                        return(
                          <>
                            {projects_attached.map((row, index) => {
                              return (
                                <ProjectsAttchedDetailForm key={row.data_id}
                                  index={index}
                                  data={row}
                                  token={token}
                                  handleRemoveItemRow={(index)=>{
                                    arrayHelpers.remove(index);
                                    handleRemoveItemRow(index);
                                  }}
                                  projectList={projectList}
                                  departmentStationList={departmentStationList}
                                  handleLevelOfEffortChange={handleLevelOfEffortChange}
                                  handleChange={handleChange}
                                  errors={arrayHelpers.form.errors}
                                  touched={arrayHelpers.form.touched}
                                  name={`projects_attached[${index}]`}
                                  handleBlur={handleBlur}
                                />
                              )
                            })}

                            {totalEffort > 100 ? (<p style={{ color:"red" }}>* Please input values that do not exceed 100%</p>): null}

                            <Button
                              onClick={()=>{
                                arrayHelpers.push(
                                  {
                                    related_project: '', related_project_role: '', related_station: '', level_of_effort: '',  data_id: uuidv4(),
                                  }
                                );
                                handleAddProjectsAttachedDetailSubmitData();
                              }}
                              sx={{
                                m: "1rem 0",
                              }}
                              size="small"
                              variant="contained"
                            >
                              Add Project
                            </Button>
                          </>
                        )
                      }}
                    />
                  </Box>
                  <Box                         
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="2px"
                  p="0.5rem"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mx: "2px"
                    }}
                  >
                    <label>Total Effort:  </label>
                    <TextField
                      label=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.effort_sum = totalEffort}
                      name="effort_sum"
                      disabled
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0
                      }}
                      error={
                      Boolean(touched.effort_sum) && Boolean(errors.effort_sum)
                      }
                      helperText={touched.effort_sum && errors.effort_sum}
                      sx={{
                        // width: "100%",
                        marginBottom: "15px",
                      }}
                    />
                  </Box>
                  </Box> 
                </>
              ):(null)
            }
      </Box>



      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          size="small"
          type="submit"
          sx={{
            m: "10px 0",
            p: "10px",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
            width: "20%",
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default UserCreateForm;
