import * as React from 'react';
import { Box } from "@mui/material";
import MyProSidebar from 'scenes/global/Sidebar';
import Navbar from "scenes/navbar";
import BasicCard from 'components/Cards/Card';


export default function MainDashboard() {
    const [isSidebar, setIsSidebar] = React.useState(true);

    return (
        <Box>
            <Navbar />
        
            <Box 
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }} 
            >
                <Box 
                    sx={{
                        flexGrow: 1              
                    }}
                >
                    <MyProSidebar isSidebar={isSidebar}/>  
                </Box>
                <Box 
                    sx={{
                        flexGrow: 999
                    }}
                >
                    <h1>Dashboard</h1>

                    <h3>Project Detail</h3>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <BasicCard name={"PROJECTS ATTECHED TO"} value={"2"}/>
                        <BasicCard name={"MEMOS CREATED"} value={"3"}/>
                        <BasicCard name={"REQUISITIONS CREATED"} value={"7"}/>
                        <BasicCard name={"VERIFICATION REQUESTS"} value={"6"}/>
                        <BasicCard name={"UNREAD MEMOS"} value={"10"}/>
                        {/* <BasicCard name={"EXPIRED"} value={"200"}/> */}
                    </Box>

                    <h3>Leave Detail</h3>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <BasicCard name={"NORMAL LEAVE DAYS LEFT"} value={"2"}/>
                        <BasicCard name={"SICK LEAVE DAYS LEFT"} value={"2"}/>
                        <BasicCard name={"LAST LEAVE DATE"} value={"3"}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}