import {
  Box,
  Paper,
  Button,
  useTheme
} from "@mui/material";


import { useRef } from "react";
import moment from "moment/moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import ReactToPrint from "react-to-print";




const TravelRequisitionPrintPreviewForm = ({data}) => {

  const theme = useTheme();

  const date_created = moment(data.state.related_document_request.date_added).format('LL');
  const title= data.state.title;
  const background= data.state.background;
  const purpose=data.state.purpose;
  const related_project=data.state.related_document_request.related_project_document.related_project.project_name;
  const approval_steps = data.state.approval_steps;
  const to = data.state.to;
  const total = data.state.total;
  const meta_fields = data.state.meta_fields;
  const supportingDocuments = []
  const travel_advance_request_fields = data.state.travel_advance_request_fields
  const added_by = data.state.related_document_request.added_by.first_name + ' ' + data.state.related_document_request.added_by.last_name

  const componentRef = useRef();

  return (
    <>
        
        <Paper 
            sx={{ width: '95%'}}
            elevation={3}
        >
          <Box
              sx={{ width: '95%',
              padding: "20px",
              margin: "20px",
            }} 
            ref={componentRef}
          >
                
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>RECTRACK</h3 >
                {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
                {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h3 > */}
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>TRAVEL REQUEST AUTHORIZATION</h3 >

                <p> <i>This form is to be filled out BEFORE the trip for trip approval.</i></p>
                    
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "yellow" }}>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            Traveller Names:
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            {added_by}
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            Date of Request:
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            {date_created}
                          </TableCell>
                                                            
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <br/> 
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={2} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          A. Project Information
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          Project Name
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Project Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {related_project}
                        </TableCell>
                        <TableCell>
                          
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={1} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          B. Trip Scope of Work (Attach or fill in a SOW for the trip)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <i>Indicate, in detail, what will be accomplished during this trip.</i>
                        </TableCell>                                
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {title}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={3} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          C. Pre Trip Request*
                        </TableCell>
                        <TableCell
                          colSpan={3} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          Transportation Arrangements
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Date of Travel
                        </TableCell>    
                        <TableCell>
                          Origin (Location)
                        </TableCell>                             
                        <TableCell>
                          Destination (Location)
                        </TableCell> 
                        <TableCell>
                          Mode
                        </TableCell> 
                        <TableCell>
                          Time of Day
                        </TableCell> 
                        <TableCell>
                          Number of Travelers
                        </TableCell> 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {meta_fields.map((row,indexedDB)=>{
                        return(
                          <TableRow>
                            <TableCell>
                              {row.date_of_travel}
                            </TableCell>
                            <TableCell>
                              {row.origin_location}
                            </TableCell>
                            <TableCell>
                              {row.destination_location}
                            </TableCell>
                            <TableCell>
                              {row.transport_mode}
                            </TableCell>
                            <TableCell>
                              {row.time_of_day}
                            </TableCell>
                            <TableCell>
                              {row.number_of_travelers}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
                <p><small> <b>* If more space is needed for travel schedule, you must attach a spreadsheet with the above columns and information.</b></small></p>

                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={5} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          D. Travel Advance Request (if the partner has travel advance policy)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Person
                        </TableCell>    
                        <TableCell>
                          Designation
                        </TableCell>                             
                        <TableCell>
                          Days
                        </TableCell> 
                        <TableCell>
                          Rate
                        </TableCell> 
                        <TableCell>
                          Total
                        </TableCell> 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {travel_advance_request_fields.map((row,indexedDB)=>{
                        return(
                          <TableRow>
                            <TableCell>
                              {row.name_of_persons}
                            </TableCell>
                            <TableCell>
                              {row.designation}
                            </TableCell>
                            <TableCell>
                              {row.days}
                            </TableCell>
                            <TableCell>
                              {row.rate}
                            </TableCell>
                            <TableCell>
                              {row.total}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={3} align="right" >
                          <b>Sub Total:</b>
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={2}  align="right" >
                          Contingency
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={1}  align="right" >
                          <b>Total:</b>
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>   
                {!approval_steps ? (
                                
                                <></>
                              
                ): (approval_steps
                  .map((row, index) => {
                    return (
                      <>
                        {/* <p>
                          <span style={{ textAlign: "left"}}> <b>{row.related_document_approval_step.related_approval_step.approval_step_complete} BY:</b> {row.status === 1 ? (row.related_document_approval_step.related_approver.related_participant.related_user.first_name + ' '+ row.related_document_approval_step.related_approver.related_participant.related_user.last_name):(".................................")}</span> 
                          <span style={{ textAlign: "right", marginLeft:"15px"}}><b>{row.related_document_approval_step.related_approver.related_project_role.role_name}</b></span>
                          <span style={{ textAlign: "right", marginLeft:"15px"}}> <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")} </span>
                        </p> */}
                        <Table size="small">
                        <TableRow>
                          <TableCell style={{  minWidth: 50 }}>
                            <b>{row.related_document_approval_step.approval_step_complete} BY:</b>
                          </TableCell>
                          <TableCell>
                            {row.status === 1 ? (row.related_approver.related_user.first_name + ' '+ row.related_approver.related_user.last_name):(".................................")}
                          </TableCell>
                          <TableCell style={{  minWidth: 50 }}>
                            <b>{row.generic_role_name}</b>
                          </TableCell>
                          <TableCell>
                            <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")}
                          </TableCell>
                        </TableRow>
                        </Table>
                      </>
                      



                      
                    );
                  }))}
          </Box>      
        </Paper>
        <ReactToPrint
          trigger={()=> 
            <Button 
              size="small" 
              variant="contained"
              sx={{ 
                width: "20%",
                my: "15px",
              }}
              
            >
              Print
            </Button>  
          }
          content={()=>componentRef.current}
        />
        
      </>
                
  );

}

export default TravelRequisitionPrintPreviewForm;