
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert} from "@mui/material";

import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PaymentRequestDetailForm from "./paymentRequisitionItemDetailForm";
import { useProjectCostCenterList } from "FetchData";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment/moment";
import { odmasApi } from "api/pesabackend";


const requisitionSchema = yup.object().shape({
  related_activity: yup.string(),
  cost_center: yup.number(),
  title: yup.string().required("required"),
  total_amount: yup.number().required("required"),
  date: yup.date().required("required"),
  budget_category: yup.string().required("required"),
  meta_fields: yup.array()
  .of(
    yup.object().shape({
      description: yup.string().required("required"),
      budget_code: yup.string(),
      amount: yup.number().required("required"),
    })
  )
  .required('Please add requisition items')
  .min(1, 'At least on requisition item must be added'),
});



const PaymentRequestCreateForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const {projectId, relatedProjectDocument} = useParams();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const {projectCostCenterList} = useProjectCostCenterList(projectId);
  const [isFailedError, setIsFailedError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);

  const token =  useSelector((state) => state.token);
  const userProjects = useSelector((state) => state.projectsAttached);
  const currentProject =userProjects.find(project=> project.related_project.id===Number(projectId))



  const initialValues = {
    related_project_document: relatedProjectDocument,
    related_activity: "",
    title: "",
    total_amount: 0,
    date: moment().format('YYYY-MM-DD'),
    cost_center:"",
    currency_id:1,
    budget_category: "",
    meta_fields:[
      {
        budget_code: '', batch: '', date: moment().format('YYYY-MM-DD'), amount: '',  description: "", data_id: uuidv4(),
      }
    ]
  };

  const[paymentRequestDetailFormData, setPaymentRequestDetailFormData] = useState([{amount: '',  data_id: uuidv4(),
  }]);

  const [grandTotal,setGrandTotal] = useState(initialValues.total_amount);


  const fetchRequisitionData = async (requestId) => {
  
    const response = await odmasApi.get(`projects/requests/details/${requestId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    navigate("/paymentRequisitionViewForm", { state: response.data.data })
  };

  
  const saveRequisition = async (values, onSubmitProps) => {

    const savedItemResponse = await fetch(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/payment_requisition`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();  
        fetchRequisitionData(savedItem.id);
      }, 1500);
    }; 

    if (savedItem.status===404){
      setIsFailedError(savedItem.errors)
      setIsFailed(true);
    };

  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.amount)
    }, 0);
    setGrandTotal(parseFloat(grandTotalCalculation).toFixed(2));
  };


  const handleAmountChange = (event, index) =>{
    const {value} = event.target;
    const newArray = paymentRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, amount: Number(value)}
        }
        return item
    })
    setPaymentRequestDetailFormData(newArray);
    handleGrandTotal(newArray);
  };

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {amount: ''};
    setPaymentRequestDetailFormData([...paymentRequestDetailFormData, oneRow])
  }; 

  const handleRemoveItemRow = (index) => {
    let newFormData = paymentRequestDetailFormData
    newFormData.splice(index,1);
    setPaymentRequestDetailFormData(newFormData);
    handleGrandTotal(newFormData);      
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveRequisition(values, onSubmitProps);
  };
  
  return (
      <Box>
          <Navbar />
          <Box 
              sx={{
                display:"flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
                <Box 
                  sx={{
                    flexGrow: 1
                  }}
                >
                    <Sidebar isSidebar={isSidebar}/>  
                </Box>
                <Box 
                  sx={{
                    flexGrow: 999,
                    paddingX: "40px"
                  }}
                >
                  {
                    isSuccess ? ( 
                        <Alert sx={{ m:"15px" }} variant="filled" severity="success">Payment Requisition created successfully!</Alert>
                        
                    ) : isFailed ? (
                        <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
                    ) : null
                                                    
                  }
                  <h4>Payment Requisition Create Form</h4>

                  <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        
                        initialValues={initialValues}
                        validationSchema={requisitionSchema}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        resetForm, 
                        isSubmitting, 
                        
                        }) => (
                        <form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around"
                            }}
                            >
                            
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width:"40%"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                          
                                  <label>Date:  </label>
                                  <TextField
                                    label=""
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.date}
                                    disabled
                                    name="date"
                                    type="date"
                                    error={
                                    Boolean(touched.date) && Boolean(errors.date)
                                    }
                                    helperText={touched.date && errors.date}
                                    sx={{
                                      width: "100%",
                                      marginBottom: "15px",
                                    }}
                                  />
                              </Box>
                              <TextField
                                label="Purpose"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                multiline
                                rows={4}
                                value={values.title}
                                name="title"
                                error={
                                    Boolean(touched.title) && Boolean(errors.title)
                                }
                                helperText={touched.title && errors.title}
                                sx={{
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                            />
                              
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width:"40%",
                                marginTop: "15px"
                              }}
                            >
                              <TextField
                                    label="Budget Category"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.budget_category}
                                    name="budget_category"
                                    select
                                    error={Boolean(touched.budget_category) && Boolean(errors.budget_category)}
                                    helperText={touched.budget_category && errors.budget_category}
                                    sx={{ 
                                      width: "100%",
                                      marginBottom: "15px",
                                    }}
                                >

                                  <MenuItem  value="CONTRACTUAL">CONTRACTUAL</MenuItem>
                                    
                                    
                              </TextField>
                                              
                              <TextField
                                  label="Related Activity"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.related_activity}
                                  name="related_activity"
                                  error={Boolean(touched.related_activity) && Boolean(errors.related_activity)}
                                  helperText={touched.related_activity && errors.related_activity}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                              />

                              <TextField
                                  label="Cost Center"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.cost_center}
                                  name="cost_center"
                                  select
                                  error={Boolean(touched.cost_center) && Boolean(errors.cost_center)}
                                  helperText={touched.cost_center && errors.cost_center}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                              >
                                {projectCostCenterList.map((costCenter, index)=>{
                                  return(
                                  <MenuItem key={index} value={costCenter.id}>{costCenter.cost_center_name}</MenuItem>
                                  );
                                })}
                              </TextField>

                            </Box>

                              

                          </Box>
                          <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          >
                              <h5>Input the requisition items here:</h5> 

                              <FieldArray
                                name="meta_fields"
                                render={(arrayHelpers)=>{
                                  const meta_fields = values.meta_fields
                                
                                  return(
                                    <>
                                      {meta_fields.map((row, index) => {
                                        return (
                                          <PaymentRequestDetailForm key={row.data_id}
                                            index={index}
                                            data={row}
                                            handleRemoveItemRow={(index)=>{
                                              arrayHelpers.remove(index);
                                              handleRemoveItemRow(index);
                                            }}
                                            handleChange={handleChange}
                                            handleAmountChange = {handleAmountChange}
                                            errors={arrayHelpers.form.errors}
                                            touched={arrayHelpers.form.touched}
                                            name={`meta_fields[${index}]`}
                                            handleBlur={handleBlur}
                                          />
                                        )
                                      })}
                                       
                                      <Button
                                          onClick={()=>{
                                            arrayHelpers.push(
                                              {
                                                budget_code: '', batch: '', date: moment().format('YYYY-MM-DD'), amount: '',  description: "", data_id: uuidv4(),
                                              }
                                            );
                                            handleAddProductDetailSubmitData();
                                          }}
                                          sx={{
                                            m: "1rem 0",
                                          }}
                                          size="small"
                                          variant="contained"
                                      >
                                          Add Item
                                      </Button>
                                    </>
                                  )
                                }}
                              />
                                                   
                          </Box>  

                          <Box                         
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                mx: "2px"
                              }}
                            >
                              <label>Total:  </label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.total_amount = grandTotal}
                                name="total_amount"
                                disabled
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0
                                }}
                                error={
                                Boolean(touched.total_amount) && Boolean(errors.total_amount)
                                }
                                helperText={touched.total_amount && errors.total_amount}
                                sx={{
                                  // width: "100%",
                                  marginBottom: "15px",
                                }}
                              />
                            </Box>

                            
                              
                          </Box> 

                          {/* BUTTONS */}
                          <Box>
                            <Button
                                
                                type="submit"
                                disabled={isSubmitting}
                                variant="contained"
                                size="small"
                                sx={{
                                m: "1rem ",
                                p: "0.5rem",
                                }}
                            >
                                Save Requisition
                            </Button>
                            <Button
                                onClick={()=>{
                                  resetForm();
                                  navigate(`/projects/${projectId}`,{ state: { startTab:0, projectName: currentProject.related_project.project_name }});
                                }}
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{
                                m: "1rem",
                                p: "0.5rem",
                                }}
                            >
                                Cancel
                            </Button>
                          </Box>
                        </form>
                        )}
                    </Formik>
                  </Box>
                </Box>
          </Box>
      </Box>
  );
};

export default PaymentRequestCreateForm;
