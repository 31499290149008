
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    MenuItem,
    useTheme,
    Select,
    Typography,
    Switch,
    FormControlLabel
  } from "@mui/material";
  import { Formik } from "formik";
  import * as yup from "yup";
  import { useNavigate } from "react-router-dom";
  import { useSelector } from "react-redux";
  import { useProjectParticipantList } from "FetchData";
  import {  useState } from "react";
  
  const itemSchema = yup.object().shape({
    action: yup.string().required("required"),
    project: yup.string().required("required"),
    delegated_to: yup.string(),
    is_delegated: yup.boolean().required('required'),
  });
  
  
  const DelegationDetailForm = (props) => {
    const {index, data, handleDelegatedToChange} = props;
    const { palette } = useTheme();
    const navigate = useNavigate();
    const theme = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
  
    const token =  useSelector((state) => state.token);
  
  
    const initialValuesItem = {
      action: data.action.name,
      document: data.document,
      project: data.project,
      delegated_to: "",
      is_delegated:true
    };

    const[allowDelegation, setAllowDelegation]= useState(initialValuesItem.is_delegated);

    const handleAllowingDelegation = (e) => {
    
      if (e.target.checked === true ) {
        setAllowDelegation(false)
      }
      if (e.target.checked === false ) {
        setAllowDelegation(true)
      }
    };  
  
  
    const saveItem = async (values, onSubmitProps) => {
     
    };
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      saveItem(values, onSubmitProps);
    };
  
    return (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesItem }
        validationSchema={itemSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width:"100%",
                    alignItems: "center",
                    justifyContent: "space-around"
                }}
            >
              <Box sx={{ marginBottom:"25px" }}>
                <p >#{index+1}</p>
              </Box>
              <Box
                display="grid"
                gap="30px"
                
                gridTemplateColumns="repeat(16, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },width:"100%"
                }}
              >
                <Box sx={{ gridColumn: "span 3", display:"flex", alignItems:"center", marginBottom:"25px" }}>
                <FormControlLabel
                  value={values.is_delegated = allowDelegation}
                  name="is_delegated"
                  control={
                    <Switch 
                      color="primary" 
                      onChange={ (e)=>{
                        handleChange(e)
                        handleAllowingDelegation(e)
                      }}
                    />
                  }
                  label="Delegate Action:  No"
                  labelPlacement="start"
                />
                <Typography px="5px">Yes</Typography>
              </Box>
                
                <TextField
                  label="Action"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.action}
                  disabled
                  name="action"
                  error={
                    Boolean(touched.action) && Boolean(errors.action)
                  }
                  helperText={touched.action && errors.action}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  label="Document"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.document}
                  name="document"
                  error={Boolean(touched.document) && Boolean(errors.document)}
                  helperText={touched.document && errors.document}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextField
                  label="Project"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.project}
                  name="project"
                  error={Boolean(touched.project) && Boolean(errors.project)}
                  helperText={touched.project && errors.project}
                  sx={{ gridColumn: "span 3" }}
                />
    
                <TextField
                  label="Delegate To"
                  onBlur={handleBlur}
                  onChange={(e) =>{ 
                    handleChange(e); 
                    handleDelegatedToChange(e);
                  }}
                  select
                  disabled={allowDelegation}
                  value={values.delegated_to}
                  name="delegated_to"
                  error={Boolean(touched.delegated_to) && Boolean(errors.delegated_to)}
                  helperText={touched.delegated_to && errors.delegated_to}
                  sx={{ gridColumn: "span 3" }}
                >
                  <MenuItem value={"Jonthan Klein"}>Jonthan Klein</MenuItem>
                  <MenuItem value={"Bigumba kigozi"}>Bigumba kigozi</MenuItem>
                  <MenuItem value={"Kasiita Solomon"}>Kasiita Solomon</MenuItem>
                </TextField>
    
          
              </Box>
            </Box>

          </form>
        )}
      </Formik>
    );
  };
  
  export default DelegationDetailForm;
  