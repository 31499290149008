
import { Box,Button,TextField,useMediaQuery,useTheme, } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const holidaySchema = yup.object().shape({
  holiday_name: yup.string().required("required"),
  public_holiday: yup.date().required("required")
  
});



const initialValuesData = {
  holiday_name: "",
  public_holiday: moment().format("YYYY-MM-DD")
};


const CreateHolidayForm = (props) => {

  const pageType = props.formType;
  const { palette } = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const token =  useSelector((state) => state.token);
  const tenantId = useSelector((state)=> state.employeeData.related_tenant.id)

  // const isNew = pageType === "create";
  // const isEdit = pageType === "edit";

  // const initialEditValuesData = {
  //   holiday_name: editData.holiday_name,
  // };

  const saveItem = async (values, onSubmitProps) => {
    values.related_tenant=tenantId;

    const savedItemResponse = await fetch("https://api.odms.iolabz.ug/api/v1/tenants/public_holidays", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewDepartmentHolidayData(true);
      onSubmitProps.resetForm();
    
      props.setIsSuccess(true);
    }

  };

  const editItem = async (values, onSubmitProps) => {
    


  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
    // if (isEdit) await editItem(values, onSubmitProps);
  };
  
    return (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesData}
        validationSchema={holidaySchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                label="Holiday Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.holiday_name}
                name="holiday_name"
                error={Boolean(touched.holiday_name) && Boolean(errors.holiday_name)}
                helperText={touched.holiday_name && errors.holiday_name}
                sx={{ gridColumn: "span 2" }}
              />

              <TextField
                label="Date "
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.public_holiday}
                name="public_holiday"
                error={Boolean(touched.public_holiday) && Boolean(errors.public_holiday)}
                helperText={touched.public_holiday && errors.public_holiday}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
  
            {/* BUTTONS */}
            <Box>
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "1rem 0",
                  p: "1rem",
                  backgroundColor: palette.primary.main,
                  color: palette.background.alt,
                  "&:hover": { color: palette.primary.main },
                }}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    );
  };
  
  export default CreateHolidayForm;
