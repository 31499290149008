
import {Box,Button,TextField,useMediaQuery} from "@mui/material";
import {useState} from "react";

const ProcurementRequestDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveItemRow, 
        handleTotalCostChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    const [estimatedTotalCost, setEstimatedTotalCost] = useState(data.estimated_total_cost);
    const [itemQuantity, setitemQuantity] = useState(data.quantity);
    const [estimatedUnitCost, setEstimatedUnitCost] = useState(data.estimated_unit_cost);
    
    const handleCalculateEstimatedTotalCost = (estimatedUnitCost, itemQuantity) =>{
        const grandTotalCalculation = ()=>{
            return Number(estimatedUnitCost) * Number(itemQuantity)
        };
        setEstimatedTotalCost(grandTotalCalculation);
        handleTotalCostChange({target:{ value: (Number(estimatedUnitCost) * Number(itemQuantity)) }}, index);
        };


    return (
            
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                width:"100%"
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
                <>
                    <TextField
                        label="Description"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.description}
                        name={`${name}.description`}
                        error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.description) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.description)}
                        helperText={ touched?.meta_fields && touched?.meta_fields[index]?.description && errors?.meta_fields && errors?.meta_fields[index]?.description}
                        sx={{ gridColumn: "span 4" }}
                    />

                    <TextField
                        label="Quantity"
                        size="small"
                        onBlur={handleBlur}
                        type="number"
                        onChange={(e)=>{
                            handleChange(e);
                            setitemQuantity(e.target.value);
                            handleCalculateEstimatedTotalCost(estimatedUnitCost, e.target.value);
                        }}
                        value={data.quantity}
                        name={`${name}.quantity`}
                        error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.quantity) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.quantity)}
                        helperText={ touched?.meta_fields && touched?.meta_fields[index]?.quantity && errors?.meta_fields && errors?.meta_fields[index]?.quantity}
                        sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                        label="Unit of Measure"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={data.unit_of_measure}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.unit_of_measure`}
                        error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.unit_of_measure) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.unit_of_measure)}
                        helperText={ touched?.meta_fields && touched?.meta_fields[index]?.unit_of_measure && errors?.meta_fields && errors?.meta_fields[index]?.unit_of_measure}
                        sx={{ gridColumn: "span 2" }}
                    />    

                    <TextField
                        label="Estimated Unit Cost"
                        size="small"
                        onBlur={handleBlur}
                        onChange={(e)=>{
                            handleChange(e);
                            setEstimatedUnitCost(e.target.value);
                            handleCalculateEstimatedTotalCost(e.target.value, itemQuantity);
                        }}
                        value={data.estimated_unit_cost}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.estimated_unit_cost`}
                        error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.estimated_unit_cost) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.estimated_unit_cost)}
                        helperText={ touched?.meta_fields && touched?.meta_fields[index]?.estimated_unit_cost && errors?.meta_fields && errors?.meta_fields[index]?.estimated_unit_cost}
                        sx={{ gridColumn: "span 2" }}
                    /> 
                    
                    <TextField
                        label="Estimated Total Cost"
                        size="small"
                        onBlur={handleBlur}
                        disabled
                        onChange={handleChange}
                        value={data.estimated_total_cost=estimatedTotalCost}
                        inputProps={{
                            min: 0
                        }}
                        name={`${name}.estimated_total_cost`}
                        error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.estimated_total_cost) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.estimated_total_cost)}
                        helperText={ touched?.meta_fields && touched?.meta_fields[index]?.estimated_total_cost && errors?.meta_fields && errors?.meta_fields[index]?.estimated_total_cost}
                        sx={{ gridColumn: "span 2" }}
                    /> 

                    <Button
                        value={index}
                        onClick={() => {
                            handleRemoveItemRow(index);
                        }}                                                     
                        size="small"
                        variant="contained"
                        sx={{ 
                            width: "20%",
                            marginBottom:"20px",
                            }}
                        >
                        Remove
                    </Button>
                </>
            </Box>
        </Box>
    );
  };
  
  export default ProcurementRequestDetailForm;
  