import {
    Box,
    Alert, 
    useTheme
  } from "@mui/material";
  
  import { useState } from "react";
  import FullCalendar from "@fullcalendar/react";
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import { useActivityList } from "FetchData";
  
  const LeaveCalendar = ({setIsSuccess}) => {
  
    const theme = useTheme();
    const {activityList} =useActivityList(1);
    const [open, setOpen] = useState(false);
    const [isFailedError, setIsFailedError] = useState("");
    const [isFailed, setIsFailed]  = useState(false);
    const [selectedDate, setSelectedDate]=useState('');
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const event=[];
    activityList.forEach(element => {
      event.push({
        title: element.activity_name,
        start:element.start_date,
        end:element.end_date
        })
    });
  
    const eventr = [
      {
      title: "take 1",
      start:"2024-01-05",
      end:"2024-01-05"
      },
      {
        title: "take 2",
        start:"2024-01-07",
        end:"2024-01-20"
      }
    ]
  
    return (
        
      <Box>
        <FullCalendar
          plugins={[ dayGridPlugin, interactionPlugin ]}
          initialView="dayGridMonth"
          headerToolbar={{ 
            start:"",
            center:"title",
           }}
           height={"80vh"}
          events={event}
          eventDidMount={(info)=>{
            // return new bootstrap.Popover( info.el, {
            //   title:info.event.title,
            //   placement:"auto",
            //   trigger:"hover",
            //   customClass: "popoverStyle",
            //   content: "<p> Anoda</p>",
            //   html:"true"
            // }
            // )
          }}
        //   selectable
        //   select={ (start, end) => {
        //     setSelectedDate(start);
        //     setOpen(true);
        //   }}
        />
        
      </Box>              
    );
  
  };
  
  export default LeaveCalendar;