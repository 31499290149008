
import {Box,Button,TextField,useMediaQuery,useTheme,Alert} from "@mui/material";

import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PreTripRequestDetailForm from "./travelRequisitionPreTripRequestDetailForm";
import TravelAdvanceRequestDetailForm from "./travelRequisitionTravelAdvanceRequestDetailForm";
import { useProjectCostCenterList } from "FetchData";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment/moment";
import { odmasApi } from "api/pesabackend";


const requisitionSchema = yup.object().shape({
  title: yup.string().required("required"),
  total_amount: yup.number().required("required"),
  date: yup.date().required("required"),
  pre_trip_request_fields: yup.array()
  .of(
    yup.object().shape({
      date_of_travel: yup.date().required("required"),
      origin_location: yup.string().required("required"),
      destination_location: yup.string().required("required"),
      transport_mode: yup.string().required("required"),
      time_of_day: yup.string().required("required"),
      number_of_travelers: yup.number().required("required"),
      })
  )
  .required("required"),
  travel_advance_request_fields: yup.array()
  .of(
    yup.object().shape({
      name_of_persons: yup.string().required("required"),
      designation: yup.string().required("required"),
      days: yup.number().required("required"),
      rate: yup.number().required("required"),
      total: yup.number().required("required"),
      })
  )
  .required("required"),
});


const TravelRequestCreateForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const {projectId, relatedProjectDocument} = useParams();
  const { palette } = useTheme();
  const navigate = useNavigate();
  const {projectCostCenterList} = useProjectCostCenterList(projectId);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);


  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false);
      setIsSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess,isFailed]);

  const token =  useSelector((state) => state.token);
  const userProjects = useSelector((state) => state.projectsAttached);
  const currentProject =userProjects.find(project=> project.related_project.id===Number(projectId))



  const initialValues = {
    related_project_document: relatedProjectDocument,
    title: "",
    total_amount: 0,
    date: moment().format('YYYY-MM-DD'),
    pre_trip_request_fields:[
      {
        origin_location: '', destination_location: '', date_of_travel: moment().format('YYYY-MM-DD'), time_of_day: moment().format('HH:mm'), transport_mode: '',  number_of_travelers: "", data_id: uuidv4(),
      }
    ],
    travel_advance_request_fields:[
      {
        name_of_persons: '', designation: '', days: 0, rate: '',  total: "", data_id: uuidv4(),
      }
    ]
  };

  const[preTripRequestDetailFormData, setPreTripRequestDetailFormData] = useState([{
    origin_location: '', destination_location: '', date_of_travel: moment().format('YYYY-MM-DD'), time_of_day: moment().format('HH:mm'), transport_mode: '',  number_of_travelers: "", data_id: uuidv4(),
  }]);

  const[tripAdvanceRequestDetailFormData, setTripAdvanceRequestDetailFormData] = useState([{
    name_of_persons: '', designation: '', days: 0, rate: '',  total: "", data_id: uuidv4(),
  }]);

  const [grandTotal,setGrandTotal] = useState(initialValues.total_amount);
  

  const fetchRequisitionData = async (requestId) => {
  
    const response = await odmasApi.get(`projects/requests/details/${requestId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    navigate("/travelRequisitionViewForm", { state: response.data.data })
  };

  const saveRequisition = async (values, onSubmitProps) => {

    const savedItemResponse = await fetch(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/travel_requisition`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();  
        fetchRequisitionData(savedItem.id);
      }, 1500);
    }; 

    if (savedItem.status===404){
      setIsFailedError(savedItem.errors)
      setIsFailed(true);
    };

  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.total)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };


  const handleTotalCostChange = (event, index) =>{
    const {daysI, rateI, value} = event.target;
    console.log({daysI, rateI, value})
      const newArray = tripAdvanceRequestDetailFormData.map((item, idx) => {
          if (idx === index) {
              return {...item, total: value, rate: rateI, days: daysI}
          }
          return item
      })
      setTripAdvanceRequestDetailFormData(newArray);
      handleGrandTotal(newArray);
    };
    

  const handleAddTripAdvanceRequestDetailSubmitData = () => {
    const oneRow = {
      name_of_persons: '', designation: '', days: 0, rate: '',  total: "", data_id: uuidv4()
    };
    setTripAdvanceRequestDetailFormData([...tripAdvanceRequestDetailFormData, oneRow])
  }; 

  const handleAddPreTripRequestDetailData = () => {
    const oneRow = {
      origin_location: '', destination_location: '', date_of_travel: moment().format('YYYY-MM-DD'), time_of_day: moment().format('HH:mm'), transport_mode: '',  number_of_travelers: "", data_id: uuidv4()
    };
    setPreTripRequestDetailFormData([...preTripRequestDetailFormData, oneRow])
  }; 


  const handleRemovePreTripItemRow = (index) => {
    let newFormData = preTripRequestDetailFormData
    newFormData.splice(index,1)
    setPreTripRequestDetailFormData(newFormData);   
  };

  const handleRemoveTravelAdvanceItemRow = (index) => {
    let newFormData = tripAdvanceRequestDetailFormData
    newFormData.splice(index,1)
    setTripAdvanceRequestDetailFormData(newFormData);
    handleGrandTotal(newFormData);      
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveRequisition(values, onSubmitProps);
  };
  
  return (
      <Box>
          <Navbar />
          <Box 
              sx={{
                display:"flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
                <Box 
                  sx={{
                    flexGrow: 1
                  }}
                >
                    <Sidebar isSidebar={isSidebar}/>  
                </Box>
                <Box 
                  sx={{
                    flexGrow: 999,
                    paddingX: "40px"
                  }}
                >
                  {
                    isSuccess ? ( 
                        <Alert sx={{ m:"15px" }} variant="filled" severity="success">Travel Requisition created successfully!</Alert>
                    ) : isFailed ? (
                        <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
                    ) : null
                                                    
                  }
                  <h4>Travel Requisition Create Form</h4>

                  <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        
                        initialValues={initialValues}
                        validationSchema={requisitionSchema}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        resetForm
                        
                        }) => (
                        <form onSubmit={handleSubmit}>
                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around"
                            }}
                          >
                          
                          
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                        
                                <label>Date of Request:  </label>
                                <TextField
                                  label=""
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.date}
                                  disabled
                                  name="date"
                                  type="date"
                                  error={
                                  Boolean(touched.date) && Boolean(errors.date)
                                  }
                                  helperText={touched.date && errors.date}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                                />
                            </Box>
                            <TextField
                              label="B. Trip Scope of Work (Attach or fill in a SOW for the trip)"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              multiline
                              rows={4}
                              value={values.title}
                              name="title"
                              error={
                                  Boolean(touched.title) && Boolean(errors.title)
                              }
                              helperText={touched.title && errors.title}
                              sx={{
                                width: "100%",
                                marginBottom: "15px",
                              }}
                            />
                            
                          </Box>

                          <Box
                            gridColumn="span 4"
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            >
                              <h5>C. Pre Trip Request*</h5> 
                              
                              <FieldArray
                                name="pre_trip_request_fields"
                                render={(arrayHelpers)=>{
                                  const pre_trip_request_fields = values.pre_trip_request_fields
                                
                                  return(
                                    <>
                                      {pre_trip_request_fields.map((row, index) => {
                                        return (
                                          <PreTripRequestDetailForm key={row.data_id}
                                            index={index}
                                            data={row}
                                            handleRemovePreTripItemRow={(index)=>{
                                              arrayHelpers.remove(index);
                                              handleRemovePreTripItemRow(index);
                                            }}
                                            handleChange={handleChange}
                                            errors={arrayHelpers.form.errors}
                                            touched={arrayHelpers.form.touched}
                                            name={`pre_trip_request_fields[${index}]`}
                                            handleBlur={handleBlur}
                                          />
                                        )
                                      })}
                              
                                      <Button
                                          onClick={()=>{
                                            arrayHelpers.push(
                                              {
                                                origin_location: '', destination_location: '', date_of_travel: moment().format('YYYY-MM-DD'), time_of_day: moment().format('HH:mm'), transport_mode: '',  number_of_travelers: "", data_id: uuidv4(),
                                              }
                                            );
                                            handleAddPreTripRequestDetailData();
                                          }}
                                          sx={{
                                            m: "1rem 0",
                                          }}
                                          size="small"
                                          variant="contained"
                                      >
                                          Add Item
                                      </Button>
                                    </>
                                  )
                                }}
                              />
                                
                          </Box>  
                          <br/>         
                          <Box
                            gridColumn="span 4"
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            >
                              <h5>D. Travel Advance Request (if the partner has travel advance policy)</h5> 
                              
                              <FieldArray
                                name="travel_advance_request_fields"
                                render={(arrayHelpers)=>{
                                  const travel_advance_request_fields = values.travel_advance_request_fields
                                
                                  return(
                                    <>
                                      {travel_advance_request_fields.map((row, index) => {
                                        return (
                                          <TravelAdvanceRequestDetailForm key={row.data_id}
                                            index={index}
                                            data={row}
                                            handleRemoveTravelAdvanceItemRow={(index)=>{
                                              arrayHelpers.remove(index);
                                              handleRemoveTravelAdvanceItemRow(index);
                                            }}
                                            handleTotalCostChange = {handleTotalCostChange}
                                            handleChange={handleChange}
                                            errors={arrayHelpers.form.errors}
                                            touched={arrayHelpers.form.touched}
                                            name={`travel_advance_request_fields[${index}]`}
                                            handleBlur={handleBlur}
                                          />
                                        )
                                      })}
                                    
                                      <Button
                                          onClick={()=>{
                                            arrayHelpers.push(
                                              {
                                                name_of_persons: '', designation: '', days: 0, rate: '',  total: "", data_id: uuidv4(),
                                              }
                                            );
                                            handleAddTripAdvanceRequestDetailSubmitData();
                                          }}
                                          sx={{
                                            m: "1rem 0",
                                          }}
                                          size="small"
                                          variant="contained"
                                      >
                                          Add Item
                                      </Button>
                                    </>
                                  )
                                }}
                              />
                                
                          </Box>

                          <Box                         
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                mx: "2px"
                              }}
                            >
                              <label>Sub Total:  </label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.total_amount = grandTotal}
                                name="total_amount"
                                disabled
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0
                                }}
                                error={
                                Boolean(touched.total_amount) && Boolean(errors.total_amount)
                                }
                                helperText={touched.total_amount && errors.total_amount}
                                sx={{
                                  // width: "100%",
                                  marginBottom: "15px",
                                }}
                              />
                            </Box>

                            
                              
                          </Box> 

                          {/* BUTTONS */}
                          <Box>
                            <Button
                                
                                type="submit"
                                variant="contained"
                                size="small"
                                sx={{
                                m: "1rem ",
                                p: "0.5rem",
                                }}
                            >
                                Save Requisition
                            </Button>
                            <Button
                              onClick={()=>{
                                resetForm();
                                navigate(`/projects/${projectId}`,{ state: { startTab:0, projectName: currentProject.related_project.project_name }});
                              }}
                              color="error"
                              variant="contained"
                              size="small"
                              sx={{
                              m: "1rem",
                              p: "0.5rem",
                              }}
                            >
                                Cancel
                            </Button>
                          </Box>
                        </form>
                        )}
                    </Formik>
                  </Box>
                </Box>
          </Box>
      </Box>
  );
};

export default TravelRequestCreateForm;
