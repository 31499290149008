import { Box, useMediaQuery, Tab, Tabs, Typography, Alert } from "@mui/material";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import ProjectDashboard from "scenes/project";
import ProjectDocuments from "../tables/projectDocuments";
import ProjectParticipantTable from "../../projectSetup/tables/projectParticipantTable";
import Memos from "../../memos/tables/memoRequestTable";
// import Requisitions from "../../approvals/tables/requisitionRequestTable";
import TravelRequisitions from "scenes/requisitions/tables/travelRequisitionTable";
import PaymentRequisitions from "scenes/requisitions/tables/paymentRequisitionTable";
import ProcurementRequisitions from "scenes/requisitions/tables/procurementRequisitionTable";
import { useParams, useLocation } from "react-router-dom";
import { useProjectData } from "FetchData";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProjectTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const location = useLocation();
  const [value, setValue] = React.useState(location.state.startTab);

  React.useEffect(()=>{
    setValue(location.state.startTab)
  },[location.state.projectName] )
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);
  // const { _id, picturePath } = useSelector((state) => state.user);
  const {projectId} = useParams()
  const {projectData, isLoadingProjectData,errorLoadingProjectData} = useProjectData(projectId);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999
            }}
          > 
               {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="filled" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
                ) : null
                                                
              }
              <h2 style={{ marginLeft: "15px" }}> {location.state.projectName}</h2>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs fullWidth value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label="Project Dashboard" {...a11yProps(0)} />
                  <Tab label="Memos" {...a11yProps(1)} />
                  <Tab label="Procurement Requisitions" {...a11yProps(2)} />
                  <Tab label="Travel Requisitions" {...a11yProps(3)} />
                  <Tab label="Payment Requisitions" {...a11yProps(4)} />
                  <Tab label="Project Documents" {...a11yProps(5)} />
                  <Tab label="Attached Users" {...a11yProps(6)} />
                  {/* <Tab label="Project Roles" {...a11yProps(3)} /> */}
                </Tabs>
              </Box>
              <Box>
                
                <TabPanel value={value} index={0}>
                  <ProjectDashboard projectId={projectId} setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Memos projectId={projectId} setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <ProcurementRequisitions projectId={projectId} setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <TravelRequisitions projectId={projectId} setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <PaymentRequisitions projectId={projectId} setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <ProjectDocuments projectId={projectId} setIsSuccess={setIsSuccess} setIsFailed={setIsFailed}/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <ProjectParticipantTable projectId={projectId} setIsSuccess={setIsSuccess} />
                </TabPanel>
                
              </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default ProjectTab;