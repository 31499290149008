
import {
  Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Select,InputLabel,OutlinedInput, Alert} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProjectParticipantList } from "FetchData";
import {  useState, useEffect} from "react";
import moment from "moment";
import { odmasApi } from "api/pesabackend";
import axios from "axios";

const itemSchema = yup.object().shape({
  title: yup.string().required("required"),
  background: yup.string().required("required"),
  purpose: yup.string().required("required"),
  thru: yup.array(),
  to: yup.number().required('required')
});


const MemoCreateForm = (props) => {
  let {projectId, relatedProjectDocument} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);

  const {projectParticipantList, isLoadingProjectParticipantList} = useProjectParticipantList(1,projectId)

  const [thruPersons, setThruPersons] = useState([]); 
  const [personsThrough, setPersonsThrough] = useState([])
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false);
      setIsFailed(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  const initialValuesItem = {
    date: moment().format('YYYY-MM-DD'),
    title: "",
    background: "",
    purpose: "",
    to:"",
    thru:[]
  };

  

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setThruPersons(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleAddPersonThrough = () => {
    const personsThroughList = []
    thruPersons.forEach(element => {
      personsThroughList.push({"id":element})
    });
    setPersonsThrough(personsThroughList);
  };
  
  const fetchMemoData = async (requestId) => {
  
    const response = await odmasApi.get(`projects/requests/details/${requestId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    navigate("/memoViewForm", { state: response.data.data })
};

  const saveItem = async (values, onSubmitProps) => {
    values.related_project_document = relatedProjectDocument
    values.persons_through = personsThrough

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/projects/requests/memos", {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();
    
    
    if (savedItem.status===201) {
      setIsSuccess(true);
      onSubmitProps.resetForm(); 
      
      setTimeout(() => {
        props.setOpen(false);
        fetchMemoData(savedItem.id)
      }, 1500);
      
      
    }
  };



  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          {
            isSuccess ? ( 
                <Alert sx={{ m:"15px" }} variant="filled" severity="success">Memo created successfully!</Alert>
                
            ) : isFailed ? (
                <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
            ) : null
                                            
          }
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TextField
              label="Date"
              onBlur={handleBlur}
              onChange={handleChange}
              type="date"
              disabled
              value={values.date}
              name="date"
              error={
                Boolean(touched.date) && Boolean(errors.date)
              }
              helperText={touched.date && errors.date}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
              name="title"
              error={
                Boolean(touched.title) && Boolean(errors.title)
              }
              helperText={touched.title && errors.title}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Background"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={3}
              value={values.background}
              name="background"
              error={Boolean(touched.background) && Boolean(errors.background)}
              helperText={touched.background && errors.background}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Purpose"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={10}
              value={values.purpose}
              name="purpose"
              error={Boolean(touched.purpose) && Boolean(errors.purpose)}
              helperText={touched.purpose && errors.purpose}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="To"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.to}
              name="to"
              error={Boolean(touched.to) && Boolean(errors.to)}
              helperText={touched.to && errors.to}
              sx={{ gridColumn: "span 4" }}
            >
              {projectParticipantList.map((participant) => (
                <MenuItem
                  key={participant.id}
                  value={participant.id}
                  
                >
                  {participant.related_participant.related_user.first_name} {participant.related_participant.related_user.last_name} - {participant.related_project_role.role_name}
                </MenuItem>
              ))}
            </TextField>


            <InputLabel id="demo-multiple-name-label">Thru</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              value={values.thru = thruPersons}
              onChange={ (e, value)=>{
                handleChange(e);
                handleChangeSelect(e);
                
              }
              }
              onClose={handleAddPersonThrough}
              input={<OutlinedInput label="Thru" />}
              sx={{ gridColumn: "span 3" }}
              
            >
              {projectParticipantList.map((participant) => (
                <MenuItem
                  key={participant.id}
                  value={participant.id}
                  
                >
                  {participant.related_project_role.role_name}
                </MenuItem>
              ))}
            </Select>

      
      </Box>

      {/* BUTTONS */}
      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >

        <Button
          fullWidth
          type="submit"
          size="small"
          disabled={buttonDisable}
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
            gridColumn: "span 3" 
          }}
        >
          Save Memo
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default MemoCreateForm;
