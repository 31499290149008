import {
  Box,
  Alert, 
  useTheme
} from "@mui/material";

import { useState } from "react";
import ResponsiveDialog from "components/dialog";
import ActivityCreateForm from "./activityCreateForm";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useActivityList } from "FetchData";
import moment from "moment";
// import * as bootstrap from "bootstrap";
// import "bootstrap/distcss/bootstrap.min.css";

const ActivityCalendar = ({setIsSuccess}) => {

  const theme = useTheme();
  const {activityList} =useActivityList(1);
  const [open, setOpen] = useState(false);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [selectedDate, setSelectedDate]=useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const event=[];
  activityList.forEach(element => {
    event.push({
      title: element.activity_name,
      start:element.start_date,
      end:element.end_date
      })
  });

  const eventr = [
    {
    title: "take 1",
    start:"2024-01-05",
    end:"2024-01-05"
    },
    {
      title: "take 2",
      start:"2024-01-07",
      end:"2024-01-20"
    }
  ]

  return (
      
    <Box>
      <FullCalendar
        plugins={[ dayGridPlugin, interactionPlugin ]}
        initialView="dayGridMonth"
        headerToolbar={{ 
          start:"",
          center:"title",
         }}
         height={"80vh"}
        events={event}
        eventDidMount={(info)=>{
          // return new bootstrap.Popover( info.el, {
          //   title:info.event.title,
          //   placement:"auto",
          //   trigger:"hover",
          //   customClass: "popoverStyle",
          //   content: "<p> Anoda</p>",
          //   html:"true"
          // }
          // )
        }}
        selectable
        select={ (start, end) => {
          setSelectedDate(start);
          setOpen(true);
        }
        }
      />
      <ResponsiveDialog
        dialogButtonName = "Add Activity "
        dialogHeading = "Add a New Activity"
        open = {open}
        handleClickOpen = {handleClickOpen}
        handleClose = {handleClose}
        negativeResponse = "Cancel"
        content = {
                    <Box
                      p="1rem"
                      borderRadius="0.5rem"
                      backgroundColor={theme.palette.background.alt}
                    >
                      {
                        isFailed ? (
                            <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
                        ) : null
                                                        
                      }
                      <ActivityCreateForm 
                        setOpen={setOpen}  setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} setIsFailedError={setIsFailedError} data={{activity_name:"", start_date: selectedDate.startStr, end_date: selectedDate.endStr, is_recurring:false, recurring_frequency:"N/A", has_report: false}}
                      />
                    </Box>
                  }
      ></ResponsiveDialog>
    </Box>              
  );

};

export default ActivityCalendar;