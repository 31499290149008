import {
  Box,
  Paper,
  Alert,
  Button,
  Grid, 
  useTheme
} from "@mui/material";

import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useState } from "react";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TravelRequisitionPrintPreviewForm from "./travelRequestPrintPreviewForm";
import ResponsiveDialog from "components/dialog";
import DocumentUploadForm from "scenes/global/documentUploadForm";
import { useUploadedDocumentList } from "FetchData";



const TravelRequisitionViewForm = () => {

  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const theme = useTheme();

  const relatedDocumentRequest = location.state.related_document_request.id;
  const projectId = location.state.related_document_request.related_project;
  const date_created = moment(location.state.related_document_request.date_added).format('LL');
  const title= location.state.title;
  const background= location.state.background;
  const purpose=location.state.purpose;
  const related_project=location.state.related_document_request.related_project_document.related_project.project_name;
  const approval_steps = location.state.approval_steps;
  const to = location.state.to;
  const total = location.state.total;
  const meta_fields = location.state.meta_fields;
  const supportingDocuments = []
  const travel_advance_request_fields = location.state.travel_advance_request_fields
  const added_by = location.state.related_document_request.added_by.first_name + ' ' + location.state.related_document_request.added_by.last_name
  const {uploadedDocumentList, isLoadingUploadedDocumentList, setNewUploadedDocumentData, setSearchUploadedDocument} = useUploadedDocumentList(1,'project_request_document', relatedDocumentRequest)
  
  const [open, setOpen] = useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleOpenDocumentUpload = () => {
    setOpenDocumentUpload(true);
  };
  
  const handleCloseDocumentUpload = () => {
        setOpenDocumentUpload(false);
      };


  return (
    <Box>
      <Navbar />
      <Box 
        sx={{
          display:"flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
          <Box 
            sx={{
              flexGrow: 1
            }}
          >
              <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999,
              paddingX: "40px",
              marginX: "40px",
              justifyContent: "space-between"
            }}
          >
            {
              isSuccess ? ( 
                  <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
              ) : isFailed ? (
                  <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
              ) : null                                  
            }

            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs sx={{ mx: '4px'}} >
              <Paper 
                  sx={{ width: '100%',
                        padding: "40px",
                        margin: "40px",
                      }}
                  elevation={3}
              >
                
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>RECTRACK</h3 >
                {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
                {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h3 > */}
                <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>TRAVEL REQUEST AUTHORIZATION</h3 >

                <p> <i>This form is to be filled out BEFORE the trip for trip approval.</i></p>
                    
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "yellow" }}>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            Traveller Names:
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            {added_by}
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            Date of Request:
                          </TableCell>
                          <TableCell
                            sx={{ backgroundColor: "#FFFF9E" }}
                          >
                            {date_created}
                          </TableCell>
                                                            
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <br/> 
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={2} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          A. Project Information
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          Project Name
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Project Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {related_project}
                        </TableCell>
                        <TableCell>
                          
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={1} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          B. Trip Scope of Work (Attach or fill in a SOW for the trip)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <i>Indicate, in detail, what will be accomplished during this trip.</i>
                        </TableCell>                                
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {title}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={3} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          C. Pre Trip Request*
                        </TableCell>
                        <TableCell
                          colSpan={3} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          Transportation Arrangements
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Date of Travel
                        </TableCell>    
                        <TableCell>
                          Origin (Location)
                        </TableCell>                             
                        <TableCell>
                          Destination (Location)
                        </TableCell> 
                        <TableCell>
                          Mode
                        </TableCell> 
                        <TableCell>
                          Time of Day
                        </TableCell> 
                        <TableCell>
                          Number of Travelers
                        </TableCell> 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {meta_fields.map((row,indexedDB)=>{
                        return(
                          <TableRow>
                            <TableCell>
                              {row.date_of_travel}
                            </TableCell>
                            <TableCell>
                              {row.origin_location}
                            </TableCell>
                            <TableCell>
                              {row.destination_location}
                            </TableCell>
                            <TableCell>
                              {row.transport_mode}
                            </TableCell>
                            <TableCell>
                              {row.time_of_day}
                            </TableCell>
                            <TableCell>
                              {row.number_of_travelers}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      
                    </TableBody>
                  </Table>
                </TableContainer>
                <p><small> <b>* If more space is needed for travel schedule, you must attach a spreadsheet with the above columns and information.</b></small></p>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          colSpan={5} sx={{ backgroundColor: "#c7e9e6" }}
                        >
                          D. Travel Advance Request (if the partner has travel advance policy)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Person
                        </TableCell>    
                        <TableCell>
                          Designation
                        </TableCell>                             
                        <TableCell>
                          Days
                        </TableCell> 
                        <TableCell>
                          Rate
                        </TableCell> 
                        <TableCell>
                          Total
                        </TableCell> 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {travel_advance_request_fields.map((row,indexedDB)=>{
                        return(
                          <TableRow>
                            <TableCell>
                              {row.name_of_persons}
                            </TableCell>
                            <TableCell>
                              {row.designation}
                            </TableCell>
                            <TableCell>
                              {row.days}
                            </TableCell>
                            <TableCell>
                              {row.rate}
                            </TableCell>
                            <TableCell>
                              {row.total}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={3} align="right" >
                          <b>Sub Total:</b>
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={2}  align="right" >
                          Contingency
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                      <TableRow   >
                        <TableCell rowSpan={4} />
                        <TableCell colSpan={1}  align="right" >
                          <b>Total:</b>
                        </TableCell>
                        <TableCell align="right"  >
                          
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>   
                      
                <Button 
                  // value={row.id} 
                  size="small" 
                  variant="contained"
                  sx={{ 
                    width: "20%",
                    my: "15px",
                  }}
                  onClick={handleClickOpen}
                >
                  Print Preview
                </Button>

                <ResponsiveDialog
                  dialogButtonName = "Add Comment "
                  dialogHeading = ""
                  open = {open}
                  handleClickOpen = {handleClickOpen}
                  handleClose = {handleClose}
                  negativeResponse = "Cancel"
                  content = {
                              <TravelRequisitionPrintPreviewForm data={location}/>
                            }
                />
                
              </Paper>
              </Grid> 
              <Grid xs sx={{ mx: '4px'}} >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width:"100%",
                    marginBottom: "15px"
                  }}
                >
                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <h3 >Approval List</h3 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  minWidth: 50}}
                              >
                                Approval Step
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Approver Role
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                Approver 
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Status
                              </TableCell>
                              
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!approval_steps ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (approval_steps
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              return (
                                <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                  
                                    <TableCell key={row.id}>
                                      {row.related_document_approval_step.approval_step_name}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.generic_role_name}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {
                                        row.status === 0 ? ("PENDING") 
                                        :
                                        row.status === 1 ? ("APPROVED") 
                                        :
                                        row.status === 2 ? ("REJECTED") 
                                        :
                                        row.status === 3 ? ("DEFERRED") 
                                        :
                                        null 
                                      }
                                    </TableCell>
                                  
                                </TableRow>
                              );
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                              
                  </Paper>

                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <h3 >Comments</h3 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  minWidth: 50}}
                              >
                                No.
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                COMMENT
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                CREATED BY
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                DATE CREATED
                              </TableCell>
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!approval_steps ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (approval_steps
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              if (row.approval_comment !== null) {
                                return (
                                  <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                
                                      <TableCell key={row.id}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.approval_comment}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {moment(row.date_performed).format('L')}
                                      </TableCell>
                                    
                                  </TableRow>)
                              }  
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>                   
                    
                  </Paper>

                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <Box
                      sx={{ 
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "10px",
                        }}
                    >
                      <h3 >Supporting Documents</h3 >
                      <Button 
                        variant="contained" 
                        size="small" 
                        onClick={handleOpenDocumentUpload}
                        sx={{ 
                          width: "20%",
                          my: "15px",
                        }}
                      >
                        Add Document
                      </Button>
                      
                    </Box>
                    <ResponsiveDialog
                        dialogButtonName = "Add Document "
                        dialogHeading = "Add a Supporting Document"
                        open = {openDocumentUpload}
                        handleClickOpen = {handleOpenDocumentUpload}
                        handleClose = {handleCloseDocumentUpload}
                        negativeResponse = "Cancel"
                        content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      <DocumentUploadForm 
                                        setOpen={setOpenDocumentUpload}  setIsSuccess={setIsSuccess} uploadedDocumentType={"SUPPORTING DOCUMENTS"} uploadedDocumentCategory={1} relatedDocumentRequest={relatedDocumentRequest} setNewUploadedDocumentData={setNewUploadedDocumentData}
                                      />
                                    </Box>
                                  }
                      />
                      
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  width: 20}}
                              >
                                No.
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Date Uploaded
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 75 }}
                              >
                                File Name
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                Description
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Actions
                              </TableCell>
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uploadedDocumentList.length===0 ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (uploadedDocumentList
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              return (
                                <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                  
                                    <TableCell key={row.id}>
                                      {row.id}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {moment(row.date_added).format('L')}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.file_name}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.description}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      <Button 
                                        value={row.id} 
                                        size="small" 
                                        // onClick={(e) => handleClickEditOpen(e)}
                                      >
                                        Download 
                                      </Button>
                                    </TableCell>
                                  
                                </TableRow>
                              );
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>                  
                    
                  </Paper>
                  <Box
                  sx={{
                    display:"flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                    padding: "10px",
                    marginX: "40px",
                  }}
                >
                  <Button 
                    variant="contained"  
                    sx={{marginX:"5px"}}
                    
                  >
                    EDIT
                  </Button>

                  <Button 
                    variant="contained"  
                    color="error"
                    sx={{marginX:"5px"}}
                    
                  >
                    DELETE
                  </Button>
                </Box>
       
                </Box>
              </Grid> 
            </Grid>  
              

          </Box>
      </Box>
    </Box>  

                      
                
  );

}

export default TravelRequisitionViewForm;