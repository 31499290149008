
import {Box,Button,TextField,useMediaQuery,MenuItem} from "@mui/material";
import {useState} from "react";


const TravelAdvanceRequestDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveTravelAdvanceItemRow, 
        handleTotalCostChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    const [estimatedTotalCost, setEstimatedTotalCost] = useState(data.total);
    const [daysInput, setDaysInput] = useState(data.days);
    const [rateInput, setRateInput] = useState(data.rate);
    
    const handleCalculateSubTotal = (rateI, daysI) =>{  
        setEstimatedTotalCost((Number(rateI) * Number(daysI)));
        handleTotalCostChange({target:{ rateI, daysI, value: (Number(rateI) * Number(daysI)) }}, index);
        };

    return (
            
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                width:"100%"
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
               
                <TextField
                    label="Traveller Name"
                    size="small"
                    onBlur={handleBlur}
                    onChange={ handleChange}
                    value={data.name_of_persons}
                    name={`${name}.name_of_persons`}
                    error={Boolean( touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.name_of_persons) && Boolean(errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.name_of_persons)}
                    helperText={ touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.name_of_persons && errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.name_of_persons}
                    sx={{ gridColumn: "span 4" }}
                />

                <TextField
                    label="Desigation"
                    size="small"
                    onBlur={handleBlur}
                    select
                    onChange={handleChange}
                    value={data.designation}
                    name={`${name}.designation`}
                    error={Boolean( touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.designation) && Boolean(errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.designation)}
                    helperText={ touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.designation && errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.designation}
                    sx={{ gridColumn: "span 2" }}
                >
                    <MenuItem  value="Officer">Officer</MenuItem>
                    <MenuItem  value="Driver">Driver</MenuItem>
                </TextField>

                <TextField
                    label="Days"
                    size="small"
                    type="number"
                    onBlur={handleBlur}
                    onChange={(e)=>{
                        handleChange(e);
                        setDaysInput(e.target.value);
                        handleCalculateSubTotal(rateInput, e.target.value);
                    }}
                    value={data.days}
                    inputProps={{
                        min: 0
                    }}
                    name={`${name}.days`}
                    error={Boolean( touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.days) && Boolean(errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.days)}
                    helperText={ touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.days && errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.days}
                    sx={{ gridColumn: "span 1" }}
                />    

                <TextField
                    label="Rate"
                    size="small"
                    onBlur={handleBlur}
                    type="number"
                    onChange={(e)=>{
                        handleChange(e);
                        setRateInput(e.target.value);
                        handleCalculateSubTotal(e.target.value, daysInput);
                    }}
                    value={data.rate}
                    inputProps={{
                        min: 0
                    }}
                    name={`${name}.rate`}
                    error={Boolean( touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.rate) && Boolean(errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.rate)}
                    helperText={ touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.rate && errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.rate}
                    sx={{ gridColumn: "span 2" }}
                /> 
                
                <TextField
                    label="Total"
                    size="small"
                    onBlur={handleBlur}
                    disabled
                    onChange={handleChange}
                    value={data.total=estimatedTotalCost}
                    inputProps={{
                        min: 0
                    }}
                    name={`${name}.total`}
                    error={Boolean( touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.total) && Boolean(errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.total)}
                    helperText={ touched?.travel_advance_request_fields && touched?.travel_advance_request_fields[index]?.total && errors?.travel_advance_request_fields && errors?.travel_advance_request_fields[index]?.total}
                    sx={{ gridColumn: "span 2" }}
                /> 

                <Button
                    value={index}
                    onClick={() => {
                        handleRemoveTravelAdvanceItemRow(index);
                    }}                                                     
                    size="small"
                    variant="contained"
                    sx={{ 
                        width: "20%",
                        marginBottom:"20px",
                        }}
                    >
                    Remove
                </Button>

            </Box>
        </Box>      
    );
  };
  
  export default TravelAdvanceRequestDetailForm;
  