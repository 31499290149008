import * as React from "react";
import {  odmasApi } from "api/pesabackend";
import { useSelector } from "react-redux";
import moment from "moment";


// ######################################### LIST CALLS ####################################################


const useProjectList = (pageNumber) => {
    const [projectList, setProjectList] = React.useState([]);
	const [isLoadingProjectList, setLoadingProjectList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectData, setNewProjectData] = React.useState(true);
	const [searchProject, setSearchProject] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/projects`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectList(response.data.data);
				setLoadingProjectList(false);
				setNewProjectData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingProjectList, newProjectData, searchProject]);
	return { projectList, isLoadingProjectList, error, setNewProjectData, setSearchProject };
};

const useProjectData = (projectId) => {
    const [projectData, setProjectData] = React.useState([]);
	const [isLoadingProjectData, setLoadingProjectData] = React.useState(true);
	const [errorLoadingProjectData, setErrorLoadingProjectData] = React.useState(false);
	
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/projects/get/${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectData(response.data.data);
				setLoadingProjectData(false);
				
			} catch (e) {
				setErrorLoadingProjectData(true);
				setLoadingProjectData(false);
			}
		};
		fetchData();
	}, []);
	return { projectData, isLoadingProjectData, errorLoadingProjectData};
};


const useDepartmentRoleList = (departmentId) => {
    const [departmentRoleList, setDepartmentRoleList] = React.useState([]);
	const [isLoadingDepartmentRoleList, setLoadingDepartmentRoleList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newDepartmentRoleData, setNewDepartmentRoleData] = React.useState(true);
	const [searchDepartmentRole, setSearchDepartmentRole] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/departments/roles?department_id=${departmentId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDepartmentRoleList(response.data.data);
				setLoadingDepartmentRoleList(false);
				setNewDepartmentRoleData(false)
			} catch (e) {
				setError(true);
				setLoadingDepartmentRoleList(false);
			}
		};
		fetchData();
	}, [departmentId, isLoadingDepartmentRoleList, newDepartmentRoleData, searchDepartmentRole]);
	return { departmentRoleList, isLoadingDepartmentRoleList, error, setNewDepartmentRoleData, setSearchDepartmentRole };
};



const useDepartmentEmployeesList = (tenantId) => {
    const [employeeList, setEmployeeList] = React.useState([]);
	const [isLoadingEmployeeList, setLoadingEmployeeList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newEmployeeData, setNewEmployeeData] = React.useState(true);
	const [searchEmployee, setSearchEmployee] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/departments/employees?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setEmployeeList(response.data.data);
				setLoadingEmployeeList(false);
				setNewEmployeeData(false)
			} catch (e) {
				setError(true);
				setLoadingEmployeeList(false);
			}
		};
		fetchData();
	}, [tenantId,isLoadingEmployeeList, newEmployeeData, searchEmployee]);
	return { employeeList, isLoadingEmployeeList, error, setNewEmployeeData, setSearchEmployee };
};


const useActivityList = (pageNumber) => {
    const [activityList, setActivityList] = React.useState([]);
	const [isLoadingActivityList, setLoadingActivityList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newActivityData, setNewActivityData] = React.useState(true);
	const [searchActivity, setSearchActivity] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/activity/list?search=${searchActivity}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setActivityList(response.data.data);
				setLoadingActivityList(false);
				setNewActivityData(false)
			} catch (e) {
				setError(true);
				setLoadingActivityList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingActivityList, newActivityData, searchActivity]);
	return { activityList, isLoadingActivityList, error, setNewActivityData, setSearchActivity };
};


const useTaskList = (pageNumber, activityId) => {
    const [taskList, setTaskList] = React.useState([]);
	const [isLoadingTaskList, setLoadingTaskList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newTaskData, setNewTaskData] = React.useState(true);
	const [searchTask, setSearchTask] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/task/list?search=${searchTask}&activity=${activityId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setTaskList(response.data.data);
				setLoadingTaskList(false);
				setNewTaskData(false)
			} catch (e) {
				setError(true);
				setLoadingTaskList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingTaskList, newTaskData, searchTask]);
	return { taskList, isLoadingTaskList, error, setNewTaskData, setSearchTask };
};


const useClockingLog = (pageNumber) => {
    const [clockingLog, setClockingLog] = React.useState([]);
	const [isLoadingClockingLog, setLoadingClockingLog] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newClockingData, setNewClockingData] = React.useState(true);
	const [searchClockingDate, setSearchClockingDate] = React.useState({"start_date": moment().format('YYYY-MM-DD'), "end_date":moment().format('YYYY-MM-DD')});
	const token =  useSelector((state) => state.token);
	const userId = useSelector((state)=> state.user.id)

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/clock_in_clock_out/list/${userId}?filter_period=True&start_date=${searchClockingDate.start_date}&end_date=${searchClockingDate.end_date}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setClockingLog(response.data.data);
				setLoadingClockingLog(false);
				setNewClockingData(false)
			} catch (e) {
				setError(true);
				setLoadingClockingLog(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingClockingLog, newClockingData, searchClockingDate]);
	return { clockingLog, isLoadingClockingLog, error, setNewClockingData, setSearchClockingDate };
};


const useTimesheetList = (pageNumber) => {
    const [timesheetList, setTimesheetList] = React.useState([]);
	const [isLoadingTimesheetList, setLoadingTimesheetList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newTimesheetListData, setNewTimesheetListData] = React.useState(true);
	const [searchTimesheetListDate, setSearchTimesheetListDate] = React.useState({
																		"start_date": moment().format('YYYY-MM-DD'),
																		"end_date":moment().format('YYYY-MM-DD')
																	});
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setTimesheetList(response.data.data);
				setLoadingTimesheetList(false);
				setNewTimesheetListData(false)
			} catch (e) {
				setError(true);
				setLoadingTimesheetList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingTimesheetList, newTimesheetListData, searchTimesheetListDate]);
	return { timesheetList, isLoadingTimesheetList, error, setNewTimesheetListData, setSearchTimesheetListDate };
};


const useMemoRequestList = (pageNumber, projectId, user = "False") => {
	
    const [memoRequestList, setMemoRequestList] = React.useState([]);
	const [isLoadingMemoRequestList, setLoadingMemoRequestList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newMemoRequestData, setNewMemoRequestData] = React.useState(true);
	const [searchMemoRequest, setSearchMemoRequest] = React.useState(false);
	const [useStatusFilter, setUseStatusFilter] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			if (useStatusFilter) {
				try {
					const response = await odmasApi.get(`projects/requests/memos?project_id=${projectId}&user_filter=${user}&status_filter=${useStatusFilter}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setMemoRequestList(response.data.data);
					setLoadingMemoRequestList(false);
					setNewMemoRequestData(false)
				} catch (e) {
					setError(true);
					setLoadingMemoRequestList(false);
				}
			} else {
				try {
					const response = await odmasApi.get(`projects/requests/memos?project_id=${projectId}&user_filter=${user}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setMemoRequestList(response.data.data);
					setLoadingMemoRequestList(false);
					setNewMemoRequestData(false)
				} catch (e) {
					setError(true);
					setLoadingMemoRequestList(false);
				}
			}
		};
		fetchData();
	}, [pageNumber,isLoadingMemoRequestList, newMemoRequestData, searchMemoRequest, useStatusFilter]);
	return { memoRequestList, isLoadingMemoRequestList, error, setNewMemoRequestData, setSearchMemoRequest, setUseStatusFilter };
};


const useRequisitionRequestList = (pageNumber) => {
    const [requisitionRequestList, setRequisitionRequestList] = React.useState([
		{
			"id" : 1,
			"date_added": "2023-12-07",
			"purpose": "SDA for CDC Connectivity Hardware Technical Meeting 24th- 25th Feb 2022 in Entebbe",
			"document_domain": {"document_domain_name":"PAYMENT"},
			"related_project": {"project_name":"FLASH"},
			"approval_process": "VERIFICATION",
			"status": "PENDING",
		}

	]);
	const [isLoadingRequisitionRequestList, setLoadingRequisitionRequestList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newRequisitionRequestData, setNewRequisitionRequestData] = React.useState(true);
	const [searchRequisitionRequest, setSearchRequisitionRequest] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			// try {
			// 	const response = await odmasApi.get(`/task/list?search=${searchRequisitionRequest}`, {
			// 		headers: {
			// 			Authorization: `Bearer ${token}`
			// 		}
			// 	});
			// 	setRequisitionRequestList(response.data.data);
			// 	setLoadingRequisitionRequestList(false);
			// 	setNewRequisitionRequestData(false)
			// } catch (e) {
			// 	setError(true);
			// 	setLoadingRequisitionRequestList(false);
			// }
		};
		fetchData();
	}, [pageNumber,isLoadingRequisitionRequestList, newRequisitionRequestData, searchRequisitionRequest]);
	return { requisitionRequestList, isLoadingRequisitionRequestList, error, setNewRequisitionRequestData, setSearchRequisitionRequest };
};


const useTravelRequisitionList = (pageNumber, projectId,user) => {
    const [travelRequisitionList, setTravelRequisitionList] = React.useState([]);
	const [isLoadingTravelRequisitionList, setLoadingTravelRequisitionList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newTravelRequisitionData, setNewTravelRequisitionData] = React.useState(true);
	const [searchTravelRequisition, setSearchTravelRequisition] = React.useState(false);
	const [useStatusFilter, setUseStatusFilter] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			if (useStatusFilter) {
				try {
					const response = await odmasApi.get(`/projects/requests/travel_requisition?project_id=${projectId}&user_filter=${user}&status_filter=${useStatusFilter}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setTravelRequisitionList(response.data.data);
					setLoadingTravelRequisitionList(false);
					setNewTravelRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingTravelRequisitionList(false);
				}
			} else {
				try {
					const response = await odmasApi.get(`/projects/requests/travel_requisition?project_id=${projectId}&user_filter=${user}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setTravelRequisitionList(response.data.data);
					setLoadingTravelRequisitionList(false);
					setNewTravelRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingTravelRequisitionList(false);
				}
			}
		};
		fetchData();
	}, [pageNumber,isLoadingTravelRequisitionList, newTravelRequisitionData, searchTravelRequisition, useStatusFilter]);
	return { travelRequisitionList, isLoadingTravelRequisitionList, error, setNewTravelRequisitionData, setSearchTravelRequisition, setUseStatusFilter };
};


const useProcurementRequisitionList = (pageNumber, projectId, user) => {
    const [procurementRequisitionList, setProcurementRequisitionList] = React.useState([]);
	const [isLoadingProcurementRequisitionList, setLoadingProcurementRequisitionList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProcurementRequisitionData, setNewProcurementRequisitionData] = React.useState(true);
	const [searchProcurementRequisition, setSearchProcurementRequisition] = React.useState(false);
	const [useStatusFilter, setUseStatusFilter] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			if(useStatusFilter) {
				try {
					const response = await odmasApi.get(`/projects/requests/procurement_requisition?project_id=${projectId}&user_filter=${user}&status_filter=${useStatusFilter}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setProcurementRequisitionList(response.data.data);
					setLoadingProcurementRequisitionList(false);
					setNewProcurementRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingProcurementRequisitionList(false);
				}
			} else {
				try {
					const response = await odmasApi.get(`/projects/requests/procurement_requisition?project_id=${projectId}&user_filter=${user}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setProcurementRequisitionList(response.data.data);
					setLoadingProcurementRequisitionList(false);
					setNewProcurementRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingProcurementRequisitionList(false);
				}
			}
		};
		fetchData();
	}, [pageNumber,isLoadingProcurementRequisitionList, newProcurementRequisitionData, searchProcurementRequisition, useStatusFilter]);
	return { procurementRequisitionList, isLoadingProcurementRequisitionList, error, setNewProcurementRequisitionData, setSearchProcurementRequisition, setUseStatusFilter };
};


const usePaymentRequisitionList = (pageNumber, projectId,user) => {
    const [paymentRequisitionList, setPaymentRequisitionList] = React.useState([]);
	const [isLoadingPaymentRequisitionList, setLoadingPaymentRequisitionList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newPaymentRequisitionData, setNewPaymentRequisitionData] = React.useState(true);
	const [searchPaymentRequisition, setSearchPaymentRequisition] = React.useState(false);
	const [useStatusFilter, setUseStatusFilter] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			if (useStatusFilter) {
				try {
					const response = await odmasApi.get(`/projects/requests/payment_requisition?project_id=${projectId}&user_filter=${user}&status_filter=${useStatusFilter}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setPaymentRequisitionList(response.data.data);
					setLoadingPaymentRequisitionList(false);
					setNewPaymentRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingPaymentRequisitionList(false);
				}
			} else {
				try {
					const response = await odmasApi.get(`/projects/requests/payment_requisition?project_id=${projectId}&user_filter=${user}`, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});
					setPaymentRequisitionList(response.data.data);
					setLoadingPaymentRequisitionList(false);
					setNewPaymentRequisitionData(false)
				} catch (e) {
					setError(true);
					setLoadingPaymentRequisitionList(false);
				}
			}
		};
		fetchData();
	}, [pageNumber,isLoadingPaymentRequisitionList, newPaymentRequisitionData, searchPaymentRequisition,useStatusFilter]);
	return { paymentRequisitionList, isLoadingPaymentRequisitionList, error, setNewPaymentRequisitionData, setSearchPaymentRequisition, setUseStatusFilter };
};


const useUploadedDocumentList = (pageNumber, category,id) => {
    const [uploadedDocumentList, setUploadedDocumentList] = React.useState([]);
	const [isLoadingUploadedDocumentList, setLoadingUploadedDocumentList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newUploadedDocumentData, setNewUploadedDocumentData] = React.useState(true);
	const [searchUploadedDocument, setSearchUploadedDocument] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/upload_document/list?id=${id}&search=${searchUploadedDocument}&category=${category}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setUploadedDocumentList(response.data.data);
				setLoadingUploadedDocumentList(false);
				setNewUploadedDocumentData(false)
			} catch (e) {
				setError(true);
				setLoadingUploadedDocumentList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingUploadedDocumentList, newUploadedDocumentData, searchUploadedDocument]);
	return { uploadedDocumentList, isLoadingUploadedDocumentList, error, setNewUploadedDocumentData, setSearchUploadedDocument };
};


const useParticipantAttachedProjectsList = (pageNumber) => {
    const [participantAttachedProjectsList, setParticipantAttachedProjectsList] = React.useState([]);
	const [isLoadingParticipantAttachedProjectsList, setLoadingParticipantAttachedProjectsList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newPartciantAttachedrojectsData, setNewPartciantAttachedrojectsData] = React.useState(true);
	const [searchPartciantAttachedrojects, setSearchPartciantAttachedrojects] = React.useState(false);
	const token =  useSelector((state) => state.token);
	const userId = useSelector((state)=> state.employeeData.id);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/participants/attached_projects?employee_id=${userId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setParticipantAttachedProjectsList(response.data.data);
				setLoadingParticipantAttachedProjectsList(false);
				setNewPartciantAttachedrojectsData(false)
			} catch (e) {
				setError(true);
				setLoadingParticipantAttachedProjectsList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingParticipantAttachedProjectsList, newPartciantAttachedrojectsData, searchPartciantAttachedrojects]);
	return { participantAttachedProjectsList, isLoadingParticipantAttachedProjectsList, error, setNewPartciantAttachedrojectsData, setSearchPartciantAttachedrojects };
};


const useProjectParticipantList = (pageNumber, projectId) => {
    const [projectParticipantList, setProjectParticipantList] = React.useState([]);
	const [isLoadingProjectParticipantList, setLoadingProjectParticipantList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectParticipantData, setNewProjectParticipantData] = React.useState(true);
	const [searchProjectParticipant, setSearchProjectParticipant] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/participants?project=${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectParticipantList(response.data.data);
				setLoadingProjectParticipantList(false);
				setNewProjectParticipantData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectParticipantList(false);
			}
		};
		fetchData();
	}, [pageNumber,isLoadingProjectParticipantList, newProjectParticipantData, searchProjectParticipant]);
	return { projectParticipantList, isLoadingProjectParticipantList, error, setNewProjectParticipantData, setSearchProjectParticipant };
};


const useProjectDocumentRequestList = (projectId) => {
    const [projectDocumentRequestList, setProjectDocumentRequestList] = React.useState([]);
	const [isLoadingProjectDocumentRequestList, setLoadingProjectDocumentRequestList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectDocumentRequestData, setNewProjectDocumentRequestData] = React.useState(true);
	const [searchProjectDocumentRequest, setSearchProjectDocumentRequest] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/requests/documents?project_id=${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectDocumentRequestList(response.data.data);
				setLoadingProjectDocumentRequestList(false);
				setNewProjectDocumentRequestData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectDocumentRequestList(false);
			}
		};
		fetchData();
	}, [projectId]);
	return { projectDocumentRequestList, isLoadingProjectDocumentRequestList, error, setNewProjectDocumentRequestData, setSearchProjectDocumentRequest };
};


const useProjectUserDocumentApprovalList = (pageNumber) => {
    const [projectUserDocumentApprovalList, setProjectUserDocumentApprovalList] = React.useState([]);
	const [isLoadingProjectUserDocumentApprovalList, setLoadingProjectUserDocumentApprovalList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectUserDocumentApprovalData, setNewProjectUserDocumentApprovalData] = React.useState(true);
	const [searchProjectUserDocumentApproval, setSearchProjectUserDocumentApproval] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/requests/approvals`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectUserDocumentApprovalList(response.data.data);
				setLoadingProjectUserDocumentApprovalList(false);
				setNewProjectUserDocumentApprovalData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectUserDocumentApprovalList(false);
			}
		};
		fetchData();
	}, [pageNumber, isLoadingProjectUserDocumentApprovalList, newProjectUserDocumentApprovalData, searchProjectUserDocumentApproval]);
	return { projectUserDocumentApprovalList, isLoadingProjectUserDocumentApprovalList, error, setNewProjectUserDocumentApprovalData, setSearchProjectUserDocumentApproval };
};


const useProjectRoleList = (projectId) => {
    const [projectRoleList, setProjectRoleList] = React.useState([]);
	const [isLoadingProjectRoleList, setLoadingProjectRoleList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectRoleData, setNewProjectRoleData] = React.useState(true);
	const [searchProjectRole, setSearchProjectRole] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/roles?project_id=${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectRoleList(response.data.data);
				setLoadingProjectRoleList(false);
				setNewProjectRoleData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectRoleList(false);
			}
		};
		fetchData();
	}, [projectId, isLoadingProjectRoleList, newProjectRoleData, searchProjectRole]);
	return { projectRoleList, isLoadingProjectRoleList, error, setNewProjectRoleData, setSearchProjectRole };
};


const useDepartmentStationList = (tenantId) => {
    const [departmentStationList, setDepartmentStationList] = React.useState([]);
	const [isLoadingDepartmentStationList, setLoadingDepartmentStationList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newDepartmentStationData, setNewDepartmentStationData] = React.useState(true);
	const [searchDepartmentStation, setSearchDepartmentStation] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/project_stations?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDepartmentStationList(response.data.data);
				setLoadingDepartmentStationList(false);
				setNewDepartmentStationData(false)
			} catch (e) {
				setError(true);
				setLoadingDepartmentStationList(false);
			}
		};
		fetchData();
	}, [tenantId, isLoadingDepartmentStationList, newDepartmentStationData, searchDepartmentStation]);
	return { departmentStationList, isLoadingDepartmentStationList, error, setNewDepartmentStationData, setSearchDepartmentStation };
};


const useProjectCostCenterList = (projectId) => {
    const [projectCostCenterList, setProjectCostCenterList] = React.useState([]);
	const [isLoadingProjectCostCenterList, setLoadingProjectCostCenterList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newProjectCostCenterData, setNewProjectCostCenterData] = React.useState(true);
	const [searchProjectCostCenter, setSearchProjectCostCenter] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/cost_centers?project_id=${projectId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjectCostCenterList(response.data.data);
				setLoadingProjectCostCenterList(false);
				setNewProjectCostCenterData(false)
			} catch (e) {
				setError(true);
				setLoadingProjectCostCenterList(false);
			}
		};
		fetchData();
	}, [projectId, isLoadingProjectCostCenterList, newProjectCostCenterData, searchProjectCostCenter]);
	return { projectCostCenterList, isLoadingProjectCostCenterList, error, setNewProjectCostCenterData, setSearchProjectCostCenter };
};

const useDocumentDomainList = (tenantId) => {
    const [documentDomainList, setDocumentDomainList] = React.useState([]);
	const [isLoadingDocumentDomainList, setLoadingDocumentDomainList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newDocumentDomainData, setNewDocumentDomainData] = React.useState(true);
	const [searchDocumentDomain, setSearchDocumentDomain] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/project_document_domains?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDocumentDomainList(response.data.data);
				setLoadingDocumentDomainList(false);
				setNewDocumentDomainData(false)
			} catch (e) {
				setError(true);
				setLoadingDocumentDomainList(false);
			}
		};
		fetchData();
	}, [tenantId, isLoadingDocumentDomainList, newDocumentDomainData, searchDocumentDomain]);
	return { documentDomainList, isLoadingDocumentDomainList, error, setNewDocumentDomainData, setSearchDocumentDomain };
};


const useDocumentTypeList = (tenantId) => {
    const [documentTypeList, setDocumentTypeList] = React.useState([]);
	const [isLoadingDocumentTypeList, setLoadingDocumentTypeList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/project_document_types?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDocumentTypeList(response.data.data);
				setLoadingDocumentTypeList(false);
			} catch (e) {
				setError(true);
				setLoadingDocumentTypeList(false);
			}
		};
		fetchData();
	}, [tenantId, isLoadingDocumentTypeList ]);
	return { documentTypeList, isLoadingDocumentTypeList, error, };
};


const useDocumentApprovalSteps = (tenantId) => {
    const [documentApprovalSteps, setDocumentApprovalSteps] = React.useState([]);
	const [isLoadingDocumentApprovalSteps, setLoadingDocumentApprovalSteps] = React.useState(true);
	const [error, setError] = React.useState(false);
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/tenants/project_document_approval_steps?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDocumentApprovalSteps(response.data.data);
				setLoadingDocumentApprovalSteps(false);
			} catch (e) {
				setError(true);
				setLoadingDocumentApprovalSteps(false);
			}
		};
		fetchData();
	}, [tenantId, isLoadingDocumentApprovalSteps ]);
	return { documentApprovalSteps, isLoadingDocumentApprovalSteps, error, };
};


const useTimeSheetList = (userId) => {
    const [timeSheetList, setTimeSheetList] = React.useState([]);
	const [isLoadingTimeSheetList, setLoadingTimeSheetList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newTimeSheetData, setNewTimeSheetData] = React.useState(true);
	const [searchTimeSheetMonthYear, setSearchTimeSheetMonthYear] = React.useState({year:moment().year(), month:moment().format('MMMM')});
	const token =  useSelector((state) => state.token);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/timesheet/list/${userId}?month=${searchTimeSheetMonthYear.month}&year=${searchTimeSheetMonthYear.year}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setTimeSheetList(response.data.data);
				setLoadingTimeSheetList(false);
				setNewTimeSheetData(false);
			} catch (e) {
				setError(true);
				setLoadingTimeSheetList(false);
			}
		};
		fetchData();
	}, [isLoadingTimeSheetList, newTimeSheetData, searchTimeSheetMonthYear]);
	return { timeSheetList, isLoadingTimeSheetList, error, setNewTimeSheetData, setSearchTimeSheetMonthYear };
};


const useCurrencyList = (tenantId) => {
    const [currencyList, setCurrencyList] = React.useState([]);
	const [isLoadingCurrencyList, setLoadingCurrencyList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newCurrencyData, setNewCurrencyData] = React.useState(true);
	const [searchCurrency, setSearchCurrency] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/tenants/currency_settings?tenant_id=${tenantId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setCurrencyList(response.data.data);
				setLoadingCurrencyList(false);
				setNewCurrencyData(false)
			} catch (e) {
				setError(true);
				setLoadingCurrencyList(false);
			}
		};
		fetchData();
	}, [isLoadingCurrencyList, newCurrencyData, searchCurrency]);
	return { currencyList, isLoadingCurrencyList, error, setNewCurrencyData, setSearchCurrency };
};

const useLeaveRequestList = (user) => {
    const [leaveRequestList, setLeaveRequestList] = React.useState([]);
	const [isLoadingLeaveRequestList, setLoadingLeaveRequestList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newLeaveRequestData, setNewLeaveRequestData] = React.useState(true);
	const [searchLeaveRequest, setSearchLeaveRequest] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/requests/leave_request?user_filter=${user}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setLeaveRequestList(response.data.data);
				setLoadingLeaveRequestList(false);
				setNewLeaveRequestData(false)
			} catch (e) {
				setError(true);
				setLoadingLeaveRequestList(false);
			}
		};
		fetchData();
	}, [isLoadingLeaveRequestList, newLeaveRequestData, searchLeaveRequest]);
	return { leaveRequestList, isLoadingLeaveRequestList, error, setNewLeaveRequestData, setSearchLeaveRequest };
};


const useTimeSheetActivitiesList = (user) => {
    const [timeSheetActivitiesList, setTimeSheetActivitiesList] = React.useState([]);
	const [isLoadingTimeSheetActivitiesList, setLoadingTimeSheetActivitiesList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newTimeSheetActivitiesData, setNewTimeSheetActivitiesData] = React.useState(true);
	const [searchTimeSheetActivities, setSearchTimeSheetActivities] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/timesheet/activities`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setTimeSheetActivitiesList(response.data.data);
				setLoadingTimeSheetActivitiesList(false);
				setNewTimeSheetActivitiesData(false)
			} catch (e) {
				setError(true);
				setLoadingTimeSheetActivitiesList(false);
			}
		};
		fetchData();
	}, [isLoadingTimeSheetActivitiesList, newTimeSheetActivitiesData, searchTimeSheetActivities]);
	return { timeSheetActivitiesList, isLoadingTimeSheetActivitiesList, error, setNewTimeSheetActivitiesData, setSearchTimeSheetActivities };
};


const useDepartmentHolidayList = (departmentId) => {
    const [departmentHolidayList, setDepartmentHolidayList] = React.useState([]);
	const [isLoadingDepartmentHolidayList, setLoadingDepartmentHolidayList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newDepartmentHolidayData, setNewDepartmentHolidayData] = React.useState(true);
	const [searchDepartmentHoliday, setSearchDepartmentHoliday] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/tenants/public_holidays?tenant_id=${departmentId}`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setDepartmentHolidayList(response.data.data);
				setLoadingDepartmentHolidayList(false);
				setNewDepartmentHolidayData(false)
			} catch (e) {
				setError(true);
				setLoadingDepartmentHolidayList(false);
			}
		};
		fetchData();
	}, [isLoadingDepartmentHolidayList, newDepartmentHolidayData, searchDepartmentHoliday]);
	return { departmentHolidayList, isLoadingDepartmentHolidayList, error, setNewDepartmentHolidayData, setSearchDepartmentHoliday };
};



const useNotiificationList = (user) => {
    const [notiificationList, setNotiificationList] = React.useState([]);
	const [isLoadingNotiificationList, setLoadingNotiificationList] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newNotiificationData, setNewNotiificationData] = React.useState(true);
	const [searchNotiification, setSearchNotiification] = React.useState(false);
	const token =  useSelector((state) => state.token);
	

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				
				const response = await odmasApi.get(`/notifications`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setNotiificationList(response.data.data);
				setLoadingNotiificationList(false);
				setNewNotiificationData(false)
			} catch (e) {
				setError(true);
				setLoadingNotiificationList(false);
			}
		};
		fetchData();
	}, [isLoadingNotiificationList, newNotiificationData, searchNotiification]);
	return { notiificationList, isLoadingNotiificationList, error, setNewNotiificationData, setSearchNotiification };
};

















// ######################################### SINGLE RECORD CALLS ####################################################


const useGetSingleRequestDetails = (id) => {
    const [singleRequest, setSingleRequest] = React.useState("");
	const [isLoadingSingleRequest, setLoadingSingleRequest] = React.useState(true);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await odmasApi.get(`/projects/Singlerequests/details/${id}`);
				setSingleRequest(response.data);
				setLoadingSingleRequest(false);
			} catch (e) {
				setError(true);
				setLoadingSingleRequest(false);
			}
		};
		fetchData();
	}, [id,isLoadingSingleRequest]);
	return { singleRequest, isLoadingSingleRequest, error };
};










 
export {
	useProjectList, useDepartmentEmployeesList, useActivityList, useTaskList, useClockingLog, useMemoRequestList, useRequisitionRequestList,
	useUploadedDocumentList, useParticipantAttachedProjectsList, useTimeSheetList, useTimesheetList, useProjectParticipantList, useProjectDocumentRequestList,
	useProjectUserDocumentApprovalList, useProjectData, useProjectRoleList, useDepartmentStationList, usePaymentRequisitionList,useDepartmentRoleList,
	useProcurementRequisitionList,useTravelRequisitionList,useProjectCostCenterList, useCurrencyList, useLeaveRequestList, useTimeSheetActivitiesList,useDepartmentHolidayList,
	useDocumentDomainList,useDocumentTypeList,useDocumentApprovalSteps,useNotiificationList,

	useGetSingleRequestDetails

}