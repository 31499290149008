import { Box, useMediaQuery, Tab, Tabs, Typography, Alert } from "@mui/material";
import { useSelector, } from "react-redux";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import UserSetupTable from "../tables/userSetupTable";
import ProjectSetupTable from "../../projectSetup/tables/projectSetupTable";
import SystemRoleSetupTable from "../tables/systemRoleSetupTable";
import LeaveSetupTable from "../tables/leaveSetupTable";
import CurrencySetupTable from "../tables/currencySetupTable";
import HolidaySetupTable from "../tables/holidaySetupTable";
import DepartmentStationSetupTable from "../tables/departmentStationSetup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SetupTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);
  // const { _id, picturePath } = useSelector((state) => state.user);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999
            }}
          > 
              {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
                ) : null                                  
              }
              
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs fullWidth value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label="Project Setup" {...a11yProps(0)} />
                  <Tab label="User Setup" {...a11yProps(1)} />
                  <Tab label="Department Role Setup" {...a11yProps(2)} />
                  <Tab label="Department Station Setup" {...a11yProps(3)} />
                  <Tab label="Currency Setup" {...a11yProps(4)} />
                  <Tab label="Leave Setup" {...a11yProps(5)} />
                  <Tab label="Holiday Setup" {...a11yProps(6)} />
                  
                  
                </Tabs>
              </Box>
              <Box>
                
                <TabPanel value={value} index={0}>
                  <ProjectSetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <UserSetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <SystemRoleSetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <DepartmentStationSetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <CurrencySetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <LeaveSetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <HolidaySetupTable setIsSuccess={setIsSuccess}/>
                </TabPanel>
                
              </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default SetupTab;