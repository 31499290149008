import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDepartmentEmployeesList} from "FetchData";
import { useState } from "react";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';



export default function LeaveSetupTable ({setIsSuccess}) {
  const theme = useTheme();
  const tenantId = useSelector((state)=> state.employeeData.related_tenant.id);
  const {employeeList, isLoadingEmployeeList, setNewEmployeeData, setSearchEmployee} = useDepartmentEmployeesList(tenantId)
  
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState('')
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(``);
    setEditData(response.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(``);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await axios.post(`http://localhost:8000/inventory/products/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewEmployeeData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchEmployee(searchContent)
  };


  return (
    <Box>
        

    </Box>
  );
};