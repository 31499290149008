
import {Box,Button,TextField,useMediaQuery,useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState } from "react";
const API_URL = process.env.REACT_APP_API_URL;



const ChangeUserPasswordForm = (props) => {
    const { palette } = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
  
    const token =  useSelector((state) => state.token);
  
    const initialValuesItem = {
      new_password: "",
      confirm_password: "",
      same_entry: ""
    };

    const itemSchema = yup.object().shape({
      new_password: yup
        .string()
        .required("required")
        .min(8, "Your password must be at least 8 characters long"),
        // .matches(
        //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        // ),
      confirm_password: yup
        .string()
        .oneOf([yup.ref('new_password'), null], "Passwords do not match.")
        .required("required"),
      
    });
    
    
  
    const saveItem = async (values, onSubmitProps) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const savedItemResponse = await axios.post(`${API_URL}/tenants/departments/employees/individual?employee_id=${props.userId}&method=edit&attribute=password`, JSON.stringify(values),{
        headers: {
            'Content-Type': 'application/json'
        }
        });
    
        if (savedItemResponse.status===200) {
            props.setOpen(false);
            onSubmitProps.resetForm();
            props.setIsSuccess(true);
        }
    };
  
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      saveItem(values, onSubmitProps);
    };
  
    return (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesItem }
        validationSchema={itemSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
  
              <TextField
                label="New Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.new_password}
                name="new_password"
                error={Boolean(touched.new_password) && Boolean(errors.new_password)}
                helperText={touched.new_password && errors.new_password}
                sx={{ gridColumn: "span 2" }}
              />
  
  
              <TextField
                label="Confirm Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirm_password}
                name="confirm_password"
                error={Boolean(touched.confirm_password) && Boolean(errors.confirm_password)}
                helperText={touched.confirm_password && errors.confirm_password}
                sx={{ gridColumn: "span 2" }}
              />
             
        </Box>
  
        {/* BUTTONS */}
        <Box>
          <Button
            fullWidth
            type="submit"
            sx={{
              m: "1rem 0",
              p: "1rem",
              backgroundColor: palette.primary.main,
              color: palette.background.alt,
              "&:hover": { color: palette.primary.main },
            }}
          >
            Save
          </Button>
        </Box>
          </form>
        )}
      </Formik>
    );
  };
  
  export default ChangeUserPasswordForm;
  