
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    MenuItem
  } from "@mui/material";
import { Formik,  } from "formik";
import * as yup from "yup";
import * as React from "react";
import { useSelector } from "react-redux";
import { useProjectParticipantList, useDocumentApprovalSteps } from "FetchData";


  
const itemUnitSchema = yup.object().shape({
related_approval_step: yup.string().required("required"),
related_approver: yup.string(),
});


const RequestDocumentApprovalDetailForm = (props) => {

    const {index, 
        data,
        projectParticipantList,
        documentApprovalSteps,
        handleRemoveItemRow, 
        setItemDetailFormSubmit, 
        handleApprovalStepChange,
        handleApproverChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    const {} = useProjectParticipantList()
    
   

    const initialValuesPurchase = {
        related_approval_step: data.related_approval_step,
        related_approver: data.related_approver,
        };
    

    const handleFormSubmit = async (values, onSubmitProps) => {
        setItemDetailFormSubmit(values);
       
    };

    return (
            
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPurchase}
            validationSchema={itemUnitSchema}
            
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width:"100%"
                    }}
                >
                    <Box >
                        <p >#{index+1}</p>
                    </Box>
                    <Box
                        display="grid"
                        gap="30px"
                        p="0.5rem"
                        gridTemplateColumns="repeat(8, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                            width:"100%"
                        }}
                    >
                        <>
                            <TextField
                                label="Approval Step"
                                size="small"
                                onBlur={handleBlur}
                                select
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleApprovalStepChange(e,index);
                                }}
                                value={values.related_approval_step}
                                name="related_approval_step"
                                error={Boolean(touched.related_approval_step) && Boolean(errors.related_approval_step)}
                                helperText={touched.related_approval_step && errors.related_approval_step}
                                sx={{ gridColumn: "span 4" }}
                            >
                                {documentApprovalSteps.map((row, index)=>{
                                  return(
                                    <MenuItem value={row.id}>{row.approval_step_name}</MenuItem>
                                  )
                                })}
                              </TextField>

                            <TextField
                                label="Approver"
                                size="small"
                                onBlur={handleBlur}
                                select
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleApproverChange(e,index);
                                }}
                                value={values.related_approver}
                                name="related_approver"
                                error={Boolean(touched.related_approver) && Boolean(errors.related_approver)}
                                helperText={touched.related_approver && errors.related_approver}
                                sx={{ gridColumn: "span 3" }}
                            >
                                {projectParticipantList.map((row, index)=>{
                                  return(
                                    <MenuItem value={row.id}>{row.related_participant.related_user.first_name} {row.related_participant.related_user.last_name} -  {row.related_project_role.role_name}</MenuItem>
                                  )
                                })}
                              </TextField>
                                    
                            
                            <Button
                                value={index}
                                onClick={() => {
                                    handleRemoveItemRow(data.data_id);
                                }}                                                     
                                size="small"
                                variant="contained"
                                sx={{ 
                                    width: "20%",
                                    marginBottom:"20px",
                                  }}
                                >
                                Remove
                            </Button>
                        </>
                    {/* )} */}
                    </Box>
                </Box>

                
            </form>
            )}
        </Formik>
                                
                            
           );
  };
  
  export default RequestDocumentApprovalDetailForm;
  