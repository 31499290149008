import * as React from 'react';
import { Box, Button, useTheme } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import { useState } from "react";
import BasicCard from 'components/Cards/Card';
import MemoCreateForm from 'scenes/memos/forms/memoCreateForm';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useProjectDocumentRequestList } from 'FetchData';


export default function ProjectDashboard({setIsSuccess, projectId}) {
    const [isSidebar, setIsSidebar] = React.useState(true);
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [relatedProjectDocument, setRelatedProjectDocument] = useState("")
    const {projectDocumentRequestList, isLoadingProjectDocumentRequestList, error} = useProjectDocumentRequestList(projectId);
    
    const handleClickOpen = (e) => {
        setRelatedProjectDocument(e.target.value);
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

    return (
        <Box>
        
            
                    <h2>Dashboard</h2>
                    <Box 
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <BasicCard name={"USERS ATTACHED"} value={"2"}/>
                        <BasicCard name={"MEMOS "} value={"3"}/>
                        <BasicCard name={"REQUISITIONS "} value={"7"}/>
                        
                        {/* <BasicCard name={"EXPIRED"} value={"200"}/> */}
                    </Box>
                    <h2>Memos and Requisitions</h2>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between"
                        }}
                    >
                        <Box 
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                my:"10px",
                                justifyContent: "space-between"
                            }}
                        >
                            <TableContainer sx={{ maxHeight: "100vh" }}>
                                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                        // style={{  minWidth: 50}}
                                        >
                                        No.
                                        </TableCell>
                                        <TableCell
                                        // style={{  minWidth: 170 }}
                                        >
                                        Document Name
                                        </TableCell>
                                        <TableCell
                                        style={{ textAlign: "center" }}
                                        >
                                        Action
                                        </TableCell>
                                        
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectDocumentRequestList.length===0 ? (
                                    
                                        <TableRow>
                                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                            <h3>No Data found</h3>
                                        </TableCell>
                                        </TableRow>
                                    
                                    ): (projectDocumentRequestList
                                    .map((row, index) => {
                                        return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            
                                            <TableCell key={row.id}>
                                                {row.id}
                                            </TableCell>
                                            <TableCell key={row.id}>
                                                {row.document_name}
                                            </TableCell>
                                            
                                            
                                            <TableCell style={{ textAlign: "center" }}>
                                            <Button 
                                                variant="contained" 
                                                value={row.id}
                                                onClick={ 
                                                    row.document_type.document_type_name==="MEMO" ? (e)=> handleClickOpen(e) 
                                                    :
                                                    row.document_domain.document_domain_name==="PAYMENT"   ? (
                                                       ()=> {navigate(`/paymentRequisitionCreateForm/${projectId}/${row.id}`)}
                                                    ) 
                                                    :
                                                    row.document_domain.document_domain_name==="PROCUREMENT"  ? (
                                                        ()=> {navigate(`/procurementRequisitionCreateForm/${projectId}/${row.id}`)}
                                                     ) 
                                                    :
                                                    row.document_domain.document_domain_name==="TRANSPORT"  ? (
                                                        ()=> {navigate(`/travelRequisitionCreateForm/${projectId}/${row.id}`)}
                                                     ) 
                                                    : null}
                                            >
                                                {row.document_type.document_type_name==="MEMO" ? ("Create Memo")
                                                :
                                                row.document_type.document_type_name==="REQUISITUION" ? ("Create Requisition"):
                                                null}
                                            </Button>
                                            <ResponsiveDialog
                                                dialogButtonName = "Add Memo "
                                                dialogHeading = "Add a New Memo"
                                                open = {open}
                                                handleClickOpen = {handleClickOpen}
                                                handleClose = {handleClose}
                                                negativeResponse = "Cancel"
                                                content = {
                                                            <Box
                                                                p="1rem"
                                                                borderRadius="0.5rem"
                                                                backgroundColor={theme.palette.background.alt}
                                                            >
                                                                <MemoCreateForm 
                                                                setOpen={setOpen}  setIsSuccess={setIsSuccess} projectId={projectId} relatedProjectDocument={relatedProjectDocument}
                                                                />
                                                            </Box>
                                                            }
                                            >
                                                
                                            </ResponsiveDialog>
                                            </TableCell>
                                            
                                        </TableRow>
                                        );
                                    }))}
                                </TableBody>
                                </Table>
                            </TableContainer>

                            {/* <Button variant="contained" onClick={handleClickOpen}>Create Memo</Button>
                            <ResponsiveDialog
                                dialogButtonName = "Add Memo "
                                dialogHeading = "Add a New Memo"
                                open = {open}
                                handleClickOpen = {handleClickOpen}
                                handleClose = {handleClose}
                                negativeResponse = "Cancel"
                                content = {
                                            <Box
                                                p="1rem"
                                                borderRadius="0.5rem"
                                                backgroundColor={theme.palette.background.alt}
                                            >
                                                <MemoCreateForm 
                                                setOpen={setOpen}  setIsSuccess={setIsSuccess}
                                                />
                                            </Box>
                                            }
                            >
                                
                            </ResponsiveDialog> */}
                            
                            

                            
                        </Box>
                    </Box>
                    
               
            
        </Box>
    )
}