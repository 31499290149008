import {Box, useTheme, Grid, Button, Paper, Alert} from "@mui/material";
import ResponsiveDialog from "components/dialog";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import EditUserBioDataForm from "./editUserBioDataForm";
import ChangeUserPasswordForm from "./editUserPasswordForm";
import EditProjectsAssignedForm from "./editUserProjectsAssignedForm";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


const UserViewForm = () => {
  const theme = useTheme();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);
  const [openEditBioData, setOpenEditBioData] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openEditProjectAssignment, setOpenEditProjectAssignment] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const token =  useSelector((state) => state.token);
  
  
  const first_name = location.state.related_user.first_name;
  const last_name = location.state.related_user.last_name;
  const email = location.state.related_user.email;
  const username = location.state.related_user.username;
  const phone_number = location.state.related_user.phone_number;
  const employee_role = location.state.related_role;
  const projects_attached = location.state.projects_attached;
  const department = location.state.related_department

  const  refreshPage = () => { 
    window.location.reload(); 
  }

  const handleOpenEditBioData = () => {
    setOpenEditBioData(true);
  };

  const handleCloseEditBioData = () => {
    setOpenEditBioData(false);
  };

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleOpenEditProjectAssignment = () => {
    setOpenEditProjectAssignment(true);
  };

  const handleCloseEditProjectAssignment = () => {
    setOpenEditProjectAssignment(false);
  };

  const handleOpenDeactivate = () => {
    setOpenDeactivate(true);
  };

  const handleCloseDeactivate = () => {
    setOpenDeactivate(false);
  };

  const handleDeactivateUser = async () =>{
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const savedItemResponse = await axios.post(`${API_URL}/tenants/departments/employees/individual?employee_id=${location.state.id}&method=delete&attribute=user`, JSON.stringify({"username":username}),{
    headers: {
        'Content-Type': 'application/json'
    }
    });

    if (savedItemResponse.status===200) {
      setOpenDeactivate(false);
      setIsSuccess(true);
    }
  };
  

  return (
    <Box>
      <Navbar />
      <Box 
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
        container spacing={2}
      >
        <Box sx={{flexGrow: 1}}>
          <Sidebar isSidebar={isSidebar}/>  
        </Box>
        <Box sx={{flexGrow: 999}}> 
            {
              isSuccess ? ( 
                  <Alert sx={{ m:"15px" }} variant="filled" severity="success">Operation was successful!</Alert>
              ) : isFailed ? (
                  <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
              ) : null                                  
            }
          <Box
            sx={{
              paddingX: "20px",
              marginX: "20px",
              justifyContent: "space-between"
            }}
          >
            
            <Grid container spacing={4} className="alignHorizantal">
              
              <Grid xs sx={{ mx: '8px', my:"15px"}} >
                <Paper 
                  sx={{ width: '100%',
                        padding: "40px",
                        margin: "40px",
                      }}
                  elevation={3}
                >
                  <h2 style={{  margin:"0px", padding:"0px"}}>USER BIO DATA DETAILS</h2 >
                  <p style={{  margin:"0px", padding:"0px"}}><b>NAME: </b> {first_name} {last_name}</p >
                  <p style={{  margin:"0px", padding:"0px"}}><b>USERNAME:</b> {username}</p >
                  <p style={{  margin:"0px", padding:"0px"}}><b>CONTACT:</b> {phone_number}</p >
                  <p style={{  margin:"0px", padding:"0px"}}><b>EMAIL:</b> {email}</p >
                    
                </Paper>
              </Grid> 

              <Grid xs sx={{ mx: '8px', my:"15px"}} >
              <Paper 
                sx={{ width: '100%',
                      padding: "40px",
                      margin: "40px",
                    }}
                elevation={3}
              >
                    
                <p style={{  margin:"0px", padding:"0px"}}><b>EDIT OPTIONS</b></p >
                <br/>
                <Button  size="small"  variant="contained" sx={{ mx: "5px"}} onClick={handleOpenEditBioData}>Edit Bio Data</Button>
                <Button  size="small"  variant="contained" sx={{ mx: "5px"}} onClick={handleOpenChangePassword}  >Change Password</Button>
                <Button  size="small"  variant="contained" sx={{ mx: "5px"}} onClick={handleOpenEditProjectAssignment}  >Edit Project Assignment</Button>
                <Button  size="small"  variant="contained" sx={{ mx: "5px"}} onClick={handleOpenDeactivate}  >Deactivate</Button>
                <ResponsiveDialog
                  dialogButtonName = "Edit User "
                  dialogHeading = "Edit User Bio Data"
                  open = {openEditBioData}
                  handleClickOpen = {handleOpenEditBioData}
                  handleClose = {handleCloseEditBioData}
                  negativeResponse = "Cancel"
                  content = {
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                <EditUserBioDataForm 
                                  setOpen={setOpenEditBioData} refreshData={refreshPage} setIsSuccess={setIsSuccess} data={[location.state.related_user,location.state.related_role]} employeeId={location.state.id}
                                />
                              </Box>
                            }
                />
                <ResponsiveDialog
                  dialogButtonName = "Edit User Password "
                  dialogHeading = "Edit User Password"
                  open = {openChangePassword}
                  handleClickOpen = {handleOpenChangePassword}
                  handleClose = {handleCloseChangePassword}
                  negativeResponse = "Cancel"
                  content = {
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                <ChangeUserPasswordForm 
                                  setOpen={setOpenChangePassword}  setIsSuccess={setIsSuccess} userId={location.state.id}
                                />
                              </Box>
                            }
                />
                <ResponsiveDialog
                  dialogButtonName = "Edit Project Assignement "
                  dialogHeading = "Edit Project Assignement"
                  open = {openEditProjectAssignment}
                  handleClickOpen = {handleOpenEditProjectAssignment}
                  handleClose = {handleCloseEditProjectAssignment}
                  negativeResponse = "Cancel"
                  content = {
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                <EditProjectsAssignedForm 
                                  setOpen={setOpenEditProjectAssignment} projectsAttached={projects_attached} setIsSuccess={setIsSuccess}
                                />
                              </Box>
                            }
                />
                <ResponsiveDialog
                  dialogButtonName = "Deativate User "
                  dialogHeading = "Deactivate User"
                  open = {openDeactivate}
                  handleClickOpen = {handleOpenDeactivate}
                  handleClose = {handleCloseDeactivate}
                  negativeResponse = "Cancel"
                  content = {
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                <p><b>Are you sure you want to deactivate this user?</b></p>
                                <Button
                                  fullWidth
                                  size="small"
                                  sx={{
                                    m: "10px 0",
                                    p: "10px",
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.background.alt,
                                    "&:hover": { color: theme.palette.primary.main },
                                  }}
                                  onClick={handleDeactivateUser}
                                >
                                  Yes, Deactivate
                                </Button>
                              </Box>
                            }
                />

              </Paper>
              </Grid>
            </Grid>  

            <hr/>

            <Paper 
              sx={{ width: '100%',
                    padding: "40px",
                    margin: "20px",
                  }}
              elevation={3}
            >
              <Grid container spacing={4} className="alignHorizantal"  >
                <Grid xs sx={{ mx: '8px', my:"15px"}} >
                    <h2 style={{  margin:"0px", padding:"0px"}}>DEPARTMENT ROLE</h2 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                            <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{  minWidth: 150 }}
                                >
                                    Department Name
                                </TableCell>
                                <TableCell
                                    style={{  minWidth: 150 }}
                                >
                                    Role Assigned
                                </TableCell>
                                                                      
                            </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                  <TableCell
                                      style={{  minWidth: 150 }}
                                  >
                                      {department.department_name}
                                  </TableCell>
                                  <TableCell
                                      style={{  minWidth: 150 }}
                                  >
                                      {employee_role ? employee_role.role_name : "N/A"}
                                  </TableCell>
                                        
                              </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <hr/>
                <Grid xs sx={{ mx: '8px', my:"15px"}} >

                    <h2 style={{  margin:"0px", padding:"0px"}}>PROJECTS ASSIGNED </h2 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                            <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{  minWidth: 50}}
                                >
                                    No.
                                </TableCell>
                                <TableCell
                                    style={{  minWidth: 150 }}
                                >
                                    Project Name
                                </TableCell>
                                <TableCell
                                    style={{  minWidth: 150 }}
                                >
                                    Role Assigned
                                </TableCell>
                                <TableCell
                                    style={{  minWidth: 150, textAlign: "center" }}
                                >
                                    Level of Effort
                                </TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                              {projects_attached.length === 0 ? (
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              ):(
                                projects_attached.map( (row, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell key={row.id}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.related_project.project_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.related_project_role.role_name}
                                      </TableCell>
                                      <TableCell key={row.id} style={{ textAlign: "center" }}>
                                        {row.level_of_effort}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                              )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                                  

                </Grid>
              </Grid> 
          
            </Paper>               

                
          </Box>                
        </Box>
      </Box>
    </Box>
        
  );

}

export default UserViewForm;