import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";


import LoginPage from "scenes/loginPage";
import UserDelete from "scenes/loginPage/userDelete";
import ProfilePage from "scenes/profilePage";

import VerificationRequests from "scenes/approvals/tables/verificationRequests";
import SetupTab from "scenes/setup/tabs/setupTab";
import LeaveTab from "scenes/leave/tabs/leaveTab";
import ActivityTab from "scenes/activity&Task/tabs/activityTaskTab";
import ClockingTimesheetTab from "scenes/clocking&Timesheet/tabs/clockingTimeSheetTab";
import ReportTab from "scenes/reports/tabs/reportTab";
import ProjectTab from "scenes/project/tabs/projectTab";
import UserLeaveRequests from "scenes/leave/tables/userLeaveRequestTable";
import PaymentRequestCreateForm from "scenes/requisitions/forms/payment/paymentRequestCreateForm";
import LeaveRequestCreateForm from "scenes/leave/forms/leaveRequestCreateForm";
import ProcurementRequestCreateForm from "scenes/requisitions/forms/procurement/procurementRequestCreateForm";
import TravelRequestCreateForm from "scenes/requisitions/forms/travel/travelRequestCreateForm";
import ActiviyTasksTable from "scenes/activity&Task/tables/activity$taskDetailTable";
import TravelMatrixTable from "scenes/travelMatrix/tables/travelMatrixListTable";

import MemoViewForm from "scenes/memos/forms/memoViewForm";
import PaymentRequisitionViewForm from "scenes/requisitions/forms/payment/paymentRequestViewForm";
import ProcurementRequisitionViewForm from "scenes/requisitions/forms/procurement/procurementRequestViewForm";
import TravelRequisitionViewForm from "scenes/requisitions/forms/travel/travelRequestViewForm";
import MemoApprovalViewForm from "scenes/memos/forms/memoApprovalViewForm";
import PaymentRequisitionApprovalViewForm from "scenes/requisitions/forms/payment/paymentRequestApprovalViewForm";
import ProcurementRequisitionApprovalViewForm from "scenes/requisitions/forms/procurement/procurementRequestApprovalViewForm";
import TravelRequisitionApprovalViewForm from "scenes/requisitions/forms/travel/travelRequestApprovalViewForm";
import UserViewForm from "scenes/user/forms/userViewForm";
import TravelMatrixCreateForm from "scenes/travelMatrix/forms/travelMatrixCreateForm";

import MainDashboard from "scenes/dashboards/mainDashboard";
import ProjectSetupTab from "scenes/projectSetup/tabs/projectSetupTab";

function App() {
  const mode = useSelector((state) => state.mode);
  const token =  useSelector((state) => state.token);
  const user = useSelector((state)=> state.user)
  const userType = user!==null ? user.account_type : 99
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = token!==null ? true : false ;
  const isAdmin = userType===0 ? true:false;

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/home"
                // element={isAuth ? <HomePage /> : <Navigate to="/" />}
                element={isAuth ? <MainDashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/dashboard"
                element={isAuth ? <MainDashboard /> : <Navigate to="/" />}
              />
              <Route
                path="/projects/:projectId"
                element={isAuth ? <ProjectTab /> : <Navigate to="/" />}
              />
              <Route
                path="/projectSetup/:projectId"
                element={isAuth ? <ProjectSetupTab /> : <Navigate to="/" />}
              />
              <Route
                path="/approvals"
                element={isAuth ? <VerificationRequests /> : <Navigate to="/" />}
              />
              <Route
                path="/clocking&timesheet"
                element={isAuth ? <ClockingTimesheetTab /> : <Navigate to="/" />}
              />
              <Route
                path="/setup"
                element={isAuth && isAdmin ? <SetupTab /> : <Navigate to="/" />}
              />
              <Route
                path="/leave"
                element={isAuth ? <LeaveTab /> : <Navigate to="/" />}
              />
              <Route
                path="/activities"
                element={isAuth ? <ActivityTab /> : <Navigate to="/" />}
              />
              <Route
                path="/reports"
                element={isAuth ? <ReportTab /> : <Navigate to="/" />}
              />
              <Route
                path="/memoViewForm"
                element={isAuth ? <MemoViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/paymentRequisitionViewForm"
                element={isAuth ? <PaymentRequisitionViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/procurementRequisitionViewForm"
                element={isAuth ? <ProcurementRequisitionViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/travelRequisitionViewForm"
                element={isAuth ? <TravelRequisitionViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/memoApprovalViewForm"
                element={isAuth ? <MemoApprovalViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/paymentRequisitionApprovalViewForm"
                element={isAuth ? <PaymentRequisitionApprovalViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/procurementRequisitionApprovalViewForm"
                element={isAuth ? <ProcurementRequisitionApprovalViewForm /> : <Navigate to="/" />}
              />
               <Route
                path="/travelRequisitionApprovalViewForm"
                element={isAuth ? <TravelRequisitionApprovalViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/paymentRequisitionCreateForm/:projectId/:relatedProjectDocument"
                element={isAuth ? <PaymentRequestCreateForm /> : <Navigate to="/" />}
              />
              
              <Route
                path="/procurementRequisitionCreateForm/:projectId/:relatedProjectDocument"
                element={isAuth ? <ProcurementRequestCreateForm  /> : <Navigate to="/" />}
              />
              <Route
                path="/travelRequisitionCreateForm/:projectId/:relatedProjectDocument"
                element={isAuth ? <TravelRequestCreateForm  /> : <Navigate to="/" />}
              />
              <Route
                path="/leaveReaquestCreateForm"
                element={isAuth ? <LeaveRequestCreateForm /> : <Navigate to="/" />}
              />
              <Route
                path="/myLeaveRequests"
                element={isAuth ? <UserLeaveRequests /> : <Navigate to="/" />}
              />
              <Route
                path="/activity&tasks"
                element={isAuth ? <ActiviyTasksTable /> : <Navigate to="/" />}
              />
              <Route
                path="/travelMartix"
                element={isAuth ? <TravelMatrixTable /> : <Navigate to="/" />}
              />
              <Route
                path="/userViewForm"
                element={isAuth ? <UserViewForm /> : <Navigate to="/" />}
              />
              <Route
                path="/travelMatrixCreateForm"
                element={isAuth ? <TravelMatrixCreateForm /> : <Navigate to="/" />}
              />
              <Route
                path="/profile/:userId"
                element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
              />
              <Route path="/user/delete" element={<UserDelete />} />
            </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
