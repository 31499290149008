import { Box, useTheme, Grid, Button, Paper, InputBase, Alert, TextField } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import ColumnGroupingTable from "components/normalTable";
import { useNavigate } from "react-router-dom";


import ClockInCreateForm from "../forms/clockInCreateForm"; 
import { useClockingLog} from "FetchData";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import NativeSelectDemo from "components/variantSelectDropdown";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ClockOutCreateForm from "../forms/clockOutCreateForm";
import moment from "moment";



const filterOptions = [
  {name:'Supplier'},
]

export default function ClockingLog ({setIsSuccess}) {
  const theme = useTheme();
  const business_branch_id = useSelector((state) => state.business_branch_id);
  const {clockingLog, isLoadingClockingLog, setNewClockingData, setSearchClockingDate} = useClockingLog(1)
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [clockingData, setClockingData] = useState('');
  const [clickedItem, setClickedItem] = useState("");
  const[searchContent, setSearchContent] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed]);

  const token =  useSelector((state) => state.token);

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value)
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickClockingRecordOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`https://api.rectrack.iolabz.ug/api/v1/clock_in_clock_out/get/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setClockingData(response.data.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`http://localhost:8000/inventory/products/get/${e.target.value}`);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await axios.post(`http://localhost:8000/inventory/products/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewClockingData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchClockingDate(searchContent)
  };


  return (
    <Box>
        <h2>Clocking Log</h2>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            {/* <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Clocking Date" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid> */}
            <Grid xs >
              <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-around",
                    // gridColumn: "span 4", 
                    m:"8px"
                }}
              >
                <h4>Filter By Date:</h4>
                <TextField
                  label="Start Date"
                  type="date"
                  onChange={(e)=>{handleChangeStartDate(e)}}
                  value={startDate}
                  name="start_date"
                  size="small"
                  // inputProps={{ 
                  //   min: moment().format('YYYY-MM-DD')
                  //  }}
                  sx={{ mx: "10px"}}
                />
                <TextField
                  label="End Date"
                  type="date"
                  onChange={ (e)=>{handleChangeEndDate(e)}}
                  value={endDate}
                  size="small"
                  name="end_date"
                  sx={{ mx: "10px"}}
                  inputProps={{ 
                    min: startDate
                   }}
                />
                <Button 
                  size="small" 
                  variant="contained" 
                  onClick={() => {
                     setSearchClockingDate({"start_date": startDate,"end_date":endDate}) 
                    }}
                  sx={{ mx: "10px", marginBottom:"23px"}}
                >
                  Filter
                </Button>
              </Box>
            </Grid>

            <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Clock In</Button>
              <ResponsiveDialog
              dialogButtonName = "Clock In "
              dialogHeading = "Clock In"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            {
                              isFailed ? (
                                  <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
                              ) : null
                                                              
                            }
                            <ClockInCreateForm
                              setOpen={setOpen} setNewClockingData={setNewClockingData} setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} setIsFailedError={setIsFailedError}
                            />
                          </Box>
                        }
              >
                
              </ResponsiveDialog>
            </Grid>
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
              <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                  <TableHead>
                    <TableRow>
                    <TableCell
                          style={{  minWidth: 50}}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          style={{  minWidth: 50 }}
                        >
                          Date Clocked
                        </TableCell>
                        <TableCell
                          style={{  minWidth: 50 }}
                        >
                          Clock In
                        </TableCell>
                        <TableCell
                          style={{  minWidth: 150 }}
                        >
                          Clock In Pin
                        </TableCell>
                        <TableCell
                          style={{  minWidth: 50 }}
                        >
                          Clock Out
                        </TableCell>

                        <TableCell
                          style={{  minWidth: 150 }}
                        >
                          Clock Out Pin
                        </TableCell>

                      
                        <TableCell style={{ minWidth: 50 }}>
                          Date Captured
                        </TableCell>

                        <TableCell style={{ textAlign: "center", minWidth: 150  }}>
                          Actions
                        </TableCell>
                        
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clockingLog.length===0 ? (
                      
                        <TableRow>
                          <TableCell colSpan={12} style={{ textAlign: "center" }}>
                            <h3>No Data found</h3>
                          </TableCell>
                        </TableRow>
                      
                    ): (clockingLog
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            
                            <TableCell key={row.id}>
                              {row.id}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.day_recorded}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.clock_in_time}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.clock_in_geo_tag}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.clock_out_time}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.clock_out_geo_tag}
                            </TableCell>
                            <TableCell key={row.id}>
                              {moment(row.date_added).format("YYYY-MM-DD HH:mm:ss")}
                            </TableCell>
                              
                              
                            
                              <TableCell style={{ textAlign: "center" }}>
                                  <Button value={row.id} size="small" onClick={(e) => handleClickClockingRecordOpen(e)}>{row.clock_out_time ? ("VIEW"):("CLOCK OUT")}</Button>

                                  {/* <Button value={row.id} size="small" onClick={(e) => handleClickDeleteOpen(e)} >Delete</Button> */}

                                  {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                                  <ResponsiveDialog
                                    dialogHeading = "Clock Out"
                                    open = {openEdit}
                                    handleClose = {handleClickEditClose}
                                    negativeResponse = "Cancel"
                                    content = {
                                      <Box
                                        p="1rem"
                                        borderRadius="0.5rem"
                                        backgroundColor={theme.palette.background.alt}
                                      >
                                        {<ClockOutCreateForm setOpen={setOpenEdit} setNewClockingData={setNewClockingData} id={clickedItem} clockingData={clockingData} setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} />}
                                      </Box>
                                        
                                        }
                                  />

                                  {/* <ResponsiveDialog
                                    dialogHeading = "Are you sure you want to delete?"
                                    open = {openDelete}
                                    handleClose = {handleClickDeleteClose}
                                    negativeResponse = "Cancel"
                                    content = {
                                      <Box
                                        p="1rem"
                                        borderRadius="0.5rem"
                                        backgroundColor={theme.palette.background.alt}
                                      >
                                        {<Button
                                          fullWidth
                                          type="submit"
                                          variant="contained"
                                          onClick={
                                            handleClickDelete
                                          }
                                          sx={{
                                            m: "1rem 0",
                                            p: "1rem",
                                          }}
                                        >
                                          Yes, Delete
                                        </Button>}
                                      </Box>
                                        
                                        }
                                  /> */}


                                  {/* <ResponsiveDialog
                                      dialogHeading = "Are you sure you want to delete?"
                                      open = {openView}
                                      handleClose = {handleClickViewClose}
                                      negativeResponse = "Cancel"
                                      content = {
                                        <Box
                                          p="1rem"
                                          borderRadius="0.5rem"
                                          backgroundColor={theme.palette.background.alt}
                                        >
                                          {ViewContent}
                                        </Box>
                                          
                                          }
                                    /> */}
                                    
                                
                              </TableCell>
                            
                          </TableRow>
                        );
                      }))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={clockingLog.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </Paper>
      </Box>
    </Box>
  );
};

const columns = [
  { id: 'id', label: 'No.', minWidth: 50 },
  { id: 'task_name', label: 'Task Name', minWidth: 170 },
  { id: 'start_date', label: 'Start Date', minWidth: 200 },
  { id: 'end_date', label: 'End Date', minWidth: 135 },
];
