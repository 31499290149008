
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { odmasApi } from "api/pesabackend";
import {  useState } from "react";
import TimesheetHourDetailForm from "./timeSheetHourDetailForm";
import {v4 as uuidv4} from 'uuid';
import moment from "moment/moment";

const itemSchema = yup.object().shape({
  related_user: yup.number().required("required"),
  related_clock_in: yup.number().required("required"),
  timesheet_records: yup.array().required("required")
});

const TimesheetCreateForm = (props) => {
  let {id, clockIn, clockOut, hoursClocked, dateSelected, clockingId} = props; 
  const userId = useSelector((state) => state.user.id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token =  useSelector((state) => state.token);

  const [hoursRecordedSoFar,setHoursRecordedSoFar] = useState(0);
  const [hoursRemaining,setHoursRemaining] = useState(hoursClocked);
 
  const initialValuesItem = {
    related_user: userId,
    related_clock_in: clockingId,
    timesheet_records:[]
  };

  const [timeSheetRecords, setTimeSheetRecords] = useState([{related_project_or_activity: '', detail: '', fund_and_project: '', time_taken:'', data_id: uuidv4()}]);

  const handleAddTimeSheetSubmitData = () => {
    const oneRow = {
      related_project_or_activity: '', detail: '', fund_and_project: '', time_taken:'', data_id: uuidv4()
    };
    setTimeSheetRecords([...timeSheetRecords, oneRow])
  }; 

 

  const handleHoursRecordedSoFar = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.time_taken)
    }, 0);
    setHoursRecordedSoFar(grandTotalCalculation);
    setHoursRemaining(parseFloat(hoursClocked-grandTotalCalculation).toFixed(2))
  };

  

  const handleRemoveTimeSheetRow = (data_id) => {
    const newFormData = timeSheetRecords.filter((i) => i.data_id !== data_id)
    setTimeSheetRecords(newFormData);
    handleHoursRecordedSoFar(newFormData);   
  };

  const handleTimeTakenChange = (event, index) =>{
    const {value} = event.target;
    const newArray = timeSheetRecords.map((item, idx) => {
        if (idx === index) {
            return {...item, time_taken: value}
        }
        return item
    })
    setTimeSheetRecords(newArray);
    handleHoursRecordedSoFar(newArray);
  };


  const handleDetailChange = (event, index) =>{
    const {value} = event.target;
    const newArray = timeSheetRecords.map((item, idx) => {
        if (idx === index) {
            return {...item, detail: value}
        }
        return item
    })
    setTimeSheetRecords(newArray)
  };

  const handleProjectOrActivtyChange = (event, index, value) =>{
    // const {value} = event.target;
    const newArray = timeSheetRecords.map((item, idx) => {
        if (idx === index) {
            return {...item, related_project_or_activity: value.id}
        }
        return item
    })
    setTimeSheetRecords(newArray)
  };

  const handleFundAndProjectChange = (event, index) =>{
    const {value} = event.target;
    const newArray = timeSheetRecords.map((item, idx) => {
        if (idx === index) {
            return {...item, fund_and_project: value}
        }
        return item
    })
    setTimeSheetRecords(newArray)
  };
  


  const saveItem = async (values, onSubmitProps) => {
    values.added_by = userId
    values.timesheet_records = timeSheetRecords

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/timesheet/add", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem.status===201) {
      props.setOpen(false);
      // props.setNewTimesheetListData(true);
      onSubmitProps.resetForm();
      navigate("/clocking&timesheet");
      props.setIsSuccess(true);
    }
    if (saveItem.status===400) {

    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <TableContainer sx={{ maxHeight: "100vh", gridColumn: "span 4" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                        Date Selected
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Clock In Time
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Clock Out Time
                      </TableCell>         
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow  role="checkbox" tabIndex={-1} >
                    <TableCell style={{ textAlign: "center" }}>
                      {dateSelected}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {clockIn}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {clockOut}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>  
            <TableContainer sx={{ maxHeight: "100vh", gridColumn: "span 4" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <TableRow>
                      <TableCell style={{ textAlign: "center" }}>
                        Total Hours Clocked
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Hours Recorded So Far
                      </TableCell >
                      <TableCell style={{ textAlign: "center" }}> 
                        Hours Remaining
                      </TableCell>         
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow  role="checkbox" tabIndex={-1} >
                    <TableCell style={{ textAlign: "center" }}>
                      {hoursClocked}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {hoursRecordedSoFar}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {hoursRemaining}
                    </TableCell>
                    
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> 
      </Box>
      <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
              marginY="5px"
            >
              <h5>Add Timesheet Records here: </h5> 
              
              {timeSheetRecords.map((row, index) => {
                return (
                  <TimesheetHourDetailForm key={row.data_id}
                    formType="create"
                    index={index}
                    data={row}
                    handleDetailChange={handleDetailChange}
                    handleTimeTakenChange={handleTimeTakenChange}
                    handleFundAndProjectChange={handleFundAndProjectChange}
                    handleProjectOrActivtyChange={handleProjectOrActivtyChange}
                    handleRemoveTimeSheetRow={handleRemoveTimeSheetRow}
                    hoursRemaining={hoursRemaining}
                  />
                )
              })}
                        
              <Button
                  onClick={()=>{
                    handleAddTimeSheetSubmitData();
                  }}
                  sx={{
                    m: "1rem 0",
                  }}
                  size="small"
                  variant="contained"
              >
                  Add Another Record
              </Button>

            </Box>

      {/* BUTTONS */}
      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        

        <Button
          fullWidth
          type="submit"
          size="small"
          sx={{
            // m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
            gridColumn: "span 4" 
          }}
        >
          Save Timesheet Records
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default TimesheetCreateForm;
