import {
  Box,
  TextField,
  Grid,
  Button,
  Paper,
} from "@mui/material";

import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { useTimeSheetList, useTimeSheetActivitiesList} from "FetchData";
import ReactToPrint from "react-to-print";
 


const TimesheetSummaryViewForm = () => {

  const userId = useSelector((state) => state.user.id);
  const {timeSheetList, isLoadingTimeSheetList, setSearchTimeSheetMonthYear} = useTimeSheetList(userId);
  const {timeSheetActivitiesList, isLoadingTimeSheetActivitiesList} = useTimeSheetActivitiesList();

  const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'));
  const [month, setMonth] = useState(moment().format('MMMM'));
  const [year, setYear] = useState(moment().year());

  const handleChangeMonth = (event) => {
    setMonth(moment(event.target.value).format('MMMM'));
    setYear(moment(event.target.value).year());
  };

  const componentRef = useRef();

  // THE HOURS PER PROJECT PER DAY IS CALCULATED HERE
  const filterRecords = (timesheetRecord, timeSheetActivity)=>{
    const checkActivty = (record)=>{
      return record.related_project_or_activity.id === timeSheetActivity.id
    }
    const desiredRecord = timesheetRecord.timesheet.timesheet_records.filter(checkActivty)

    return desiredRecord[0]
  }


  // THE TOTAL HOURS IN A MONTH PER PROJECT IS PRESENTED HERE
  const sumHoursPerProject = (activityRow, timeSheetActivity)=>{
    var totalHours = 0.0

    const checkActivty = (record)=>{
      return record.related_project_or_activity.id === timeSheetActivity.id
    }

    if (activityRow.length===0){
      totalHours+=0
    } else {
      activityRow.timesheets.forEach((element) => {
        if (element.timesheet===false){
          totalHours+=0
        }else {
          const desiredRecord = element.timesheet.timesheet_records.filter(checkActivty);

          if (desiredRecord[0]) totalHours+=desiredRecord[0].time_taken
        }
        
      });
    }
    
   
    return totalHours
  }


  // THE TOTAL HOURS PER DAY ROW AND PERCENTAGE TIME WORKED IS CALCULATED HERE
  const sumHoursPerDay = (activityRow)=>{
    var totalHours = 0.0
    activityRow.timesheet.timesheet_records.forEach((element) => {
      totalHours += element.time_taken
    });
    return [totalHours, parseFloat((totalHours/8)*100).toFixed(2)]
  }

    

  return (
    <Box>
      <Box 
        sx={{
          display:"flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
          
          <Box 
            sx={{
              flexGrow: 999,
              paddingX: "10px",
              marginX: "5px",
              justifyContent: "space-between"
            }}
          >
            <Box
              sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-around",
                  // gridColumn: "span 4", 
                  m:"8px"
              }}
            >
              <h4>Filter By Month:</h4>
              <TextField
                label="Month"
                type="month"
                onChange={(e)=>{
                  handleChangeMonth(e);
                  setCurrentMonth(e.target.value);
                }}
                value={currentMonth}
                name="start_date"
                size="small"
                sx={{ mx: "10px"}}
              />

              <Button 
                size="small" 
                variant="contained" 
                onClick={() => {
                  setSearchTimeSheetMonthYear({"month": month,"year":year}) 
                  }}
                sx={{ mx: "10px", marginBottom:"23px"}}
              >
                Filter
              </Button>
            </Box>
            <Paper 
              sx={{ width: '98%'}}
              elevation={3}
             
            >
              <Box
                sx={{ 
                  width: '95%',
                  padding: "10px",
                  margin: "10px",
                }} 
                ref={componentRef}
              >
                <h3 style={{ textAlign: "center"}}>NAD TIME SHEET</h3 >
                <h6 style={{ textAlign: "center"}}>MONTHLY TIME REPORT FOR {timeSheetList.month} {timeSheetList.year}</h6 >
    
    
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width:"100%",
                    marginBottom: "15px", justifyContent: "space-around"
                  }}
                >
                  <TableContainer sx={{ maxHeight: "100vh", gridColumn: "span 4" }}>
                    <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh" sx={{ border: "1px solid" }} style={{ fontSize:"60%" }}>
                      <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 75,padding: "6px 0px",border: "1px solid" }}>
                              Ativity/Project
                            </TableCell>
                            <TableCell style={{ width: 75,padding: "6px 0px",border: "1px solid" }}>
                              Fund
                            </TableCell>
                            {timeSheetList.length===0  ? (
                                
                                <TableCell style={{ padding: "6px 0px",border: "1px solid" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              
                              ):(timeSheetList.timesheets.map((row, index)=>{
                                return(
                                  <TableCell style={{ textAlign: "center", writingMode:"vertical-lr", width: 30, padding: "6px 0px",border: "1px solid" }} key={row.id}>
                                    {moment(row.day).format('dddd')}
                                  </TableCell> 
                                )
                            }))}
                            <TableCell style={{ width: 50, padding: "6px 0px",border: "1px solid" }}>
                              TOTAL HRS
                            </TableCell>
                            <TableCell style={{ width: 50, padding: "6px 0px",border: "1px solid" }}>
                              %age to Charge
                            </TableCell>
                                    
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ padding: "6px 0px",border: "1px solid" }}>
                              
                            </TableCell>
                            <TableCell style={{ padding: "6px 0px",border: "1px solid" }}>
                              
                            </TableCell>
                            {timeSheetList.length===0  ? (
                                
                                <TableCell colSpan={12} style={{ padding: "6px 0px",border: "1px solid" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              
                              ):(timeSheetList.timesheets.map((row, index)=>{
                                return(
                                  <TableCell style={{ textAlign: "center", width: 10, padding: "6px 6px",border: "1px solid"   }} key={row.id}>
                                    {moment(row.day).format('DD')}
                                  </TableCell> 
                                )
                            }))}
                            <TableCell style={{ padding: "6px 0px",border: "1px solid" }}>
                              
                              </TableCell>
                              <TableCell style={{ padding: "6px 0px",border: "1px solid" }}>
                                
                              </TableCell>
                                    
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        
                        {timeSheetActivitiesList.length===0  ? (
                                <TableRow  role="checkbox" tabIndex={-1} >
                                  <TableCell  style={{ padding: "6px 0px",border: "1px solid" }}>
                                    <h3>No Data found</h3>
                                  </TableCell>
                                  </TableRow>
                        ):(timeSheetActivitiesList.map((row, index)=>{
                          return(
                            <TableRow>
                              <TableCell style={{ padding: "6px 0px",border: "1px solid"}} key={row.id}>
                                {row.display_name}
                              </TableCell>
                              <TableCell style={{ padding: "6px 0px",border: "1px solid"}} key={row.id}>
                                
                              </TableCell>
                              {timeSheetList.length===0  ? (
                                0
                              ):(timeSheetList.timesheets.map((innerRow, id)=>{
                                
                                return(
                                    // THE HOURS PER PROJECT PER DAY IS PRESENTED HERE
                                    innerRow.timesheet===false ? (
                                      <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }} >
                                        
                                      </TableCell>
                                    ):(
                                      <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }}>
                                        {filterRecords(innerRow, row) ? (filterRecords(innerRow, row).time_taken):(null)}
                                      </TableCell>
                                    ))
                                }))}

                                {/* THE TOTAL HOURS IN A MONTH PER PROJECT IS PRESENTED HERE*/}
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }}>
                                  {sumHoursPerProject(timeSheetList, row)}
                                </TableCell>

                                {/* THE PERCENTAGE OF THE TOTAL HOURS PER PROJECT IS PRESENTED HERE*/}
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }}>
                                  
                                </TableCell>

                            </TableRow>
                          )
                            }))}
                        <br/>
                        <TableRow>
                          <TableCell colSpan={2} style={{ width: 90,padding: "6px 0px",border: "1px solid"}} >
                            <b>TOTAL HOURS</b>
                          </TableCell>
                          
                          {timeSheetList.length===0  ? (
                            
                            <TableCell colSpan={12} style={{ padding: "6px 0px",border: "1px solid" }}>
                              <h3>No Data found</h3>
                            </TableCell>
                          
                          ):(timeSheetList.timesheets.map((row, index)=>{

                            // THE TOTAL HOURS PER DAY ROW IS PRESENTED HERE
                            return(
                              row.timesheet===false ? (
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }} >
                                  {0}
                                </TableCell>
                              ):(
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }}>
                                  {sumHoursPerDay(row)[0]}
                                </TableCell>
                              )
                            )
                          }))}
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                                  
                          </TableCell>
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                            
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ width: 90,padding: "6px 0px",border: "1px solid"}} >
                            <b>Total Expected hours of work</b>
                          </TableCell>
                            
                          {timeSheetList.length===0  ? (
                            
                            <TableCell colSpan={12} style={{ padding: "6px 0px",border: "1px solid" }}>
                              <h3>No Data found</h3>
                            </TableCell>
                          
                          ):(timeSheetList.timesheets.map((row, index)=>{
                            return(
                              <TableCell style={{ textAlign: "center", width: 10, padding: "6px 6px",border: "1px solid"   }} key={row.id}>
                                {moment(row.day).format('dddd') === "Sunday" || moment(row.day).format('dddd') === "Saturday" ? (0):(8) }
                              </TableCell> 
                            )
                          }))}
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                                
                          </TableCell>
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                            
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ width: 90, padding: "6px 0px",border: "1px solid"}} >
                            <b>Percent time worked</b>
                          </TableCell>
                            
                          {timeSheetList.length===0  ? (
                            
                            <TableCell colSpan={12} style={{ padding: "6px 0px",border: "1px solid" }}>
                              <h3>No Data found</h3>
                            </TableCell>
                          
                          ):(timeSheetList.timesheets.map((row, index)=>{
                            // PERCENTAGE OF THE HOURS WORKED IN A DAY OUT OF THE EXPECTED HOURS
                            return(
                              
                              row.timesheet===false ? (
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }} >
                                  {0}
                                </TableCell>
                              ):(
                                <TableCell style={{ textAlign: "center", padding: "6px 0px",border: "1px solid"  }}>
                                  {sumHoursPerDay(row)[1]}
                                </TableCell>
                              )
                            )
                          }))}
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                                  
                          </TableCell>
                          <TableCell style={{ width: 45,padding: "6px 0px",border: "1px solid" }}>
                            
                          </TableCell>
                        </TableRow>
                            
                      </TableBody>
                    </Table>
                  </TableContainer>

                
                </Box>
                  
                  <p style={{ textAlign:"justify" }}><span style={{ textAlign:"left", marginRight: "50px" }} >EMPLOYEE: {}  DATE: {}</span>  <span style={{ textAlign:"center", marginRight: "50px" }}>SUPERVISOR: {}  DATE: {}</span>   <span style={{ textAlign:"right" }} >EXECUTIVE SECRETARY: {}  DATE: {}</span></p>
                  </Box>
            </Paper>
            <Button 
              size="small" 
              variant="contained"
              color="success"
              sx={{ 
                width: "20%",
                m: "15px",
              }}
              
            >
              SUBMIT FOR REVIEW
            </Button>  
            <ReactToPrint
              trigger={()=> 
                <Button 
                  size="small" 
                  variant="contained"
                  sx={{ 
                    width: "20%",
                    m: "15px",
                  }}
                  
                >
                  Print
                </Button>  
              }
              content={()=>componentRef.current}
            />
              

          </Box>
      </Box>
    </Box>                
                
  );

  }

  export default TimesheetSummaryViewForm;