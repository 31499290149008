import {
  Box,
  Paper,
  Button,
  useTheme
} from "@mui/material";


import { useRef } from "react";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import ReactToPrint from "react-to-print";



const ProcurementRequisitionPrintPreviewForm = ({data}) => {

  const theme = useTheme();

  const date_created = moment(data.state.related_document_request.date_added).format('LL');
  const title= data.state.title;
  const activity= data.state.related_activity.activity_name;
  const purpose=data.state.purpose;
  // const related_tenant= data.state.related_tenant.tenant_name;
  // const related_tenant_department= data.state.related_tenant_department.department_name;
  const related_project=data.state.related_document_request.related_project_document.related_project.project_name;
  const relatedDocumentRequest = data.state.related_document_request.id
  const currentApprovalStep = []
  const approval_steps = data.state.approval_steps;
  const to = data.state.to;
  const meta_fields = data.state.meta_fields;
  const supportingDocuments = []
  const cost_center = data.state.cost_center.cost_center_name
  const budget_category = data.state.budget_category
  const added_by = data.state.related_document_request.added_by.first_name + ' ' + data.state.related_document_request.added_by.last_name
  
  const componentRef = useRef();
 

  return (
    <>
        
        <Paper 
            sx={{ width: '95%'}}
            elevation={3}
        >
          <Box
              sx={{ width: '95%',
              padding: "20px",
              margin: "20px",
            }} 
              ref={componentRef}
          >
                
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>RECTRACK</h3 >
            {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
            {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h3 > */}
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>PROCUREMENT REQUISITION</h3 >

              
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width:"100%",
                  marginBottom: "5px"
                }}
              >
                <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                  <TableHead>
                    <TableRow >
                      <TableCell align="left" colSpan={6}>
                        <b>Procurement Reference Number (Refer to the Procurement Guidelines):</b> 
                      </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          PD Entity Code
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 50 }}
                        >
                          Wrks/Srvcs/Supplies?
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Budget Year 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Sequence Number 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          ITB Number 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Contract Number 
                        </TableCell>
                                                          
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                    <TableRow  role="checkbox" tabIndex={-1} >
                        <TableCell >
                          {meta_fields.description}
                        </TableCell>
                        <TableCell >
                          {meta_fields.budget_code}
                        </TableCell>
                        <TableCell >
                          {meta_fields.amount}
                        </TableCell>
                        <TableCell >
                          {meta_fields.amount}
                        </TableCell>
                        <TableCell >
                          {meta_fields.amount}
                        </TableCell>
                        <TableCell >
                          {meta_fields.amount}
                        </TableCell>
                    </TableRow>
                        
                  </TableBody>
                </Table>
                </TableContainer>
                <br/>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                  <TableHead>
                    <TableRow >
                      <TableCell align="left" colSpan={6}>
                        <b>SUBJECT OF PROCUREMENT:  </b> {title}
                      </TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell align="left" colSpan={2}>
                        <b>LOCATION FOR DELIVERY:</b> 
                      </TableCell>
                      <TableCell align="left" colSpan={2}>
                        
                      </TableCell>
                      <TableCell align="left" colSpan={1}>
                        <b>Date Required:</b> 
                      </TableCell>
                      <TableCell align="left" colSpan={1}>
                        
                      </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                          style={{  minWidth: 50}}
                        >
                          Item No
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 50 }}
                          align="center"
                        >
                          Description <br/>
                          (A detailed list, Statement of Requirements or Stock Management Information may be attached)
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Unit of Measure
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Estimated Unit Cost
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Estimated Total Cost
                        </TableCell>
                                                          
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                    { meta_fields.map((row, index) => {
                      return (
                        <TableRow  role="checkbox" tabIndex={-1} key={index}>
                          <TableCell >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {row.description}
                          </TableCell>
                          <TableCell >
                            {row.quantity}
                          </TableCell>
                          <TableCell >
                            {row.unit_of_measure}
                          </TableCell>
                          <TableCell >
                            {row.estimated_unit_cost}
                          </TableCell>
                          <TableCell >
                            {row.estimated_total_cost}
                          </TableCell>
                      </TableRow>
                      );
                      })}
                    <TableRow   >
                        <TableCell >
                          
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          <b>Currency:</b>
                        </TableCell>
                        <TableCell colSpan={2} align="center">
                          <b>Overall Estimated total cost:</b>
                        </TableCell>
                        <TableCell >
                          
                        </TableCell>
                        

                      </TableRow>
                        
                  </TableBody>
                </Table>
                </TableContainer>

                <p><b>BUDGET CODE:</b> </p>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          PROGRAM
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 50 }}
                        >
                          PROJECT
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          DONOR 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          ACTIVITY 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          RESP/COST CENTRE
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          ANNUAL BUDGET
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          EXP. TO DATE
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          BUDGET BALANCE
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          AMOUNT REQUIRED
                        </TableCell>                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow  role="checkbox" tabIndex={-1} >
                        <TableCell >
                          
                        </TableCell>
                        <TableCell >
                          {related_project}
                        </TableCell>
                        <TableCell >
                          CDC
                        </TableCell>
                        <TableCell >
                          {activity}
                        </TableCell>
                        <TableCell >
                        {cost_center}
                        </TableCell>
                        <TableCell >
                          
                        </TableCell>
                        <TableCell >
                          
                        </TableCell>
                        <TableCell >
                          
                        </TableCell>
                        <TableCell >
                          
                        </TableCell>

                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <p><span style={{ textAlign: "left"}}><b>Requested By:</b> </span><span style={{ textAlign: "center", marginLeft:"15px"}}> <b>Name:</b> {added_by} </span> <span> <b>Date:</b> {date_created}</span></p>
                
            </Box>
           
            {!approval_steps ? (
                          
                          <></>
                        
            ): (approval_steps
              .map((row, index) => {
                return (
                  <>
                    {/* <p>
                      <span style={{ textAlign: "left"}}> <b>{row.related_document_approval_step.related_approval_step.approval_step_complete} BY:</b> {row.status === 1 ? (row.related_document_approval_step.related_approver.related_participant.related_user.first_name + ' '+ row.related_document_approval_step.related_approver.related_participant.related_user.last_name):(".................................")}</span> 
                      <span style={{ textAlign: "right", marginLeft:"15px"}}><b>{row.related_document_approval_step.related_approver.related_project_role.role_name}</b></span>
                      <span style={{ textAlign: "right", marginLeft:"15px"}}> <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")} </span>
                    </p> */}
                    <Table size="small">
                    <TableRow>
                      <TableCell style={{  minWidth: 50, padding: "2px 0px" }}>
                        <b>{row.related_document_approval_step.approval_step_complete} BY:</b>
                      </TableCell>
                      <TableCell style={{ padding: "2px 0px" }}>
                        {row.status === 1 ? (row.related_approver.related_user.first_name + ' '+ row.related_approver.related_user.last_name):(".................................")}
                      </TableCell>
                      <TableCell style={{  minWidth: 50, padding: "2px 0px" }}>
                        <b>{row.generic_role_name}</b>
                      </TableCell>
                      <TableCell style={{ padding: "2px 0px" }}>
                        <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")}
                      </TableCell>
                    </TableRow>
                    </Table>
                  </>
                  



                  
                );
              }))}
          </Box>
                
        </Paper>
        <ReactToPrint
          trigger={()=> 
            <Button 
              size="small" 
              variant="contained"
              sx={{ 
                width: "20%",
                my: "15px",
              }}
              
            >
              Print
            </Button>  
          }
          content={()=>componentRef.current}
        />
        
      </>            
  );

}

export default ProcurementRequisitionPrintPreviewForm;