import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import FullScreenDialog from "components/fullScreenDialog";
import CreateProjectRequestDocumentForm from "../forms/createProjectRequestDocumentForm";
import {  useProjectDocumentRequestList} from "FetchData";
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export default function ProjectRequestDocumentTable ({setIsSuccess, projectId}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const {projectDocumentRequestList, isLoadingProjectDocumentRequestList, error, setNewProjectDocumentRequestData} = useProjectDocumentRequestList(projectId);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState('')
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  // const handleClickSearch = async () => {
  //   setSearchProject(searchContent)
  // };


  return (
    <Box>
        <h4>Project Request Document List</h4>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              {/* <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Project Name" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper> */}
            </Grid>
            <Grid xs >
              {/* <NativeSelectDemo
                inputName = "Filter By"
                itemOptions={filterOptions}
              /> */}
              
            </Grid>
            <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Add Project Request Document</Button>
              <FullScreenDialog
              dialogButtonName = "Add Project Request Document"
              dialogHeading = "Add a New Project Request Document"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <CreateProjectRequestDocumentForm 
                              setOpen={setOpen} setNewProjectDocumentRequestData={setNewProjectDocumentRequestData} setIsSuccess={setIsSuccess} projectId={projectId}
                            />
                          </Box>
                        }
              >
                
              </FullScreenDialog>
            </Grid>
          </Grid>

        </Box>
      
      <Box>
        
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <TableRow>
                    <TableCell
                    // style={{  minWidth: 50}}
                    >
                      No.
                    </TableCell>
                    <TableCell
                    // style={{  minWidth: 170 }}
                    >
                      Document Name
                    </TableCell>
                    <TableCell
                    // style={{  minWidth: 170 }}
                    >
                      Document Domain
                    </TableCell>
                    <TableCell
                    // style={{  minWidth: 170 }}
                    >
                      Document Type
                    </TableCell>
                    <TableCell
                    // style={{  minWidth: 170 }}
                    >
                      Document Station
                    </TableCell>
                    <TableCell
                    style={{ textAlign: "center" }}
                    >
                    Action
                    </TableCell>
                    
                    
                </TableRow>
              </TableHead>
              <TableBody>
                {projectDocumentRequestList.length===0 ? (
                
                    <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                        <h3>No Data found</h3>
                    </TableCell>
                    </TableRow>
                
                ): (projectDocumentRequestList
                .map((row, index) => {
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        
                        <TableCell key={row.id}>
                            {row.id}
                        </TableCell>
                        <TableCell key={row.id}>
                            {row.document_name}
                        </TableCell>
                        <TableCell key={row.id}>
                            {row.document_domain.document_domain_name}
                        </TableCell>
                        <TableCell key={row.id}>
                            {row.document_type.document_type_name}
                        </TableCell>
                        <TableCell key={row.id}>
                            {row.related_region_project.station_name}
                        </TableCell>
                        
                        {/* WILL DESIGN A NEW SET OF ACTIONS FOR THIS LIST CONSIDER SHOWING THE APPROVAL STEPS */}
                        <TableCell style={{ textAlign: "center" }}>
                          
                          <Button 
                              variant="contained" 
                              value={row.id}
                              onClick={ 
                                  row.document_type.document_type_name==="MEMO" ? (e)=> handleClickOpen(e) 
                                  :
                                  row.document_domain.document_domain_name==="PAYMENT"   ? (
                                      ()=> {navigate(`/paymentRequisitionCreateForm/${projectId}/${row.id}`)}
                                  ) 
                                  :
                                  row.document_domain.document_domain_name==="PROCUREMENT"  ? (
                                      ()=> {navigate(`/procurementRequisitionCreateForm/${projectId}/${row.id}`)}
                                    ) 
                                  :
                                  row.document_domain.document_domain_name==="TRAVEL"  ? (
                                      ()=> {navigate(`/travelRequisitionCreateForm/${projectId}/${row.id}`)}
                                    ) 
                                  : null}
                          >
                              {row.document_type.document_type_name==="MEMO" ? ("Create Memo")
                              :
                              row.document_type.document_type_name==="REQUISITUION" ? ("Create Requisition"):
                              null}
                          </Button>
                        </TableCell>
                        
                    </TableRow>
                    );
                }))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={projectDocumentRequestList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};