import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import {  odmasApi } from "api/pesabackend";

import DocumentUploadForm from "../../global/documentUploadForm";
import { useUploadedDocumentList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";

export default function ProjectDocuments ({setIsSuccess, projectId, setIsFailed}) {
  const theme = useTheme();
  
  const {uploadedDocumentList, isLoadingUploadedDocumentList, setNewUploadedDocumentData, setSearchUploadedDocument} = useUploadedDocumentList(1,'project_general_document', projectId)
  
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // const [editData, setEditData] = useState('')
  // const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")
  const token =  useSelector((state) => state.token);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  // const handleClickEditOpen = async (e) => {
  //   const clickedItem = setClickedItem(e.target.value)
  //   const response = await axios.get(`http://localhost:8000/inventory/products/get/${e.target.value}`);
  //   setEditData(response.data);
  //   setOpenEdit(true);
  // };

  const handleDownload = async (filePath) => {
    const response = await odmasApi.get(`/${filePath}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    // const clickedItem = setClickedItem(e.target.value)
    // const response = await axios.get(`http://localhost:8000/inventory/products/get/${e.target.value}`);
    // setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    // const response = await axios.post(`http://localhost:8000/inventory/products/delete/${clickedItem}`);
    // setIsSuccess(true)
    // setNewUploadedDocumentData(true)
    // setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchUploadedDocument(searchContent)
  };


  return (
    <Box>
        <h4>Project Document List</h4>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search File Name" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xs >
              {/* <NativeSelectDemo
                inputName = "Filter By"
                itemOptions={filterOptions}
              /> */}
              
            </Grid>
            <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Upload Document</Button>
              <ResponsiveDialog
              dialogButtonName = "Add Document "
              dialogHeading = "Add a New Document"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <DocumentUploadForm 
                              setOpen={setOpen} setNewUploadedDocumentData={setNewUploadedDocumentData} setIsSuccess={setIsSuccess} uploadedDocumentType={"PROJECT DOCUMENTS"} uploadedDocumentCategory={1} relatedProject={projectId} setIsFailed={setIsFailed}
                            />
                          </Box>
                        }
              >
                
              </ResponsiveDialog>
            </Grid>
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: "100vh" }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <TableRow>
                  <TableCell
                        style={{  minWidth: 50}}
                      >
                        No.
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Date Uploaded
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 150 }}
                      >
                        File Name
                      </TableCell>
                      <TableCell
                        style={{  minWidth: 150 }}
                      >
                        Descritpion
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        Actions
                      </TableCell>
                      
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedDocumentList.length===0 ? (
                    
                      <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          <h3>No Data found</h3>
                        </TableCell>
                      </TableRow>
                    
                  ): (uploadedDocumentList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          
                            <TableCell key={row.id}>
                              {row.id}
                            </TableCell>
                            <TableCell key={row.id}>
                              {moment(row.date_added ).format('LL')}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.file_name}
                            </TableCell>
                            <TableCell key={row.id}>
                              {row.description}
                            </TableCell>
                            
                            
                            
                          
                            <TableCell style={{ textAlign: "center" }}>
                                <Button value={row.id} size="small" onClick={(e) => handleDownload(row.file_path)}>Download </Button>

                                <Button value={row.id} size="small" 
                                  // onClick={(e) => handleClickDeleteOpen(e)}
                                   >Delete</Button>

                                {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                                <ResponsiveDialog
                                  dialogHeading = "Edit"
                                  open = {openEdit}
                                  handleClose = {handleClickEditClose}
                                  negativeResponse = "Cancel"
                                  content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      {/* {<EditItemForm setOpen={setOpenEdit} setNewUploadedDocumentData={setNewUploadedDocumentData} id={clickedItem} editData={editData} setIsSuccess={setIsSuccess} />} */}
                                    </Box>
                                      
                                      }
                                />

                                <ResponsiveDialog
                                  dialogHeading = "Are you sure you want to delete?"
                                  open = {openDelete}
                                  handleClose = {handleClickDeleteClose}
                                  negativeResponse = "Cancel"
                                  content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      {<Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        onClick={
                                          handleClickDelete
                                        }
                                        sx={{
                                          m: "1rem 0",
                                          p: "1rem",
                                        }}
                                      >
                                        Yes, Delete
                                      </Button>}
                                    </Box>
                                      
                                      }
                                />


                                {/* <ResponsiveDialog
                                    dialogHeading = "Are you sure you want to delete?"
                                    open = {openView}
                                    handleClose = {handleClickViewClose}
                                    negativeResponse = "Cancel"
                                    content = {
                                      <Box
                                        p="1rem"
                                        borderRadius="0.5rem"
                                        backgroundColor={theme.palette.background.alt}
                                      >
                                        {ViewContent}
                                      </Box>
                                        
                                        }
                                  /> */}
                                  
                              
                            </TableCell>
                          
                        </TableRow>
                      );
                    }))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={uploadedDocumentList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
      </Box>
    </Box>
  );
};