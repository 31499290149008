
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    Autocomplete
  } from "@mui/material";
import { Formik,  } from "formik";
import * as yup from "yup";
import * as React from "react";
import { useSelector } from "react-redux";
import { inventoryApi } from "api/pesabackend";

  
const itemUnitSchema = yup.object().shape({
related_business_product_unit: yup.number().required("required"),
unit_selling_price: yup.number().required("required"),
number_of_smallest_units: yup.number().required("required"),
});


const ItemUnitDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveItemUnitRow, 
        setItemUnitDetailFormSubmit, 
        handleItemUnitChange,
        handleNumberOfSmallestUnitChange,
        handleSellingPriceChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const business_branch_id = useSelector((state) => state.business_branch_id);
    const [unitList, setUnitList] = React.useState([]);
    const [isLoadingUnitList, setIsLoadingUnitList] = React.useState(true);
    const [error, setError] = React.useState(false);
    
    const fetchUnitList = async (search) => {
        try {
            const response = await inventoryApi.get(`/business_product_units/list?business_branch=${business_branch_id}&search=${search}`);
            setUnitList(response.data);
            setIsLoadingUnitList(false);
        } catch (e) {
            setError(true);
            setIsLoadingUnitList(false);
        }
    };
    

    const initialValuesPurchase = {
        related_business_product_unit: data.related_business_product_unit,
        related_business_product_unit_default:data.related_business_product_unit,
        unit_selling_price: data.unit_selling_price,
        number_of_smallest_units: data.number_of_smallest_units,
        };
    

    const handleFormSubmit = async (values, onSubmitProps) => {
        setItemUnitDetailFormSubmit(values);
       
    };

    return (
            
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPurchase}
            validationSchema={itemUnitSchema}
            
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Box >
                        <p >#{index+1}</p>
                    </Box>
                    <Box
                        display="grid"
                        gap="30px"
                        p="0.5rem"
                        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                        }}
                    >
                        <>
                            <Autocomplete
                                size="small"
                                onOpen={ ()=> {
                                    fetchUnitList('false');
                                }}
                                onBlur={handleBlur}
                                name="related_business_product_unit"
                                defaultValue={values.related_business_product_unit_default}
                                error={Boolean(touched.related_business_product_unit) && Boolean(errors.related_business_product_unit)}
                                helperText={touched.related_business_product_unit && errors.related_business_product_unit}
                                onChange={(e, value)=>{
                                    handleChange(e);
                                    handleItemUnitChange(e,index,value);
                                }}
                                sx={{ gridColumn: "span 4" }}
                                options={unitList}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => <TextField 
                                                            {...params} 
                                                            inputProps={{
                                                                ...params.inputProps
                                                            }}
                                                            label="Unit Name"
                                                            value={values.related_business_product_unit}
                                                            defaultValue={values.related_business_product_unit_default.name}
                                                            onChange={(e)=>{
                                                                fetchUnitList(e.currentTarget.value);
                                                            }}
                                                            />}
                            />

                            <TextField
                                label="Qty in Smallest Units"
                                size="small"
                                type="number"
                                inputProps={{
                                    min: 0
                                }}
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleNumberOfSmallestUnitChange(e,index);
                                }}
                                value={values.number_of_smallest_units}
                                name="number_of_smallest_units"
                                error={Boolean(touched.number_of_smallest_units) && Boolean(errors.number_of_smallest_units)}
                                helperText={touched.number_of_smallest_units && errors.number_of_smallest_units}
                                sx={{ gridColumn: "span 3" }}
                            />

                            <TextField
                                label="Selling Price"
                                size="small"
                                type="number"
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleSellingPriceChange(e,index);
                                }}
                                value={values.unit_selling_price}
                                inputProps={{
                                    min: 0
                                }}
                                name="unit_selling_price"
                                error={
                                Boolean(touched.unit_selling_price) && Boolean(errors.unit_selling_price)
                                }
                                helperText={touched.unit_selling_price && errors.unit_selling_price}
                                sx={{ gridColumn: "span 3" }}
                            />                                      
                            
                            <Button
                                value={index}
                                onClick={() => {
                                    handleRemoveItemUnitRow(data.data_id);
                                }}                                                     
                                size="small"
                                variant="contained"
                                >
                                Remove
                            </Button>
                        </>
                    {/* )} */}
                    </Box>
                </Box>

                
            </form>
            )}
        </Formik>
                                
                            
           );
  };
  
  export default ItemUnitDetailForm;
  