
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme, 
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const itemSchema = yup.object().shape({
  clock_out_time: yup.string().required("required")
  
});


const ClockOutCreateForm = (props) => {
  const pageType = props.formType;
  let {id, clockingData} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);

  const initialValuesItem = {
    id: clockingData.id,
    day_recorded: clockingData.day_recorded,
    clock_in_time: clockingData.clock_in_time,
    clock_out_time: clockingData.clock_out_time ? (clockingData.clock_out_time) : (moment().format('HH:mm')),
    clock_out_geo_tag: "Kampala"
  };
  

  const saveItem = async (values, onSubmitProps) => {
  
    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/clock_in_clock_out/clock_out", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewClockingData(true);
      onSubmitProps.resetForm();
      navigate("/clocking&timesheet");
      props.setIsSuccess(true);
    } 
    if (savedItem.status===400){
      props.setIsFailedError(savedItem.error)
      props.setIsFailed(true);
      onSubmitProps.resetForm();
    }
  };


  


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Clocking Date"
              onBlur={handleBlur}
              disabled
              type="date"
              onChange={handleChange}
              value={values.day_recorded}
              name="day_recorded"
              error={
                Boolean(touched.day_recorded) && Boolean(errors.day_recorded)
              }
              helperText={touched.day_recorded && errors.day_recorded}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Clock In"
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              type="time"
              value={values.clock_in_time}
              name="clock_in_time"
              error={Boolean(touched.clock_in_time) && Boolean(errors.clock_in_time)}
              helperText={touched.clock_in_time && errors.clock_in_time}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Clock Out"
              onBlur={handleBlur}
              onChange={handleChange}
              type="time"
              disabled={clockingData.clock_out_time ?(true):(false)}
              value={values.clock_out_time}
              name="clock_out_time"
              error={Boolean(touched.clock_out_time) && Boolean(errors.clock_out_time)}
              helperText={touched.clock_out_time && errors.clock_out_time}
              sx={{ gridColumn: "span 4" }}
              inputProps={{ 
                min: values.clock_in_time
               }}
            />


           
      </Box>

      {/* BUTTONS */}
      <Box
        display="grid"
        gap="30px"
        p="0.5rem"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
        }}
      >
        {
          clockingData.clock_out_time ? null : (
            <Button
              fullWidth
              type="submit"
              size="small"
              sx={{
                m: "1rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                gridColumn: "span 6" 
              }}
            >
              CLOCK OUT
            </Button>)
        }

      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ClockOutCreateForm;
