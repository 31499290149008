
import {Box,Button,TextField,useMediaQuery,Typography,FormControlLabel, Switch, useTheme,MenuItem} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {  useDepartmentRoleList } from "FetchData";
import {  useState } from "react";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;




const EditUserBioDataForm = (props) => {
  const {data, employeeId} = props;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const token =  useSelector((state) => state.token);
  const departmentId = useSelector((state)=> state.employeeData.related_department.id);
  const {departmentRoleList, isLoadingDepartmentRoleList} = useDepartmentRoleList(departmentId);
  const {related_tenant, related_department} = useSelector((state)=>state.employeeData);

  const initialValuesItem = {
    first_name: data[0].first_name,
    last_name: data[0].last_name,
    email: data[0].email,
    username: data[0].username,
    phone_number: data[0].phone_number,
    related_role: data[1] ? data[1].id : 0,
    has_department_role: data[1] ? "True" : "False",
  };

  const[hasDepartmentRole, setHasDepartmentRole]= useState(initialValuesItem.has_department_role);

  const itemSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    email: yup.string().email("Input a valid email").required("required"),
    username: yup.string().required("required"),
    phone_number: yup.string().required("required"),
    related_role: hasDepartmentRole === "False"  ? yup.number() : yup.number().required("required"),
    
  });


  const saveItem = async (values, onSubmitProps) => {

    values.related_department = related_department.id
    values.related_tenant = related_tenant.id

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const savedItemResponse = await axios.post(`${API_URL}/tenants/departments/employees/individual?employee_id=${employeeId}&method=edit&attribute=biodata`, JSON.stringify(values),{
    headers: {
        'Content-Type': 'application/json'
    }
    });

    if (savedItemResponse.status===200) {
      props.setOpen(false);
      props.refreshData()
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  const handleCheckHasDepartmentRole = (e) => {
    
    if (e.target.checked === true ) {
      setHasDepartmentRole("True")
    }
    if (e.target.checked === false ) {
      setHasDepartmentRole("False")
    }
  };  

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.first_name}
              name="first_name"
              error={
                Boolean(touched.first_name) && Boolean(errors.first_name)
              }
              helperText={touched.first_name && errors.first_name}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              label="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.last_name}
              name="last_name"
              error={Boolean(touched.last_name) && Boolean(errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="Username"
              disabled
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.username}
              name="username"
              error={Boolean(touched.username) && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              sx={{ gridColumn: "span 1" }}
            />
            
            <TextField
              label="Phone Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.phone_number}
              name="phone_number"
              error={
                Boolean(touched.phone_number) && Boolean(errors.phone_number)
              }
              helperText={touched.phone_number && errors.phone_number}
              sx={{ gridColumn: "span 1" }}
            />

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 2" }}
            />

            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.has_department_role = hasDepartmentRole}
                name="has_department_role"
                control={
                  <Switch 
                    defaultChecked={initialValuesItem.has_department_role === "True" ? true :false}
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleCheckHasDepartmentRole(e)
                    }}
                  />
                }
                label="Does the User have a department role:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {hasDepartmentRole === "True" ? 
              (
                <TextField
                label="Department Role"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.related_role}
                select
                name="related_role"
                error={Boolean(touched.related_role) && Boolean(errors.related_role)}
                helperText={touched.related_role && errors.related_role}
                sx={{ gridColumn: "span 2" }}
                >
                  {departmentRoleList.map((row)=>{
                    return(
                      <MenuItem value={row.id} selected={initialValuesItem.related_role.id===row.id ? true :false } >{row.role_name}</MenuItem>
                    )})
                  }
                </TextField>
              ):(null)
            }
            
          </Box> 

          {/* BUTTONS */}
          <Box>
            <Button
              fullWidth
              size="small"
              type="submit"
              sx={{
                m: "10px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditUserBioDataForm;
