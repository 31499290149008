
import {Box,Button,TextField,useMediaQuery} from "@mui/material";
import * as React from "react";

const PaymentRequestDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveItemRow,
        handleAmountChange,
        name, 
        errors,
        touched,
        handleBlur,
        handleChange,
        } = props;

    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    return (
            
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                width:"100%"
            }}
        >
            <Box >
                <p >#{index+1}</p>
            </Box>
            <Box
                display="grid"
                gap="30px"
                p="0.5rem"
                gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
                }}
            >
               
                <TextField
                    label="Description"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={data.description}
                    name={`${name}.description`}
                    error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.description) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.description)}
                    helperText={ touched?.meta_fields && touched?.meta_fields[index]?.description && errors?.meta_fields && errors?.meta_fields[index]?.description}
                    sx={{ gridColumn: "span 4" }}
                />

                <TextField
                    label="Budget Code"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={data.budget_code}
                    name={`${name}.budget_code`}
                    error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.budget_code) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.budget_code)}
                    helperText={ touched?.meta_fields && touched?.meta_fields[index]?.budget_code && errors?.meta_fields && errors?.meta_fields[index]?.budget_code}
                    sx={{ gridColumn: "span 3" }}
                />

                <TextField
                    label="Amount"
                    size="small"
                    type="number"
                    onBlur={handleBlur}
                    onChange={(e)=>{
                        handleChange(e);
                        handleAmountChange(e,index);
                    }}
                    value={data.amount}
                    inputProps={{
                        min: 0
                    }}
                    name={`${name}.amount`}
                    error={Boolean( touched?.meta_fields && touched?.meta_fields[index]?.amount) && Boolean(errors?.meta_fields && errors?.meta_fields[index]?.amount)}
                    helperText={ touched?.meta_fields && touched?.meta_fields[index]?.amount && errors?.meta_fields && errors?.meta_fields[index]?.amount}
                    sx={{ gridColumn: "span 3" }}
                />                                      
                
                <Button
                    value={index}
                    onClick={() => {
                        handleRemoveItemRow(index);
                    }}                                                     
                    size="small"
                    variant="contained"
                    sx={{ 
                        width: "20%",
                        marginBottom:"20px",
                        }}
                    >
                    Remove
                </Button>
                
            </Box>
        </Box>
    );
  };
  
  export default PaymentRequestDetailForm;
  