import { Box, useTheme, Grid, Button, Paper, Alert } from "@mui/material";
import FullScreenDialog from "components/fullScreenDialog";
import { useNavigate } from "react-router-dom";
import { useLeaveRequestList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DelegationCreateForm from "../forms/delegationCreateForm";
import moment from "moment";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


export default function UserLeaveRequests () {
  const theme = useTheme();
  const navigate = useNavigate();
  const employeeId = useSelector((state) => state.employeeData.id);
  const token =  useSelector((state) => state.token);
  const [open, setOpen] = useState(false);
  const [openDelegation, setOpenDelegation] = useState(false);
  const [editData, setEditData] = useState('')
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")
  const [isSidebar, setIsSidebar] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);
  const [currentAttachedRoles, setCurrentAttachedRoles]  = useState(false);
  const {leaveRequestList, isLoadingLeaveRequestList,setSearchLeaveRequest} = useLeaveRequestList('True');


  // const leaveRequestList = [{id: 1, leave_date: "2024-02-13", date_added:"2024-02-01", status:" Delegation Pending", isDelegeted: false, approvalStatus: "PENDING" }]

  const handleClickMakeRequest = async (e) => {
    const response = await axios.get(`${API_URL}/requests/leave_details?employee_id=${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    navigate(`/leaveReaquestCreateForm`, { state: response.data.data})
    
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };

    const handleClickOpenDelegation = (e) => {
      // const response = await axios.get(`http://localhost:8000/inventory/products/get/${e.target.value}`);
      setCurrentAttachedRoles([
        {action: {id: 2, name: "VERIFICATION"},  project: 'FLASH', document: "Head Office Memo", delegated_to: "",is_delegated: false,  data_id: uuidv4(),},
        {action: {id: 4, name: "REVIEW"},  project: 'HIV', document: "Payment Requisition", delegated_to: "",is_delegated: false,  data_id: uuidv4(),},
        {action: {id: 5, name: "APPROVE"},  project: 'SAVE THE CHILDREN',document: "Procurement Requisition", delegated_to: "",is_delegated: false,  data_id: uuidv4(),},
      ]);
      setOpenDelegation(true);
    };
    
  const handleCloseDelegation = () => {
        setOpenDelegation(false);
      };

 
  
  
  

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    // setSearchItem(searchContent)
  };


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999,
              ml: "10px"
            }}
          > 
               {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
                ) : null
                                                
              }
              <Box>
                  <h4>My Leave Requests</h4>
                  <Box
                    p="1rem"
                    m ="1rem"
                    borderRadius="0.5rem"
                    
                  >
                    <Grid container spacing={4} className="alignHorizantal">
                      
                      <Grid xs sx={{ textAlign:'right' }}>
                        <Button 
                          variant="contained" 
                          onClick={() => {handleClickMakeRequest()}}>
                              Make Leave Request
                        </Button>
                        
                      </Grid>
                    </Grid>

                  </Box>
                
                <Box>
                <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: "100vh" }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                      <TableHead>
                        <TableRow>
                            <TableCell
                              // style={{  minWidth: 50}}
                            >
                              No.
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Leave Date
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Current Approval Level
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Date Created
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Request Status
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                                Actions
                              </TableCell>
                            
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leaveRequestList.length===0 ? (
                          
                            <TableRow>
                              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                <h3>No Data found</h3>
                              </TableCell>
                            </TableRow>
                          
                        ): (leaveRequestList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                
                                  <TableCell key={row.id}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.leave_date}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.approvalStatus}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.date_added}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.status}
                                  </TableCell>
                                  
                                  <TableCell style={{ textAlign: "center" }}>

                                        { row.isDelegeted === false ? (<Button 
                                          value={row.id} 
                                          size="small"
                                          onClick={(e) => handleClickOpenDelegation(e)}
                                        >
                                          Delegate
                                        </Button>) : null}

                                      {/* <Button value={row.id} size="small" 
                                        // onClick={(e) => handleClickEditOpen(e)}
                                        >Edit </Button>

                                      <Button value={row.id} size="small" 
                                        // onClick={(e) => handleClickDeleteOpen(e)}
                                        >Delete</Button> */}


                                        {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                                        <FullScreenDialog
                                          dialogHeading = {"Delegate Actions for the Leave period starting" + " " + moment(row.leave_date).format('LL')}
                                          open = {openDelegation}
                                          handleClose = {handleCloseDelegation}
                                          negativeResponse = "Cancel"
                                          content = {
                                            <Box
                                              p="1rem"
                                              borderRadius="0.5rem"
                                              backgroundColor={theme.palette.background.alt}
                                            >
                                              <DelegationCreateForm leaveId={row.id} currentAttachedRoles={currentAttachedRoles} />
                                            </Box>
                                              
                                              }
                                        />

                                      
                                    </TableCell>
                                
                              </TableRow>
                            );
                          }))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={leaveRequestList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
                </Box>
              </Box>
          </Box>
        </Box>
      </Box>
                                        

  );
};