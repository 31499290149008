
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";


const itemSchema = yup.object().shape({
  file_name: yup.string().required("required"),
  description: yup.string(),
  file_path: yup.string().required("required"),
});


const DocumentUploadForm = (props) => {

  const userId = useSelector((state) => state.user.id);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);
      
  const initialValuesItem = {
    file_name: "",
    description: "",
    file_path: "",
    uploaded_document_type:props.uploadedDocumentType,
    uploaded_document_category:props.uploadedDocumentCategory,
    related_department:1,
    related_project: props.relatedProject,
    added_by: userId,
    related_document_request:props.relatedDocumentRequest
    
  };

  const saveItem = async (data, onSubmitProps) => {

    const savedItemResponse = await fetch("https://api.odms.iolabz.ug/api/v1/upload_document/add", {
      method: "POST",
      headers: { 
        Authorization: `Bearer ${token}`
       },
      body: data,
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem.status ===202) {
      props.setOpen(false);
      props.setNewUploadedDocumentData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    } else {
      props.setIsFailed(true);      
    }
    
  };


  const handleFormSubmit = async (values, onSubmitProps) => {

    /* Then create a new FormData obj */
    let formData = new FormData();

 
    
    /* append input field values to formData */
    for (let value in values) {
      formData.append(value, values[value]);
    }

    /* Can't console.log(formData), must
       use formData.entries() - example:  */
    for (let property of formData.entries()) {
      console.log(property[0], property[1]);
    }

    /* Now POST your formData: */
    saveItem(formData, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
      
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="File Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.file_name}
              name="file_name"
              error={
                Boolean(touched.file_name) && Boolean(errors.file_name)
              }
              helperText={touched.file_name && errors.file_name}
              sx={{ gridColumn: "span 2" }}
            />
            <TextField
              label="Description"
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={2}
              value={values.description}
              name="description"
              error={Boolean(touched.description) && Boolean(errors.description)}
              helperText={touched.description && errors.description}
              sx={{ gridColumn: "span 4" }}
            />
            
            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png, .pdf, .ppt, .xls, .doc"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  setFieldValue("file_path", acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.file_path ? (
                      <p>Upload Document Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.file_path.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default DocumentUploadForm;
