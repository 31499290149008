import { Box, useTheme, Grid, Button, Paper, InputBase, Alert } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import ActivityCreateForm from "../forms/activityCreateForm";
import { useActivityList} from "FetchData";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from "moment";

export default function ActivityTable ({setIsSuccess}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const token =  useSelector((state) => state.token);
  const {activityList, isLoadingActivityList, setNewActivityData, setSearchActivity} = useActivityList(1)
  
  const [open, setOpen] = useState(false);
  const [clickedItem, setClickedItem] = useState("")
  const[searchContent, setSearchContent] = useState("")

  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed]);

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickViewDetails = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`https://api.odms.iolabz.ug/api/v1/activity/get/${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    navigate("/activity&tasks", { state: response.data.data})
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchActivity(searchContent)
  };


  return (
    <Box>
        <h4>Activity List</h4>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Activity Name" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xs >
              {/* <NativeSelectDemo
                inputName = "Filter By"
                itemOptions={filterOptions}
              /> */}
              
            </Grid>
            <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Add Activity</Button>
              <ResponsiveDialog
              dialogButtonName = "Add Activity "
              dialogHeading = "Add a New Activity"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            {
                              isFailed ? (
                                  <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
                              ) : null
                                                              
                            }
                            <ActivityCreateForm 
                              setOpen={setOpen} setNewActivityData={setNewActivityData} setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} setIsFailedError={setIsFailedError} data={{activity_name:"", start_date: moment().format("YYYY-MM-DD"), end_date: moment().format("YYYY-MM-DD"), is_recurring:false, recurring_frequency:"N/A", has_report: false}} 
                            />
                          </Box>
                        }
              >
                
              </ResponsiveDialog>
            </Grid>
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <TableRow>
                    <TableCell
                      style={{  minWidth: 50}}
                    >
                      No.
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 170 }}
                    >
                      Activity Name
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 135 }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 135 }}
                    >
                      End Date
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 135 }}
                    >
                      Reoccurance
                    </TableCell>

                    <TableCell >
                      Actions
                    </TableCell>
                    
                </TableRow>
              </TableHead>
              <TableBody>
                {activityList.length===0 ? (
                  
                    <TableRow>
                      <TableCell colSpan={12} style={{ textAlign: "center" }}>
                        <h3>No Data found</h3>
                      </TableCell>
                    </TableRow>
                  
                ): (activityList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        
                          <TableCell key={row.id}>
                            {row.id}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.activity_name}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.start_date}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.end_date}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.recurring_frequency}
                          </TableCell>
                          
                          <TableCell >
                              <Button value={row.id} size="small" onClick={(e) => handleClickViewDetails(e)}>View Details </Button>

                            </TableCell>
                        
                      </TableRow>
                    );
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={activityList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};