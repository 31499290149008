import { Box, useMediaQuery, Tab, Tabs, Typography, Alert } from "@mui/material";
import { useSelector, } from "react-redux";
import * as React from 'react';
import PropTypes from 'prop-types';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import LeaveRequests from "../tables/leaveRequestTable";
import LeaveCalendar from "../forms/leaveCalendar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LeaveTab = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSidebar, setIsSidebar] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);
  // const { _id, picturePath } = useSelector((state) => state.user);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999
            }}
          > 
               {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
                ) : null
                                                
              }
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs fullWidth value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label="Leave Calendar" {...a11yProps(0)} />
                  <Tab label="Leave Requests" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <Box>
                
                <TabPanel value={value} index={0}>
                  <LeaveCalendar/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <LeaveRequests/>
                </TabPanel>
              </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default LeaveTab;