import {
    Box,
    Paper,
    Button,
    useTheme
  } from "@mui/material";

  import { useState, useRef } from "react";
  import moment from "moment/moment";
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableHead from '@mui/material/TableHead';
  import TableRow from '@mui/material/TableRow';
  import TableContainer from '@mui/material/TableContainer';
  import ReactToPrint from "react-to-print";



  const PaymentRequisitionPrintPreviewForm = ({data}) => {
    const theme = useTheme();

    const date_created = moment(data.state.related_document_request.date_added).format('LL');
    const title= data.state.title;
    const background= data.state.background;
    const purpose=data.state.purpose;
    const related_project=data.state.related_document_request.related_project_document.related_project.project_name;
    const approval_steps = data.state.approval_steps;
    const to = data.state.to;
    const total = data.state.total;
    const meta_fields = data.state.meta_fields;
    const cost_center = data.state.cost_center.cost_center_name
    const budget_category = data.state.budget_category
    const added_by = data.state.related_document_request.added_by.first_name + ' ' + data.state.related_document_request.added_by.last_name
    
    const componentRef = useRef();
    
    

    return (
      <>
        
        <Paper 
            sx={{ width: '95%'}}
            elevation={3}
        >
          <Box
              sx={{ width: '95%',
              padding: "20px",
              margin: "20px",
            }} 
            ref={componentRef}
          >
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>REC-TRACK</h3 >
            {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
            {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h3 > */}
            <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>PAYMENT REQUISITION</h3 >

            <p> <b>Date:</b> {date_created}</p>
            <span style={{ textAlign: "left"}}> <b>Requisitioning Officer:</b> {added_by} </span>
            <span style={{ textAlign: "right", marginLeft:"15px"}}> <b>Source of Funds:</b> CDC</span>
            <p> <b>Purpose:</b> {title}</p>

            <Box
              sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
              }}
              >
              
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width:"100%",
                  marginBottom: "15px"
                }}
              >
                <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                  <TableHead>
                    <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 50 }}
                        >
                          Budget Code
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          Amount 
                        </TableCell>
                                                          
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!meta_fields ? (
                      
                        <TableRow>
                          <TableCell colSpan={12} style={{ textAlign: "center" }}>
                            <h3>No Data found</h3>
                          </TableCell>
                        </TableRow>
                      
                    ): (meta_fields
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow  role="checkbox" tabIndex={-1} key={index}>
                            
                              <TableCell key={row.id}>
                                {row.description}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.budget_code}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.amount}
                              </TableCell>
                          </TableRow>
                        );
                      }))}
                      <TableRow   >
                        <TableCell colSpan={2} align="right">
                          <b>Total:</b>
                        </TableCell>
                        <TableCell >
                          {total}
                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>

                <p><b>BUDGET FINANCING SOURCE:</b> </p>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                  <TableHead>
                    <TableRow>
                        <TableCell
                          // style={{  minWidth: 50}}
                        >
                          COST CENTRE
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 50 }}
                        >
                          PROJECT
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          DONOR 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          BUDGET CATEGORY 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          ACTIVICTY 
                        </TableCell>
                        <TableCell
                          // style={{  minWidth: 150 }}
                        >
                          SUB ACTIVITY 
                        </TableCell>
                                                          
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  <TableRow  role="checkbox" tabIndex={-1} >
                            
                            <TableCell >
                              {cost_center}
                            </TableCell>
                            <TableCell >
                              {related_project}
                            </TableCell>
                            <TableCell >
                              CDC
                            </TableCell>
                            <TableCell >
                              {budget_category}
                            </TableCell>
                            <TableCell >
                              
                            </TableCell>
                            <TableCell >
                              
                            </TableCell>

                          
                        </TableRow>
                  </TableBody>
                </Table>
                </TableContainer>
              </Box>
            </Box>
            
            {!approval_steps ? (
                                
                                <></>
                              
                            ): (approval_steps
                              .map((row, index) => {
                                return (
                                  <>
                                    {/* <p>
                                      <span style={{ textAlign: "left"}}> <b>{row.related_document_approval_step.related_approval_step.approval_step_complete} BY:</b> {row.status === 1 ? (row.related_document_approval_step.related_approver.related_participant.related_user.first_name + ' '+ row.related_document_approval_step.related_approver.related_participant.related_user.last_name):(".................................")}</span> 
                                      <span style={{ textAlign: "right", marginLeft:"15px"}}><b>{row.related_document_approval_step.related_approver.related_project_role.role_name}</b></span>
                                      <span style={{ textAlign: "right", marginLeft:"15px"}}> <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")} </span>
                                    </p> */}
                                    <Table size="small">
                                    <TableRow>
                                      <TableCell style={{  minWidth: 50 }}>
                                        <b>{row.related_document_approval_step.approval_step_complete} BY:</b>
                                      </TableCell>
                                      <TableCell>
                                        {row.status === 1 ? (row.related_approver.related_user.first_name + ' '+ row.related_approver.related_user.last_name):(".................................")}
                                      </TableCell>
                                      <TableCell style={{  minWidth: 50 }}>
                                        <b>{row.generic_role_name}</b>
                                      </TableCell>
                                      <TableCell>
                                        <b>Date: </b> {row.date_performed!==null ? (moment(row.date_performed).format('L')):(".............")}
                                      </TableCell>
                                    </TableRow>
                                    </Table>
                                  </>
                                  



                                  
                                );
                              }))}
          </Box>
        </Paper>
        <ReactToPrint
          trigger={()=> 
            <Button 
              size="small" 
              variant="contained"
              sx={{ 
                width: "20%",
                my: "15px",
              }}
              
            >
              Print
            </Button>  
          }
          content={()=>componentRef.current}
        />
        
      </>
    );

  }

  export default PaymentRequisitionPrintPreviewForm;