import axios from 'axios';
import { useSelector } from "react-redux";

// const token =  useSelector((state) => state.token);

const inventoryApi = axios.create({
    baseURL: 'http://localhost:8000/inventory',
    headers: {

    }  
})

const customerApi = axios.create({
    baseURL: 'http://localhost:8000/customers',
    headers: {

    }  
})


const odmasApi = axios.create({
    baseURL: 'https://api.rectrack.iolabz.ug/api/v1',
    headers: {
        // "Authorization": "Bearer " + token
    }
})

export {
    inventoryApi, customerApi, odmasApi
}
    