import { Box, Button, Paper,} from "@mui/material";
import { useLeaveRequestList} from "FetchData";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from "react";



export default function LeaveRequests () {

  const[searchContent, setSearchContent] = useState("");

  const {leaveRequestList, isLoadingLeaveRequestList,setSearchLeaveRequest} = useLeaveRequestList('False');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleClickView = async (e) => {
    
  }

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    
  };


  return ( 
      <Box>
          <h3>Pending Leave Requests</h3>
          <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: "100vh" }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                      <TableHead>
                        <TableRow>
                            <TableCell
                              // style={{  minWidth: 50}}
                            >
                              No.
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Leave Date
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Requested By
                            </TableCell>
                            <TableCell
                              // style={{  minWidth: 170 }}
                            >
                              Date Created
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                                Actions
                              </TableCell>
                            
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leaveRequestList.length===0 ? (
                          
                            <TableRow>
                              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                <h3>No Data found</h3>
                              </TableCell>
                            </TableRow>
                          
                        ): (leaveRequestList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                
                                  <TableCell key={row.id}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.leave_date}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.approvalStatus}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.date_added}
                                  </TableCell>
                                  
                                  <TableCell style={{ textAlign: "center" }}>
                                    <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >View Details</Button>
                                  </TableCell>
                                
                              </TableRow>
                            );
                          }))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={leaveRequestList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
      </Box>
     
  );
};