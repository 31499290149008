import { Box, Typography, useTheme, useMediaQuery, Alert, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";

const UserDelete = () => {
  const { palette } = useTheme();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  
  const deleteSchema = yup.object().shape({
    username: yup.string().required("required"),
  });

  const handleFormSubmit = async () => {
    // if (isLogin) await login(values, onSubmitProps);
    // if (isRegister) await register(values, onSubmitProps);
  };

  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box
        width={"100"}
        p="10rem"
        m="2rem auto"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ textAlign: "center", mb: "1.5rem" }}>
          Account Delete
        </Typography>

        {
          isFailed ? (
              <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
          ) : null
                                          
        }

        <Formik
        // onSubmit={handleFormSubmit}
        initialValues={deleteSchema}
        validationSchema={deleteSchema}
        >
        {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm,
        }) => (
            <form onSubmit={handleSubmit}>
            <Box
                display="grid"
                gap="30px"
                // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >

                <TextField
                label="Username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={Boolean(touched.username) && Boolean(errors.username)}
                helperText={touched.username && errors.username}
                />

                <TextField
                label="Confirm Username"
                type="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                />
            </Box>
            <Box>
                <Button
                fullWidth
                type="submit"
                sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: palette.background.secondary,
                    color: palette.secondary.white,
                    "&:hover": { color: palette.secondary.white, backgroundColor: palette.secondary.main},
                }}
                >
                LOGIN
                </Button>
            </Box>
            </form>
        )}
        </Formik>
      </Box>
    </Box>
  );
};

export default UserDelete;
