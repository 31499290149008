
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    Autocomplete
  } from "@mui/material";
import { Formik,  } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useSelector } from "react-redux";
import { odmasApi } from "api/pesabackend";

  


const TimesheetHourDetailForm = (props) => {

    const {index, 
        data,
        handleRemoveTimeSheetRow, 
        setTimesheetHourDetailFormSubmit, 
        handleProjectOrActivtyChange,
        handleFundAndProjectChange,
        handleTimeTakenChange,
        handleDetailChange,
        hoursRemaining
        } = props;


    const itemUnitSchema = yup.object().shape({
        related_project_or_activity: yup.number().required("required"),
        fund_and_project: yup.string(),
        time_taken: yup.number().required("required"),
        detail: yup.string().required("required"),
    });
        

    const isNonMobile = useMediaQuery("(min-width:600px)");
    const token =  useSelector((state) => state.token);
    const [activityList, setActivityList] = useState([]);
    const [isLoadingActivityList, setIsLoadingActivityList] = useState(true);
    const [ActivityListError, setActivityListError] = useState(false);

    const fetchActivityList = async () => {
        try {
            const response = await odmasApi.get(`/timesheet/activities`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
            setActivityList(response.data.data);
            setIsLoadingActivityList(false);
        } catch (e) {
            setActivityListError(true);
            setIsLoadingActivityList(false);
        }
      };
   

    const initialValuesPurchase = {
        related_project_or_activity: "",
        fund_and_project:data.fund_and_project,
        time_taken: data.time_taken,
        detail: data.detail,
        };
    

    const handleFormSubmit = async (values, onSubmitProps) => {
        setTimesheetHourDetailFormSubmit(values);
       
    };

    return (
            
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesPurchase}
            validationSchema={itemUnitSchema}
            
        >
            {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue
            }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Box  py="5px">
                        <p >#{index+1}</p>
                    </Box>
                    <Box
                        display="grid"
                        gap="30px"
                        p="0.5rem"
                        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
                        }}
                    >
                        <>
                            <Autocomplete
                                onOpen={ ()=> {
                                    fetchActivityList();
                                }}
                                onBlur={handleBlur}
                                name="related_activity"
                                error={Boolean(touched.related_activity) && Boolean(errors.related_activity)}
                                helperText={touched.related_activity && errors.related_activity}
                                onChange={(e, value) => {
                                    setFieldValue("related_activity", value.id);
                                    handleProjectOrActivtyChange(e, index, value);
                                }}
                                sx={{ gridColumn: "span 3" }}
                                options={activityList}
                                getOptionLabel={(option) => option.display_name}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.display_name}
                                    </Box>
                                    )}
                                renderInput={(params) => <TextField 
                                                            {...params} 
                                                            inputProps={{
                                                                ...params.inputProps
                                                            }}
                                                            label="Related Activity"
                                                            value={values.related_activity}
                                                            onChange={(e)=>{
                                                                fetchActivityList();
                                                            }}
                                                                />}
                            />
                            <TextField
                                label="Donor"
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleFundAndProjectChange(e, index);
                                }}
                                value={values.fund_and_project}
                                name="fund_and_project"
                                error={Boolean(touched.fund_and_project) && Boolean(errors.fund_and_project)}
                                helperText={touched.fund_and_project && errors.fund_and_project}
                                sx={{ gridColumn: "span 2" }}
                            />  

                            <TextField
                                label="Hours Taken"
                                onBlur={handleBlur}
                                type="number"
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleTimeTakenChange(e, index)
                                }}
                                value={values.time_taken}
                                name="time_taken"
                                inputProps={{ 
                                    max:hoursRemaining,
                                    min: 0
                                 }}
                                error={Boolean(touched.time_taken) && Boolean(errors.time_taken)}
                                helperText={touched.time_taken && errors.time_taken}
                                sx={{ gridColumn: "span 3" }}
                            />

                            <TextField
                                label="Detail"
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                    handleChange(e);
                                    handleDetailChange(e, index);
                                }}
                                value={values.detail}
                                name="detail"
                                error={Boolean(touched.detail) && Boolean(errors.detail)}
                                helperText={touched.detail && errors.detail}
                                sx={{ gridColumn: "span 4" }}
                            />                                     
                            
                            <Button
                                value={index}
                                onClick={() => {
                                    handleRemoveTimeSheetRow(data.data_id);
                                }}                                                     
                                size="small"
                                variant="contained"
                                >
                                Remove
                            </Button>
                        </>
                    {/* )} */}
                    </Box>
                </Box>

                
            </form>
            )}
        </Formik>
                                
                            
           );
  };
  
  export default TimesheetHourDetailForm;
  