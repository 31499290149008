
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Autocomplete,
  Switch,
  FormControlLabel
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { odmasApi } from "api/pesabackend";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";

const itemSchema = yup.object().shape({
  project_name: yup.string().required("required"),
  project_number: yup.string(),
  related_tenant: yup.number().required("required"),
  donor: yup.string().required("required"),
  project_participant: yup.number().required("required"),
});


const ProjectCreateForm = (props) => {
  const pageType = props.formType;
  let {id} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);

  const [deparmentEmployeeList, setDeparmentEmployeeList] = useState([]);
  const [isLoadingDeparmentEmployeeList, setIsLoadingDeparmentEmployeeList] = useState(true);
  const [deparmentEmployeeListError, setDeparmentEmployeeListError] = useState(false);
    
  const fetchDepartmentEmployeeList = async () => {
      try {
          const response = await odmasApi.get(`/tenants/departments/employees?tenant_id=1`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setDeparmentEmployeeList(response.data.data);
          setIsLoadingDeparmentEmployeeList(false);
      } catch (e) {
          setDeparmentEmployeeListError(true);
          setIsLoadingDeparmentEmployeeList(false);
      }
  };
 

  const initialValuesItem = {
    project_name: "",
    project_number: "",
    related_tenant: 1,
    donor: "",
    project_participant:"",
    
  };


  

  const saveItem = async (values, onSubmitProps) => {

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/projects", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    props.setOpen(false);
    props.setNewItemData(true);
    onSubmitProps.resetForm();
    if (savedItem) {
      navigate("/setup");
      props.setIsSuccess(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Project Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.project_name}
              name="project_name"
              error={
                Boolean(touched.project_name) && Boolean(errors.project_name)
              }
              helperText={touched.project_name && errors.project_name}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Project Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.project_number}
              name="project_number"
              error={Boolean(touched.project_number) && Boolean(errors.project_number)}
              helperText={touched.project_number && errors.project_number}
              sx={{ gridColumn: "span 4" }}
            />

            <TextField
              label="Donor"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.donor}
              inputProps={{
                min: 0
              }}
              name="donor"
              error={Boolean(touched.donor) && Boolean(errors.donor)}
              helperText={touched.donor && errors.donor}
              sx={{ gridColumn: "span 2" }}
            />

            <Autocomplete
              onOpen={ ()=> {
                fetchDepartmentEmployeeList();
              }}
              onBlur={handleBlur}
              name="project_participant"
              error={Boolean(touched.project_participant) && Boolean(errors.project_participant)}
              helperText={touched.project_participant && errors.project_participant}
              onChange={(e, value) => {
                setFieldValue(
                  "project_participant",
                  value.id, 
                  
                );
              }}
              sx={{ gridColumn: "span 2" }}
              options={deparmentEmployeeList}
              getOptionLabel={(option) => option.related_user.first_name + " " + option.related_user.last_name }
              renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.related_user.first_name} {option.related_user.last_name}
                  </Box>
                )}
              renderInput={(params) => <TextField 
                                          {...params} 
                                          inputProps={{
                                              ...params.inputProps
                                          }}
                                          label="Project Manager"
                                          value={values.project_participant}
                                          onChange={(e)=>{
                                            fetchDepartmentEmployeeList(e.currentTarget.value);
                                          }}
                                            />}
            />
            
                    
                      

            {/* <TextField
              label="UEC"
              hidden="True"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.related_tenant}
              type="number"
              inputProps={{
                min: 0
              }}
              name="related_tenant"
              error={Boolean(touched.related_tenant) && Boolean(errors.related_tenant)}
              helperText={touched.related_tenant && errors.related_tenant}
              sx={{ gridColumn: "span 1" }}
            /> */}
                        
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ProjectCreateForm;
