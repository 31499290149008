import { Box, useTheme, Grid, Button, Paper, InputBase } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import UserCreateForm from "../../user/forms/userCreateForm";
import { useDepartmentEmployeesList} from "FetchData";
import { useState } from "react";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
const API_URL = process.env.REACT_APP_API_URL


export default function UserSetupTable ({setIsSuccess}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const tenantId = useSelector((state)=> state.employeeData.related_tenant.id);
  const {employeeList, isLoadingEmployeeList, setNewEmployeeData, setSearchEmployee} = useDepartmentEmployeesList(tenantId);
  
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState('');
  const [clickedItem, setClickedItem] = useState("");
  const[searchContent, setSearchContent] = useState("");
  const token =  useSelector((state) => state.token);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickView = async (e) => {
    const response = await axios.get(`${API_URL}/tenants/departments/employees/individual?employee_id=${e.target.value}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    navigate(`/userViewForm`, { state: response.data.data})
    
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(``);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await axios.post(`http://localhost:8000/inventory/products/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewEmployeeData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchEmployee(searchContent)
  };


  return (
    <Box>
        <h4>User List</h4>
        <Box
          p="1rem"
          m ="1rem"
          borderRadius="0.5rem"
          
        >
          <Grid container spacing={4} className="alignHorizantal">
            <Grid xs >
              <Paper
                sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
              >
                <InputBase placeholder="Search Username Name" 
                  value={searchContent}
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  
                />
                <IconButton
                  onClick={handleClickSearch}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid xs >
              {/* <NativeSelectDemo
                inputName = "Filter By"
                itemOptions={filterOptions}
              /> */}
              
            </Grid>
            <Grid xs sx={{ textAlign:'right' }}>
              <Button variant="contained" onClick={handleClickOpen}>Add User</Button>
              <ResponsiveDialog
              dialogButtonName = "Add User "
              dialogHeading = "Add a New User"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <UserCreateForm 
                              setOpen={setOpen} setNewEmployeeData={setNewEmployeeData} setIsSuccess={setIsSuccess}
                            />
                          </Box>
                        }
              >
                
              </ResponsiveDialog>
            </Grid>
          </Grid>

        </Box>
      
      <Box>
        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
              <TableHead>
                <TableRow>
                    <TableCell
                      style={{  minWidth: 50}}
                    >
                      No.
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 170 }}
                    >
                      First Name
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 170 }}
                    >
                      Last Name
                    </TableCell>
                    <TableCell
                      style={{  minWidth: 135 }}
                    >
                      Employee Role
                    </TableCell>

                  
                    <TableCell style={{ textAlign: "center" }}>
                      Actions
                    </TableCell>
                    
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeList.length===0 ? (
                  
                    <TableRow>
                      <TableCell colSpan={12} style={{ textAlign: "center" }}>
                        <h3>No Data found</h3>
                      </TableCell>
                    </TableRow>
                  
                ): (employeeList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        
                          <TableCell key={row.id}>
                            {row.id}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.related_user.first_name}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.related_user.last_name}
                          </TableCell>
                          <TableCell key={row.id}>
                            {row.related_role ? row.related_role.role_name : null}
                          </TableCell>
                          
                          
                        
                          <TableCell style={{ textAlign: "center" }}>
                              <Button value={row.id} size="small" 
                                onClick={(e) => handleClickView(e)}
                              >
                                View Details
                              </Button>

                              {/* <Button value={row.id} size="small" 
                                // onClick={(e) => handleClickDeleteOpen(e)}
                                 >Delete</Button> */}

                              {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                              <ResponsiveDialog
                                dialogHeading = "Edit"
                                open = {openEdit}
                                handleClose = {handleClickEditClose}
                                negativeResponse = "Cancel"
                                content = {
                                  <Box
                                    p="1rem"
                                    borderRadius="0.5rem"
                                    backgroundColor={theme.palette.background.alt}
                                  >
                                    {/* {<EditItemForm setOpen={setOpenEdit} setNewEmployeeData={setNewEmployeeData} id={clickedItem} editData={editData} setIsSuccess={setIsSuccess} />} */}
                                  </Box>
                                    
                                    }
                              />

                              <ResponsiveDialog
                                dialogHeading = "Are you sure you want to delete?"
                                open = {openDelete}
                                handleClose = {handleClickDeleteClose}
                                negativeResponse = "Cancel"
                                content = {
                                  <Box
                                    p="1rem"
                                    borderRadius="0.5rem"
                                    backgroundColor={theme.palette.background.alt}
                                  >
                                    {<Button
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      onClick={
                                        handleClickDelete
                                      }
                                      sx={{
                                        m: "1rem 0",
                                        p: "1rem",
                                      }}
                                    >
                                      Yes, Delete
                                    </Button>}
                                  </Box>
                                    
                                    }
                              />


                              {/* <ResponsiveDialog
                                  dialogHeading = "Are you sure you want to delete?"
                                  open = {openView}
                                  handleClose = {handleClickViewClose}
                                  negativeResponse = "Cancel"
                                  content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      {ViewContent}
                                    </Box>
                                      
                                      }
                                /> */}
                                
                            
                          </TableCell>
                        
                      </TableRow>
                    );
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={employeeList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      
      </Box>
    </Box>
  );
};