
import {Box,Button,TextField,useMediaQuery,MenuItem,useTheme,Alert} from "@mui/material";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProcurementRequestDetailForm from "./procurementRequisitionItemDetailForm";
import { useProjectCostCenterList, useActivityList, useCurrencyList } from "FetchData";
import {v4 as uuidv4} from 'uuid';
import {  useState, useEffect } from "react";
import moment from "moment/moment";
import { odmasApi } from "api/pesabackend";


const requisitionSchema = yup.object().shape({
  related_activity: yup.number(),
  cost_center: yup.number(),
  title: yup.string().required("required"),
  total_amount: yup.number().required("required"),
  date_created: yup.date().required("required"),
  date: yup.date().required("required"),
  currency_id: yup.number().required("required"),
  meta_fields: yup.array()
  .of(
    yup.object().shape({
      description: yup.string().required("required"),
      quantity: yup.number().required("required"),
      unit_of_measure: yup.string().required("required"),
      estimated_unit_cost: yup.number().required("required"),
      estimated_total_cost: yup.number().required("required"),
      })
  )
  .required('Please add requisition items')
  .min(1, 'At least on requisition item must be added'),
});



const ProcurementRequestCreateForm = () => {
  const [isSidebar, setIsSidebar] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const {projectId, relatedProjectDocument} = useParams();
  const tenantId = useSelector((state)=> state.employeeData.related_tenant.id); 
  const token =  useSelector((state) => state.token);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const {projectCostCenterList} = useProjectCostCenterList(projectId);
  const {activityList} = useActivityList();
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {currencyList} = useCurrencyList(tenantId);

  const userProjects = useSelector((state) => state.projectsAttached);
  const currentProject =userProjects.find(project=> project.related_project.id===Number(projectId))


  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false);
      setIsSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed]);

  


  const initialValues = {
    related_project_document: relatedProjectDocument,
    related_activity: "",
    title: "",
    total_amount: 0,
    date_created: moment().format('YYYY-MM-DD'),
    date: moment().format('YYYY-MM-DD'),
    cost_center:"",
    currency_id:"",
    meta_fields:[
      {
        quantity: 1,  unit_of_measure: '', estimated_unit_cost: 0,estimated_total_cost: 0,  description: "", data_id: uuidv4(),
      }
    ]
  };

  const[procurementRequestDetailFormData, setProcurementRequestDetailFormData] = useState([{
    quantity: 0,  unit_of_measure: '', estimated_unit_cost: 0,estimated_total_cost: 0,  description: "", data_id: uuidv4(),
  }]);

  const [grandTotal,setGrandTotal] = useState(initialValues.total_amount);
  
  const fetchRequisitionData = async (requestId) => {
  
    const response = await odmasApi.get(`projects/requests/details/${requestId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    navigate("/procurementRequisitionViewForm", { state: response.data.data })
  };


  const saveRequisition = async (values, onSubmitProps) => {

    const savedItemResponse = await fetch(`https://api.rectrack.iolabz.ug/api/v1/projects/requests/procurement_requisition`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      setIsSuccess(true);
      setTimeout(() => {
        onSubmitProps.resetForm();  
        fetchRequisitionData(savedItem.id);
      }, 1500);
    }; 
    if (savedItem.status===404){
      setIsFailedError(savedItem.errors)
      setIsFailed(true);
    };

  };


  const handleGrandTotal = (newArray) =>{
    const grandTotalCalculation = newArray.reduce((total_amount,newArray)=>{
      return Number(total_amount) + Number(newArray.estimated_total_cost)
    }, 0);
    setGrandTotal(grandTotalCalculation);
  };

  const handleQuantityChange = (event, index) =>{
    const {value} = event.target;
    const newArray = procurementRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, quantity: value}
        }
        return item
    })
    setProcurementRequestDetailFormData(newArray)
  };

  const handleUnitOfMeasureChange = (event, index) =>{
    const {value} = event.target;
    const newArray = procurementRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, unit_of_measure: value}
        }
        return item
    })
    setProcurementRequestDetailFormData(newArray)
  };

  const handleUnitCostChange = (event, index) =>{
    const {value} = event.target;
    const newArray = procurementRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, estimated_unit_cost: value}
        }
        return item
    })
    setProcurementRequestDetailFormData(newArray)
  };

  const handleTotalCostChange = (event, index) =>{
    const {value} = event.target;
    const newArray = procurementRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, estimated_total_cost: Number(value)}
        }
        return item
    })
    setProcurementRequestDetailFormData(newArray);
    handleGrandTotal(newArray);
  };

  
  const handleItemDescriptionChange = (event, index) =>{
  const {value} = event.target;
    const newArray = procurementRequestDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, description: value}
        }
        return item
    })
    setProcurementRequestDetailFormData(newArray);
  };

    

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
      quantity: 0,  unit_of_measure: '', estimated_unit_cost: 0,estimated_total_cost: 0,  description: "", data_id: uuidv4()
    };
    setProcurementRequestDetailFormData([...procurementRequestDetailFormData, oneRow])
  }; 


  const handleRemoveItemRow = (index) => {
    let newFormData = procurementRequestDetailFormData;
    newFormData.splice(index,1);
    setProcurementRequestDetailFormData(newFormData);
    handleGrandTotal(newFormData);      
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    await saveRequisition(values, onSubmitProps);
  };
  
  return (
      <Box>
          <Navbar />
          <Box 
              sx={{
                display:"flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
                <Box 
                  sx={{
                    flexGrow: 1
                  }}
                >
                    <Sidebar isSidebar={isSidebar}/>  
                </Box>
                <Box 
                  sx={{
                    flexGrow: 999,
                    paddingX: "40px"
                  }}
                >
                  {
                    isSuccess ? ( 
                        <Alert sx={{ m:"15px" }} variant="filled" severity="success">Procurement Requisition created successfully!</Alert>
                    ) : isFailed ? (
                        <Alert sx={{ m:"15px" }} variant="filled" severity="error">Operation was not successful!</Alert>
                    ) : null
                                                    
                  }
                  <h4>Procurement Requisition Create Form</h4>

                  <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        
                        initialValues={initialValues}
                        validationSchema={requisitionSchema}
                    >
                        {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        resetForm, 
                        isSubmitting, 
                        
                        }) => (
                        <form  onSubmit={handleSubmit}>
                          <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around"
                            }}
                            >
                            
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width:"40%"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                          
                                  <label>Date Created:  </label>
                                  <TextField
                                    label=""
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.date_created}
                                    disabled
                                    name="date_created"
                                    type="date_created"
                                    error={
                                    Boolean(touched.date_created) && Boolean(errors.date_created)
                                    }
                                    helperText={touched.date_created && errors.date_created}
                                    sx={{
                                      width: "100%",
                                      marginBottom: "15px",
                                    }}
                                  />
                              </Box>

                              <TextField
                                label="Subject of Procurement"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                multiline
                                rows={2}
                                value={values.title}
                                name="title"
                                error={
                                    Boolean(touched.title) && Boolean(errors.title)
                                }
                                helperText={touched.title && errors.title}
                                sx={{
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                              />

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                          
                                  <label>Date Required:  </label>
                                  <TextField
                                    label=""
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.date}
                                    name="date"
                                    type="date"
                                    error={
                                    Boolean(touched.date) && Boolean(errors.date)
                                    }
                                    helperText={touched.date && errors.date}
                                    sx={{
                                      width: "100%",
                                      marginBottom: "15px",
                                    }}
                                    inputProps={{ 
                                      min: moment().format('YYYY-MM-DD')
                                     }}
                                  />
                              </Box>
                              
                              
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width:"40%",
                                marginTop: "15px"
                              }}
                            >
                              <TextField
                                  label="Location for Delivery"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.location_for_delivery}
                                  name="location_for_delivery"
                                  error={Boolean(touched.location_for_delivery) && Boolean(errors.location_for_delivery)}
                                  helperText={touched.location_for_delivery && errors.location_for_delivery}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                              />
                                              
                              <TextField
                                  label="Related Activity"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.related_activity}
                                  name="related_activity"
                                  select
                                  error={Boolean(touched.related_activity) && Boolean(errors.related_activity)}
                                  helperText={touched.related_activity && errors.related_activity}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                              >
                                {activityList.map((activity, index)=>{
                                  return(
                                  <MenuItem key={index} value={activity.id}>{activity.activity_name}</MenuItem>
                                  );
                                })}
                              </TextField>

                              <TextField
                                label="Currency"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.currency_id}
                                name="currency_id"
                                select
                                error={Boolean(touched.currency_id) && Boolean(errors.currency_id)}
                                helperText={touched.currency_id && errors.currency_id}
                                sx={{
                                  width: "100%",
                                  marginBottom: "15px",
                                }}
                              >
                                {currencyList.map((currency, index)=>{
                                  return(
                                  <MenuItem key={index} value={currency.id}>{currency.currency_name}</MenuItem>
                                  );
                                })}
                              </TextField>

                              <TextField
                                  label="Cost Center"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.cost_center}
                                  name="cost_center"
                                  select
                                  error={Boolean(touched.cost_center) && Boolean(errors.cost_center)}
                                  helperText={touched.cost_center && errors.cost_center}
                                  sx={{
                                    width: "100%",
                                    marginBottom: "15px",
                                  }}
                              >
                                {projectCostCenterList.map((costCenter, index)=>{
                                  return(
                                  <MenuItem key={index} value={costCenter.id}>{costCenter.cost_center_name}</MenuItem>
                                  );
                                })}
                              </TextField>
                            

                            </Box>

                              

                          </Box>
                          <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          >
                            <h5>Input the requisition items here:</h5> 
                            
                            <FieldArray
                              name="meta_fields"
                              render={(arrayHelpers)=>{
                                const meta_fields = values.meta_fields
                              
                                return(
                                  <>
                                    {meta_fields.map((row, index) => {
                                      return (
                                        <ProcurementRequestDetailForm key={row.data_id}
                                          index={index}
                                          data={row}
                                          handleRemoveItemRow={(index)=>{
                                            arrayHelpers.remove(index);
                                            handleRemoveItemRow(index);
                                          }}                                        
                                          handleTotalCostChange = {handleTotalCostChange}
                                          handleChange={handleChange}
                                          errors={arrayHelpers.form.errors}
                                          touched={arrayHelpers.form.touched}
                                          name={`meta_fields[${index}]`}
                                          handleBlur={handleBlur}
                                        />
                                      )
                                    })}
                                    
                                    <Button
                                        onClick={()=>{
                                          arrayHelpers.push(
                                            {
                                              quantity: 1,  unit_of_measure: '', estimated_unit_cost: 0,estimated_total_cost: 0,  description: "", data_id: uuidv4(),
                                            }
                                          );
                                          handleAddProductDetailSubmitData();
                                        }}
                                        sx={{
                                          m: "1rem 0",
                                        }}
                                        size="small"
                                        variant="contained"
                                    >
                                        Add Item
                                    </Button>
                                      
                                  </>
                                )
                              }}
                            />
                              
                          </Box>  

                          <Box                         
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                mx: "2px"
                              }}
                            >
                              <label>Overal Total Estimated Cost:  </label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.total_amount = grandTotal}
                                name="total_amount"
                                disabled
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0
                                }}
                                error={
                                Boolean(touched.total_amount) && Boolean(errors.total_amount)
                                }
                                helperText={touched.total_amount && errors.total_amount}
                                sx={{
                                  // width: "100%",
                                  marginBottom: "15px",
                                }}
                              />
                            </Box>

                            
                              
                          </Box> 

                          {/* BUTTONS */}
                          <Box>
                            <Button
                                
                                type="submit"
                                disabled={isSubmitting}
                                variant="contained"
                                size="small"
                                sx={{
                                m: "1rem ",
                                p: "0.5rem",
                                }}
                            >
                                Save Requisition
                            </Button>
                            <Button
                                onClick={()=>{
                                  resetForm();
                                  navigate(`/projects/${projectId}`,{ state: { startTab:0, projectName: currentProject.related_project.project_name }});
                                }}
                                variant="contained"
                                color="error"
                                size="small"
                                sx={{
                                m: "1rem",
                                p: "0.5rem",
                                }}
                            >
                                Cancel
                            </Button>
                          </Box>
                        </form>
                        )}
                    </Formik>
                  </Box>
                </Box>
          </Box>
      </Box>
  );
};

export default ProcurementRequestCreateForm;
