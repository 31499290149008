
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { Formik } from "formik";
  import * as yup from "yup";
  import { useNavigate } from "react-router-dom";
  import { useSelector } from "react-redux";
  import moment from "moment";
  import DelegationDetailForm from "./delegationDetailForm";
  import {useState} from 'react';
  import {v4 as uuidv4} from 'uuid';
  
  const itemSchema = yup.object().shape({
    
    delegation_list: yup.array(),
  });
  
  
  const DelegationCreateForm = (props) => {
    let {leaveId, currentAttachedRoles } = props; 
    const { palette } = useTheme();
    const navigate = useNavigate();
    const theme = useTheme();
    const isNonMobile = useMediaQuery("(min-width:600px)");
  
    const token =  useSelector((state) => state.token);
  
    const initialValuesItem = {
      date: moment().format('YYYY-MM-DD'),
      delegation_list: currentAttachedRoles
    };

    const[delegationDetailFormData, setDelegationDetailFormData] = useState(initialValuesItem.delegation_list);

    const handleDelegatedToChange = (event, index) =>{
    const {value} = event.target;
    const newArray = delegationDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, delegated_to: value}
        }
        return item
    })
    setDelegationDetailFormData(newArray)
    };
  
    
    
  
    const saveItem = async (values, onSubmitProps) => {
      
  
      const savedItemResponse = await fetch("https://api.odms.iolabz.ug/api/v1/projects/requests/memos", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(values),
      });
      const savedItem = await savedItemResponse.json();
  
      
      if (savedItem.status===201) {
        props.setOpen(false);
        onSubmitProps.resetForm();
        props.setIsSuccess(true);
      }
    };
  
  
    
  
  
    const handleFormSubmit = async (values, onSubmitProps) => {
      saveItem(values, onSubmitProps);
    };
  
    return (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesItem }
        validationSchema={itemSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >

              
                <TextField
                    label="Date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    disabled
                    value={values.date}
                    name="date"
                    error={
                    Boolean(touched.date) && Boolean(errors.date)
                    }
                    helperText={touched.date && errors.date}
                    sx={{ gridColumn: "span 1" }}
                />
              
                <Box
                    gridColumn="span 4"
                    border={`1px solid ${palette.neutral.medium}`}
                    borderRadius="2px"
                    p="0.5rem"
                >

                    <p><b>Please Check Yes on all the actions you would like to delegate:</b></p>
        
                    {delegationDetailFormData.map((row, index) => {
                        return (
                        <DelegationDetailForm key={row.data_id}
                            index={index}
                            data={row}
                            setDelegationDetailFormData={setDelegationDetailFormData}
                            handleDelegatedToChange = {handleDelegatedToChange}
                        />
                        )
                        })}
  
                </Box>
        
  
        
        </Box>
  
        {/* BUTTONS */}
        <Box
          display="grid"
          gap="30px"
          p="0.5rem"
          gridTemplateColumns="repeat(12, minmax(0, 1fr))"
          sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
          }}
        >
  
          <Button
            fullWidth
            type="submit"
            size="small"
            sx={{
              m: "1rem 0",
              p: "1rem",
              backgroundColor: palette.primary.main,
              color: palette.background.alt,
              "&:hover": { color: palette.primary.main },
              gridColumn: "span 2" 
            }}
          >
            Save Delegation Settings
          </Button>
        </Box>
          </form>
        )}
      </Formik>
    );
  };
  
  export default DelegationCreateForm;
  