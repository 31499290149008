
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  MenuItem
} from "@mui/material";
import RequestDocumentApprovalDetailForm from "./requestDocumentApprovalDetailForm";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import { useDocumentDomainList, useDocumentTypeList, useDepartmentStationList, useProjectParticipantList, useDocumentApprovalSteps  } from "FetchData";
const API_URL = process.env.REACT_APP_API_URL;

const itemSchema = yup.object().shape({
  document_name: yup.string().required("required"),
  related_document_type: yup.string(),
  related_document_domain: yup.number().required("required"),
  related_region_project: yup.string().required("required"),
  order_number: yup.string().required("required"),
});


const CreateProjectRequestDocumentForm = (props) => {
  let {projectId} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);
  const tenantId = useSelector((state)=> state.employeeData.related_tenant.id);

  const {departmentStationList, isLoadingDepartmentStationList} = useDepartmentStationList(tenantId);
  const {documentDomainList, isLoadingDocumentDomainList} = useDocumentDomainList(tenantId);
  const {documentTypeList, isLoadingDocumentTypeList} = useDocumentTypeList(tenantId);
  const {projectParticipantList} = useProjectParticipantList(1,projectId);
  const {documentApprovalSteps} = useDocumentApprovalSteps(tenantId);
 

  const initialValuesItem = {
    document_name: "",
    related_document_type: "",
    related_document_domain: "",
    related_region_project: "",
    order_number:"",
    approval_steps_fields:[],
    related_project: projectId
    
  };

  const[requestDocumentApprovalDetailFormData, setRequestDocumentApprovalDetailFormData] = useState([{
    related_approval_step: "",  related_approver: '', data_id: uuidv4(),
  }]);

  const handleApprovalStepChange = (event, index) =>{
    const {value} = event.target;
    const newArray = requestDocumentApprovalDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_approval_step: value}
        }
        return item
    })
    setRequestDocumentApprovalDetailFormData(newArray)
  };

  const handleApproverChange = (event, index) =>{
    const {value} = event.target;
    const newArray = requestDocumentApprovalDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_approver: value}
        }
        return item
    })
    setRequestDocumentApprovalDetailFormData(newArray)
  };

  const handleAddProductDetailSubmitData = () => {
    const oneRow = {
      related_approval_step: '',  related_approver: '', data_id: uuidv4()
    };
    setRequestDocumentApprovalDetailFormData([...requestDocumentApprovalDetailFormData, oneRow])
  }; 


  const handleRemoveItemRow = (data_id) => {
    const newFormData = requestDocumentApprovalDetailFormData.filter((i) => i.data_id !== data_id)
    setRequestDocumentApprovalDetailFormData(newFormData);
  };


  

  const saveItem = async (values, onSubmitProps) => {

    values.approval_steps_fields = requestDocumentApprovalDetailFormData

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const savedItemResponse = await axios.post(`${API_URL}/tenants/project_documents`, JSON.stringify(values),{
      headers: {
        'Content-Type': 'application/json'
      }
    });

    
    if (savedItemResponse.status===201) {
      props.setOpen(false);
      props.setNewProjectDocumentRequestData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Document Type"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.related_document_type}
              name="related_document_type"
              error={Boolean(touched.related_document_type) && Boolean(errors.related_document_type)}
              helperText={touched.related_document_type && errors.related_document_type}
              sx={{ gridColumn: "span 1" }}
            >
              {documentTypeList.map((row, index)=>{
                return(
                  <MenuItem value={row.id}>{row.document_type_name}</MenuItem>
                )
              })}
            </TextField>

            <TextField
              label="Document Number"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.order_number}
              name="order_number"
              error={
                Boolean(touched.order_number) && Boolean(errors.order_number)
              }
              helperText={touched.order_number && errors.order_number}
              sx={{ gridColumn: "span 1" }}
            />
            
            <TextField
              label="Document Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.document_name}
              name="document_name"
              error={
                Boolean(touched.document_name) && Boolean(errors.document_name)
              }
              helperText={touched.document_name && errors.document_name}
              sx={{ gridColumn: "span 2" }}
            />
            

            <TextField
              label="Document Domain"
              hidden="True"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.related_document_domain}
              name="related_document_domain"
              error={Boolean(touched.related_document_domain) && Boolean(errors.related_document_domain)}
              helperText={touched.related_document_domain && errors.related_document_domain}
              sx={{ gridColumn: "span 2" }}
            >
              {documentDomainList.map((row, index)=>{
                return(
                  <MenuItem value={row.id}>{row.document_domain_name}</MenuItem>
                )
              })}
            </TextField>

            <TextField
              label="Document Station"
              onBlur={handleBlur}
              onChange={handleChange}
              select
              value={values.related_region_project}
              name="related_region_project"
              error={Boolean(touched.related_region_project) && Boolean(errors.related_region_project)}
              helperText={touched.related_region_project && errors.related_region_project}
              sx={{ gridColumn: "span 2" }}
            >
              {departmentStationList.map((row, index)=>{
                return(
                  <MenuItem value={row.id}>{row.station_name}</MenuItem>
                )
              })}
            </TextField>                   

            
                        
      </Box>
      <Box
                          gridColumn="span 4"
                          border={`1px solid ${palette.neutral.medium}`}
                          borderRadius="2px"
                          p="0.5rem"
                          marginTop="15px"
                          >
                              <h5>Input the approval steps in order of approval here:</h5> 
                              
                              {requestDocumentApprovalDetailFormData.map((row, index) => {
                                return (
                                <RequestDocumentApprovalDetailForm key={row.data_id}
                                  index={index}
                                  data={row}
                                  handleRemoveItemRow={handleRemoveItemRow}
                                  setRequestDocumentApprovalDetailFormData={setRequestDocumentApprovalDetailFormData}
                                  handleApprovalStepChange = {handleApprovalStepChange}
                                  handleApproverChange = {handleApproverChange}
                                  projectParticipantList={projectParticipantList}
                                  documentApprovalSteps={documentApprovalSteps}
                                  
                                />
                                  )
                                })}
                              
                                <Button
                                    onClick={()=>{
                                      handleAddProductDetailSubmitData();
                                    }}
                                    sx={{
                                      m: "1rem 0",
                                    }}
                                    size="small"
                                    variant="contained"
                                >
                                    Add Approval Step
                                </Button>
                              
                          </Box> 

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default CreateProjectRequestDocumentForm;
