import {
    Box,
    TextField,
    Grid,
    Button,
    Paper,
    useTheme
  } from "@mui/material";
  
  import Sidebar from "scenes/global/Sidebar";
  import Navbar from "scenes/navbar";
  import { useState } from "react";
  import moment from "moment/moment";
  import { useLocation } from "react-router-dom";
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableHead from '@mui/material/TableHead';
  import TableRow from '@mui/material/TableRow';
  import TableContainer from '@mui/material/TableContainer';
  import ResponsiveDialog from "components/dialog";
  import ApprovalBasisForm from "../../approvals/forms/approvalBasisForm";
  import { useUploadedDocumentList } from "FetchData";
 
 


  const MemoApprovalViewForm = () => {

    const [isSidebar, setIsSidebar] = useState(true);
    const location = useLocation();
    const theme = useTheme();
    
    const projectId = location.state.related_document_request.related_project;
    const date_created = moment(location.state.related_document_request.date_added).format('LL');
    const title= location.state.title;
    const background= location.state.background;
    const purpose=location.state.purpose;
    const related_project=location.state.related_document_request.related_project_document.related_project.project_name;
    const approval_steps = location.state.approval_steps;
    const currentApprovalStepDetail=approval_steps.filter(d => d.next_step===true);
    const to = location.state.to;
    const persons_through = location.state.meta_fields;
    const supportingDocuments = []
    const relatedDocumentRequest = location.state.related_document_request.id
    const {uploadedDocumentList, isLoadingUploadedDocumentList, setNewUploadedDocumentData, setSearchUploadedDocument} = useUploadedDocumentList(1,'project_request_document', relatedDocumentRequest)
  
    const [open, setOpen] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openDefer, setOpenDefer] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
          setOpen(false);
        };
    
    const handleClickOpenReject = () => {
      setOpenReject(true);
    };
    
    const handleCloseReject = () => {
          setOpenReject(false);
        };

    const handleClickOpenDefer = () => {
      setOpenDefer(true);
    };
    
    const handleCloseDefer = () => {
          setOpenDefer(false);
        };

    return (
      <Box>
        <Navbar />
        <Box 
          sx={{
            display:"flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
            <Box 
              sx={{
                flexGrow: 1
              }}
            >
                <Sidebar isSidebar={isSidebar}/>  
            </Box>
            <Box 
              sx={{
                flexGrow: 999,
                paddingX: "40px",
                marginX: "40px",
                justifyContent: "space-between"
              }}
            >
              <Grid container spacing={4} className="alignHorizantal">
                <Grid xs sx={{ mx: '4px'}} >
                <Paper 
                    sx={{ width: '100%',
                          padding: "40px",
                          margin: "40px",
                        }}
                    elevation={3}
                >
                  
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>REC-TRACK</h3 >
                    {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
                    {/* <h4 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h4 > */}
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>PROCUREMENT MEMO</h3 >

                    <p> <b>Date:</b> {date_created}</p>
                    <p> <b>To:</b> {to.related_user.first_name} {to.related_user.last_name}</p>
                    {!persons_through ? (
                              
                              null
                            
                          ): (persons_through
                            
                            .map((row, index) => {
                              return (
                                <p> <b>Thru:</b> {row.persons_through.related_project_role ? row.persons_through.related_project_rolerole_name : null}</p>
                              );
                            }))}
                    <p> <b>From:</b> Business Development Unit Coordinator</p>
                    <br/>
                    <p><b>Title:</b> { title}</p>
        
                    <Box
                      sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around"
                      }}
                      >
                      
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width:"100%",
                          marginBottom: "15px"
                        }}
                      >
                        <p>Background:</p>
                        {
                          background ?
                          (<TextField
                            label=""
                            value={background}
                            name="background"
                            sx={{
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />) : null
                        }
                        <p>Purpose:</p>
                        <TextField
                              label=""
                              multiline
                              
                              value={purpose}
                              name="purpose"
                              sx={{ 
                                width: "100%",
                                marginBottom: "15px",
                              }}
                          >
                        </TextField>
                        <Button 
                          // value={row.id} 
                          size="small" 
                          variant="contained"
                          sx={{ 
                            width: "20%",
                            marginBottom: "15px",
                          }}
                          // onClick={(e) => handleClickEditOpen(e)}
                        >
                          Print 
                        </Button>
                      </Box>
                      
                    </Box>
              
                  
                </Paper>
                </Grid> 
                <Grid xs sx={{ mx: '4px'}} >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width:"100%",
                      marginBottom: "15px"
                    }}
                  >
                    <Paper 
                        sx={{ width: '100%',
                              height: '50%',
                              padding: "10px",
                              margin: "40px",
                            }}
                        elevation={3}
                    >
                      
                      <h3 >Approval List</h3 >
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                          <TableHead>
                            <TableRow>
                                <TableCell
                                  style={{  minWidth: 50}}
                                >
                                  Approval Step
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  Approver Role
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 150 }}
                                >
                                  Approver 
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  Status
                                </TableCell>
                                
                                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!approval_steps ? (
                              
                                <TableRow>
                                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                    <h3>No Data found</h3>
                                  </TableCell>
                                </TableRow>
                              
                            ): (approval_steps
                              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                if (row.status==! 0 || row.next_step === true) {
                                  return (
                                    <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                      
                                        <TableCell key={row.id}>
                                          {row.related_document_approval_step.approval_step_name}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.generic_role_name}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {
                                            row.status === 0 ? ("PENDING") 
                                            :
                                            row.status === 1 ? ("APPROVED") 
                                            :
                                            row.status === 2 ? ("REJECTED") 
                                            :
                                            row.status === 3 ? ("DEFERRED") 
                                            :
                                            null 
                                          }
                                        </TableCell>
                                      
                                    </TableRow>
                                  );
                                }
                              }))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                                
                    </Paper>

                    <Paper 
                        sx={{ width: '100%',
                              height: '50%',
                              padding: "10px",
                              margin: "40px",
                            }}
                        elevation={3}
                    >
                      
                      <h3 >Comments</h3 >
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                          <TableHead>
                            <TableRow>
                                <TableCell
                                  style={{  minWidth: 50}}
                                >
                                  No.
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  COMMENT
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 150 }}
                                >
                                  CREATED BY
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  DATE CREATED
                                </TableCell>
                                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!approval_steps ? (
                              
                                <TableRow>
                                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                    <h3>No Data found</h3>
                                  </TableCell>
                                </TableRow>
                              
                            ): (approval_steps
                              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                if (row.approval_comment !== null) {
                                  return (
                                    <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                  
                                        <TableCell key={row.id}>
                                          {index + 1}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.approval_comment}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {moment(row.date_performed).format('L')}
                                        </TableCell>
                                      
                                    </TableRow>)
                                }  
                              }))}
                          </TableBody>
                        </Table>
                      </TableContainer>                  
                      
                    </Paper>

                    <Paper 
                        sx={{ width: '100%',
                              height: '50%',
                              padding: "10px",
                              margin: "40px",
                            }}
                        elevation={3}
                    >
                      
                      <Box
                        sx={{ 
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: "10px",
                          }}
                      >
                        <h3 >Supporting Documents</h3 >
                      
                      </Box>
                      
                        
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                          <TableHead>
                            <TableRow>
                                <TableCell
                                  style={{  width: 20}}
                                >
                                  No.
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  Date Uploaded
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 75 }}
                                >
                                  File Name
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 150 }}
                                >
                                  Description
                                </TableCell>
                                <TableCell
                                  style={{  minWidth: 50 }}
                                >
                                  Actions
                                </TableCell>
                                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {uploadedDocumentList.length===0 ? (
                              
                                <TableRow>
                                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                    <h3>No Data found</h3>
                                  </TableCell>
                                </TableRow>
                              
                            ): (uploadedDocumentList
                              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                return (
                                  <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                    
                                      <TableCell key={row.id}>
                                        {row.id}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {moment(row.date_added).format('L')}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.file_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.description}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        <Button 
                                          value={row.id} 
                                          size="small" 
                                          // onClick={(e) => handleClickEditOpen(e)}
                                        >
                                          Download 
                                        </Button>
                                      </TableCell>
                                    
                                  </TableRow>
                                );
                              }))}
                          </TableBody>
                        </Table>
                      </TableContainer>                     
                      
                    </Paper>
                    <Box
                      sx={{
                        display:"flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        padding: "10px",
                        marginX: "40px",
                      }}
                    >
                      <Button 
                        variant="contained"  
                        color="success"
                        onClick={handleClickOpen}
                        sx={{marginX:"5px"}}
                        
                      >
                        {currentApprovalStepDetail[0].related_document_approval_step.approval_step_name}
                      </Button>

                      <Button 
                        variant="contained"  
                        color="error"
                        onClick={handleClickOpenReject}
                        sx={{marginX:"5px"}}
                        
                      >
                        REJECT
                      </Button>
                      <Button 
                        variant="contained"  
                        onClick={handleClickOpenDefer}
                        sx={{marginX:"5px"}}
                        
                      >
                        DEFFER
                      </Button>
                    </Box>
                    <ResponsiveDialog
                      dialogButtonName = "Add Comment "
                      dialogHeading = "Add Supporting Comment"
                      open = {open}
                      handleClickOpen = {handleClickOpen}
                      handleClose = {handleClose}
                      negativeResponse = "Cancel"
                      content = {
                                  <Box
                                    p="1rem"
                                    borderRadius="0.5rem"
                                    backgroundColor={theme.palette.background.alt}
                                  >
                                    <ApprovalBasisForm setOpen={setOpen} isDeffered={false} approvalStepDetail={currentApprovalStepDetail[0]} relatedDocumentRequest={relatedDocumentRequest} status={1} commentName={currentApprovalStepDetail[0].related_document_approval_step.approval_step_name} />
                                  </Box>
                                }
                    >
                      
                    </ResponsiveDialog>

                    <ResponsiveDialog
                      dialogButtonName = "Add Comment "
                      dialogHeading = "Add Supporting Comment for Rejection"
                      open = {openReject}
                      handleClickOpen = {handleClickOpenReject}
                      handleClose = {handleCloseReject}
                      negativeResponse = "Cancel"
                      content = {
                                  <Box
                                    p="1rem"
                                    borderRadius="0.5rem"
                                    backgroundColor={theme.palette.background.alt}
                                  >
                                    <ApprovalBasisForm setOpenReject={setOpenReject} isDeffered={false} approvalStepDetail={currentApprovalStepDetail[0]} relatedDocumentRequest={relatedDocumentRequest} status={3} commentName={"REJECTION"} />
                                  </Box>
                                }
                    >
                      
                    </ResponsiveDialog>

                    <ResponsiveDialog
                      dialogButtonName = "Add Comment "
                      dialogHeading = "Add Reason for Deffering"
                      open = {openDefer}
                      handleClickOpen = {handleClickOpenDefer}
                      handleClose = {handleCloseDefer}
                      negativeResponse = "Cancel"
                      content = {
                                  <Box
                                    p="1rem"
                                    borderRadius="0.5rem"
                                    backgroundColor={theme.palette.background.alt}
                                  >
                                    <ApprovalBasisForm setOpenDefer={setOpenDefer} isDeffered={true} approvalStepDetail={currentApprovalStepDetail[0]} relatedDocumentRequest={relatedDocumentRequest} status={2} commentName={"DEFFERRING"} />
                                  </Box>
                                }
                    >
                      
                    </ResponsiveDialog>

                    
                  </Box>
                </Grid> 
              </Grid>  
                

            </Box>
        </Box>
      </Box>                
                  
    );

  }

  export default MemoApprovalViewForm;