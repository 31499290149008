
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  
  useTheme,
  MenuItem
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { odmasApi } from "api/pesabackend";
import { useProjectRoleList } from "FetchData";


const itemSchema = yup.object().shape({
  related_project: yup.number(),
  role_name: yup.string().required("required"),
  reports_to: yup.number().required("required"),
  
});


const ProjectRoleCreateForm = (props) => {
  const pageType = props.formType;
  let {projectId} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);
  const {projectRoleList, isLoadingProjectRoleList, setNewProjectRoleData, setSearchProjectRole} = useProjectRoleList(projectId)


  const initialValuesItem = {
    role_name: "",
    reports_to: "",
  };
  

  const saveItem = async (values, onSubmitProps) => {
    values.related_project = projectId;

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/projects/roles", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` 
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem) {
      props.setOpen(false);
      props.setNewProjectRoleData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Role Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.role_name}
              name="role_name"
              error={Boolean(touched.role_name) && Boolean(errors.role_name)}
              helperText={touched.role_name && errors.role_name}
              sx={{ gridColumn: "span 2" }}
            />


            <TextField
              label="Reports To"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.reports_to}
              select
              name="reports_to"
              error={Boolean(touched.reports_to) && Boolean(errors.reports_to)}
              helperText={touched.reports_to && errors.reports_to}
              sx={{ gridColumn: "span 2" }}
            >
              {projectRoleList.map((row, index) => { 
                return ( 
                  <MenuItem  value={row.id}>{row.role_name}</MenuItem>
                ) 
              })}
            </TextField>
           
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ProjectRoleCreateForm;
