
import {
    Box,
    Button,
    useMediaQuery,
    useTheme,
    Alert
  } from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ViewFormTemplate from "components/viewFormTemplate";
import ResponsiveDialog from "components/dialog";
import TimesheetCreateForm from "./timesheetCreateForm";
import {  useState, useEffect } from "react";
import { odmasApi } from "api/pesabackend";
import moment from "moment/moment";
import {v4 as uuidv4} from 'uuid';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import { useTimeSheetList} from "FetchData";
  
const TimesheetCreatCalendarInputForm = (props) => {
    let {id, } = props; 
    const theme = useTheme();
    const userId = useSelector((state) => state.user.id);
    const { palette } = useTheme();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const {timeSheetList, isLoadingTimeSheetList, setNewTimeSheetData, setSearchTimeSheetMonthYear} = useTimeSheetList(userId);
    const [open, setOpen] = useState(false);
    const [dateSelected, setDateSelected] = useState('');
    const [clockingData, setClockingData] = useState({});
    const [isLoadingClockingData, setIsLoadingClockingData] = useState(true);
    const [clockingDataError, setClockingDataError] = useState(false);
    const [isFailedError, setIsFailedError] = useState("");
    const [isFailed, setIsFailed]  = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [hoursRecorded,setHoursRecorded] = useState(0);
    const [hoursSelected,setHoursSelected] = useState(0);
  
    const token =  useSelector((state) => state.token);

    useEffect(() =>{
      const timer = setTimeout(() => {
        setIsFailed(false)
      }, 4000);
      return () => clearTimeout(timer);
    }, [isFailed]);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const initialValuesItem = {
      related_user: userId,
      related_clock_in:'',
      timesheet_records:[]
    };

    const event=[];
    if (timeSheetList.length!==0){timeSheetList.timesheets.forEach(element => {
      event.push({
        title: (element.clock_in===true && element.timesheet===false)? "Clocked In" : (element.clock_in===true && element.timesheet!==false) ? "TimeSheet Submitted" : "Has No Clock In",
        start:moment(element.day).format('YYYY-MM-DD'),
        
        })
    });}

    const [timeSheetRecords, setTimeSheetRecords] = useState([{related_project_or_activity: '', detail: '', fund_and_project: '', time_taken:'', data_id: uuidv4()}]);

    const handleHoursRecorded = (newArray) =>{
      const grandTotalCalculation = newArray.reduce((total,newArray)=>{
        return Number(total) + Number(newArray.time_taken)
      }, 0);
      setHoursRecorded(grandTotalCalculation);
    };

    const handleAddTimeSheetSubmitData = () => {
      const oneRow = {
        related_project_or_activity: '', detail: '', fund_and_project: '', time_taken:'', data_id: uuidv4()
      };
      setTimeSheetRecords([...timeSheetRecords, oneRow])
    }; 
  
  
    const handleRemoveTimeSheetRow = (data_id) => {
      const newFormData = timeSheetRecords.filter((i) => i.data_id !== data_id)
      setTimeSheetRecords(newFormData);
      handleHoursRecorded(newFormData);      
    };
  
    const handleHoursChange = (event, index) =>{
      const {value} = event.target;
      const newArray = timeSheetRecords.map((item, idx) => {
          if (idx === index) {
              return {...item, time_taken: value}
          }
          return item
      })
      setTimeSheetRecords(newArray)
    };


    const handleDetailChange = (event, index) =>{
      const {value} = event.target;
      const newArray = timeSheetRecords.map((item, idx) => {
          if (idx === index) {
              return {...item, detail: value}
          }
          return item
      })
      setTimeSheetRecords(newArray)
    };

    const calculateHoursSelected = (selectedTime) => {
      const startTime = moment.duration(moment(selectedTime.startStr).format('HH:mm:ss')).asHours();
      const endTime = moment.duration(moment(selectedTime.endStr).format('HH:mm:ss')).asHours();
      const duration = (endTime-startTime);
      setHoursSelected(duration);
    };

  
    const fetchClockingData = async (clockingDate) => {
        
      const response = await fetch("https://api.rectrack.iolabz.ug/api/v1/timesheet/clocking", {
        method: "POST",
        headers: { 
          "Content-Type": "application/json", 
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(
          {
            "date": `${clockingDate}`
          }
        ),
        });
        
      const dataReturned = await response.json();

      if (dataReturned.status===200) {
        setClockingData(dataReturned.data);
        setIsLoadingClockingData(false);
      } else {
        setOpen(false);
        setIsFailedError(dataReturned.error)
        setIsFailed(true);
        setClockingDataError(true);
        setIsLoadingClockingData(false);
        }
    };
  
    return (
                
      <>
          {
            isSuccess ? ( 
                <Alert severity="success">Operation was successful!</Alert>
            ) : isFailed ? (
                <Alert severity="error">{isFailedError}</Alert>
            ) : null
                                            
          }
          <p>*Click on a date to input you timesheet*</p>
          <FullCalendar
              plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin ]}
              initialView="dayGridMonth"
              headerToolbar={{ 
              start:"",
              center:"title",
              end:"today prev next"
              }}
              selectable="true"
              height={"80vh"}
              events={event}
              select={ (start, end) => {
                setDateSelected(moment(start.startStr).format('YYYY-MM-DD'));
                fetchClockingData(moment(start.startStr).format('YYYY-MM-DD'));
                calculateHoursSelected(start);
                setOpen(true);
              }
              }
              
          />
          
          {/* BUTTONS */}
          <Box
            display="grid"
            gap="30px"
            p="0.5rem"
            gridTemplateColumns="repeat(12, minmax(0, 1fr))"
            sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 10" },
            }}
            >
            
            <ResponsiveDialog
              dialogButtonName = "Enter Timesheet"
              dialogHeading = "Enter Timesheet Hours"
              open = {open}
              handleClickOpen = {handleClickOpen}
              handleClose = {handleClose}
              negativeResponse = "Cancel"
              content = {
                          <Box
                            p="1rem"
                            borderRadius="0.5rem"
                            backgroundColor={theme.palette.background.alt}
                          >
                            <TimesheetCreateForm
                              setOpen={setOpen} clockingId={clockingData.id} clockIn={clockingData.clock_in_time} clockOut={clockingData.clock_out_time} hoursClocked={clockingData.hours} dateSelected={dateSelected} hoursRecorded={hoursRecorded} handleDetailChange={handleDetailChange} handleHoursChange={handleHoursChange} hoursSelected={hoursSelected} setIsSuccess={setIsSuccess}
                            />
                          </Box>
                        }
            >
              
            </ResponsiveDialog>
            

            {/* <Button
                fullWidth
                type="submit"
                size="small"
                sx={{
                m: "5px 0",
                p: "10px",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
                gridColumn: "span 3" 
                }}
            >
                Save Timesheet Record
            </Button> */}
          </Box>
      </>
                    
         
  
      
    );
  };
  
  export default TimesheetCreatCalendarInputForm;
  