import { Box, useTheme, Grid, Button, Paper, InputBase, Alert } from "@mui/material";
import ResponsiveDialog from "components/dialog";
import ColumnGroupingTable from "components/normalTable";
import { useNavigate } from "react-router-dom";


import TaskCreateForm from "../forms/taskCreateForm";
import { useTaskList} from "FetchData";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";

import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

const columns = [
      { id: 'id', label: 'No.', minWidth: 50 },
      { id: 'task_name', label: 'Task Name', minWidth: 170 },
      { id: 'start_date', label: 'Start Date', minWidth: 200 },
      { id: 'end_date', label: 'End Date', minWidth: 135 },
    ];

    const filterOptions = [
      {name:'Supplier'},
    ]

export default function ActiviyTasksTable () {
  const theme = useTheme();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const activityName = location.state.activity_name;
  const activityId = location.state.id;
  const startDate = location.state.start_date;
  const endDate = location.state.end_date;
  const hasReport = location.state.has_report;
  const recurringFrequency = location.state.recurring_frequency;

  const {taskList, isLoadingTaskList, setNewTaskData, setSearchTask} = useTaskList(1, activityId);
  
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState('');
  const [clickedItem, setClickedItem] = useState("");
  const[searchContent, setSearchContent] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false);
      setIsSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed, isSuccess]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = () => {
        setOpen(true);
      };
    
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`http://localhost:8000/task/get/${e.target.value}`);
    setEditData(response.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(`http://localhost:8000/task/get/${e.target.value}`);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await axios.post(`http://localhost:8000/task/delete/${clickedItem}`);
    setIsSuccess(true)
    setNewTaskData(true)
    setOpenDelete(false);
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {
    setSearchTask(searchContent)
  };


  return (
    <Box>
      <Navbar />
      <Box 
          sx={{
            display:"flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <Box 
            sx={{
              flexGrow: 1
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box
            sx={{
              flexGrow: 999,
              paddingX: "40px"
            }}
          >
             {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="filled" severity="success">Operation was successful!</Alert>
                ) : null
                                                
              }
            <Paper
              sx={{ 
                padding:"10px",
                marginTop:"10px"
               }}
            >
              <h2>{activityName}</h2>
              <p><b>From:</b> {startDate}    -    <b>To:</b> {endDate}   </p>
              <p>Description</p>
              
              {hasReport ? (<Button
                variant="outlined"
              >
                ATTACH REPORT
              </Button>) : null}

            </Paper>
            <br/>
            <hr/>

            <h3>Activity Task List</h3>
            <Box
              p="1rem"
              m ="1rem"
              borderRadius="0.5rem"
              
            >
              <Grid container spacing={4} className="alignHorizantal">
                <Grid xs >
                  <Paper
                    sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
                  >
                    <InputBase placeholder="Search Task Name" 
                      value={searchContent}
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      
                    />
                    <IconButton
                      onClick={handleClickSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid xs >
                  {/* <NativeSelectDemo
                    inputName = "Filter By"
                    itemOptions={filterOptions}
                  /> */}
                  
                </Grid>
                <Grid xs sx={{ textAlign:'right' }}>
                  <Button variant="contained" onClick={handleClickOpen}>Add Task</Button>
                  <ResponsiveDialog
                  dialogButtonName = "Add Task "
                  dialogHeading = "Add a New Task"
                  open = {open}
                  handleClickOpen = {handleClickOpen}
                  handleClose = {handleClose}
                  negativeResponse = "Cancel"
                  content = {
                              <Box
                                p="1rem"
                                borderRadius="0.5rem"
                                backgroundColor={theme.palette.background.alt}
                              >
                                {
                                  isFailed ? (
                                      <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
                                  ) : null
                                                                  
                                }
                                <TaskCreateForm 
                                  setOpen={setOpen} setNewTaskData={setNewTaskData} setIsSuccess={setIsSuccess} setIsFailed={setIsFailed} setIsFailedError={setIsFailedError} activityId={activityId}
                                />
                              </Box>
                            }
                  >
                    
                  </ResponsiveDialog>
                </Grid>
              </Grid>

            </Box>
            
            <Box>
              <Paper sx={{ width: '100%' }}>
                <TableContainer sx={{ maxHeight: "100vh" }}>
                  <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                    <TableHead>
                      <TableRow>
                      <TableCell
                            style={{  minWidth: 50}}
                          >
                            No.
                      </TableCell>

                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Task Name
                      </TableCell>

                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        Related Activity
                      </TableCell>

                      <TableCell
                        style={{  minWidth: 150 }}
                      >
                        Start Date
                      </TableCell>

                      <TableCell
                        style={{  minWidth: 50 }}
                      >
                        End Date
                      </TableCell>
                
                      <TableCell style={{ textAlign: "center", minWidth: 150  }}>
                        Actions
                      </TableCell>
                          
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taskList.length===0 ? (
                        
                          <TableRow>
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>
                              <h3>No Data found</h3>
                            </TableCell>
                          </TableRow>
                        
                      ): (taskList
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              
                              <TableCell key={row.id}>
                                {index + 1}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.task_name}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.related_activity.activity_name}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.start_date}
                              </TableCell>
                              <TableCell key={row.id}>
                                {row.end_date}
                              </TableCell>
                                                    
                                
                              
                                <TableCell style={{ textAlign: "center" }}>
                                    <Button value={row.id} size="small" 
                                      // onClick={(e) => handleClickEditOpen(e)}
                                      >Edit</Button>

                                    {/* <Button value={row.id} size="small" onClick={(e) => handleClickDeleteOpen(e)} >Delete</Button> */}

                                    {/* <Button value={row.id} size="small" onClick={(e) => handleClickView(e)} >{viewButtonName}</Button> */}

                                    <ResponsiveDialog
                                      dialogHeading = "Clock Out"
                                      open = {openEdit}
                                      handleClose = {handleClickEditClose}
                                      negativeResponse = "Cancel"
                                      content = {
                                        <Box
                                          p="1rem"
                                          borderRadius="0.5rem"
                                          backgroundColor={theme.palette.background.alt}
                                        >
                                          {/* {<EditItemForm setOpen={setOpenEdit} setNewTaskData={setNewTaskData} id={clickedItem} editData={editData} setIsSuccess={setIsSuccess} />} */}
                                        </Box>
                                          
                                          }
                                    />

                                    {/* <ResponsiveDialog
                                      dialogHeading = "Are you sure you want to delete?"
                                      open = {openDelete}
                                      handleClose = {handleClickDeleteClose}
                                      negativeResponse = "Cancel"
                                      content = {
                                        <Box
                                          p="1rem"
                                          borderRadius="0.5rem"
                                          backgroundColor={theme.palette.background.alt}
                                        >
                                          {<Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            onClick={
                                              handleClickDelete
                                            }
                                            sx={{
                                              m: "1rem 0",
                                              p: "1rem",
                                            }}
                                          >
                                            Yes, Delete
                                          </Button>}
                                        </Box>
                                          
                                          }
                                    /> */}


                                    {/* <ResponsiveDialog
                                        dialogHeading = "Are you sure you want to delete?"
                                        open = {openView}
                                        handleClose = {handleClickViewClose}
                                        negativeResponse = "Cancel"
                                        content = {
                                          <Box
                                            p="1rem"
                                            borderRadius="0.5rem"
                                            backgroundColor={theme.palette.background.alt}
                                          >
                                            {ViewContent}
                                          </Box>
                                            
                                            }
                                      /> */}
                                      
                                  
                                </TableCell>
                              
                            </TableRow>
                          );
                        }))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={taskList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </Box>
      </Box>
    </Box>
  );
};