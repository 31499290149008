
import {Box,Button,TextField,useMediaQuery,useTheme} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProjectsAttchedDetailForm from "./projectsAttachedDetailForm";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import { useProjectList, useDepartmentStationList, useDepartmentRoleList } from "FetchData";

const itemSchema = yup.object().shape({
  projects_attached: yup.array(),
  effort_sum: yup.number().max(100, 'You have exceeded the 100% effort limit').min(100, 'Effort cannot be less than 100%')
  
});


const EditProjectsAssignedForm = (props) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const {projectList, isLoadingProjectList} = useProjectList(1)
  const token =  useSelector((state) => state.token);
  const {related_tenant, related_department} = useSelector((state)=>state.employeeData);
  const {departmentStationList, isLoadingDepartmentStationList} = useDepartmentStationList(related_tenant.id)
  const [totalEffort,setTotalEffort] = useState(100);

  const initialValuesItem = {
    projects_attached:[],
    effort_sum: 0
  };
  

  const[projectsAttachedDetailFormData, setProjectsAttachedDetailFormData] = useState(props.projectsAttached);
  
  const handleCalculateTotalEffort = (newArray) =>{
    const totalEffortCalculation = newArray.reduce((total,newArray)=>{
      return Number(total) + Number(newArray.level_of_effort)
    }, 0);
    setTotalEffort(totalEffortCalculation);
  };

  const handleProjectChange = (event, index) =>{
    const {value} = event.target;
    const newArray = projectsAttachedDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_project: value}
        }
        return item
    })
    setProjectsAttachedDetailFormData(newArray)
  };

  const handleProjectRoleChange = (event, index) =>{
    const {value} = event.target;
    const newArray = projectsAttachedDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_project_role: Number(value)}
        }
        return item
    })
    setProjectsAttachedDetailFormData(newArray)
  };

  const handleLevelOfEffortChange = (event, index) =>{
    const {value} = event.target;
    const newArray = projectsAttachedDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, level_of_effort: Number(value)}
        }
        return item
    })
    setProjectsAttachedDetailFormData(newArray);
    handleCalculateTotalEffort(newArray);
  };

  const handleProjectStationChange = (event, index) =>{
    const {value} = event.target;
    const newArray = projectsAttachedDetailFormData.map((item, idx) => {
        if (idx === index) {
            return {...item, related_station: value}
        }
        return item
    })
    setProjectsAttachedDetailFormData(newArray)
  };

  const handleAddProjectsAttachedDetailSubmitData = () => {
    const oneRow = {
      related_project: '', related_project_role: '', related_station: '', level_of_effort: '',  data_id: uuidv4(),
    };
    setProjectsAttachedDetailFormData([...projectsAttachedDetailFormData, oneRow])
  }; 


  const handleRemoveItemRow = (data_id) => {
    const newFormData = projectsAttachedDetailFormData.filter((i) => i.data_id !== data_id)
    setProjectsAttachedDetailFormData(newFormData);    
    handleCalculateTotalEffort(newFormData);
  };
  

  const saveItem = async (values, onSubmitProps) => {

    values.related_department = related_department.id
    values.related_tenant = related_tenant.id
    values.projects_attached = projectsAttachedDetailFormData

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/tenants/departments/employees", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` 
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem) {
      props.setOpen(false);
      props.setNewEmployeeData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
          

            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <h5>Select the projects the user is attached to and input their details:</h5>

              {projectsAttachedDetailFormData.map((row, index) => {
                return (
                  <ProjectsAttchedDetailForm key={row.data_id}
                    index={index}
                    data={row}
                    token={token}
                    handleRemoveItemRow={handleRemoveItemRow}
                    setProjectsAttachedDetailFormData={setProjectsAttachedDetailFormData}
                    handleProjectChange = {handleProjectChange}
                    handleProjectStationChange = {handleProjectStationChange}
                    handleProjectRoleChange = {handleProjectRoleChange}
                    handleLevelOfEffortChange = {handleLevelOfEffortChange}
                    projectList={projectList}
                    departmentStationList={departmentStationList}
                  />
                )
              })}

              {totalEffort > 100 ? (<p style={{ color:"red" }}>* Please input values that do not exceed 100%</p>): null}

              <Button
                onClick={()=>{
                  handleAddProjectsAttachedDetailSubmitData();
                }}
                sx={{ 
                  // width: "20%",
                  my: "15px",
                }}
                size="small"
                variant="contained"
              >
                Add Project
              </Button>
            </Box>
      </Box>

      <Box                         
                            border={`1px solid ${palette.neutral.medium}`}
                            borderRadius="2px"
                            p="0.5rem"
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                mx: "2px"
                              }}
                            >
                              <label>Total Effort:  </label>
                              <TextField
                                label=""
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.effort_sum = totalEffort}
                                name="effort_sum"
                                disabled
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0
                                }}
                                error={
                                Boolean(touched.effort_sum) && Boolean(errors.effort_sum)
                                }
                                helperText={touched.effort_sum && errors.effort_sum}
                                sx={{
                                  // width: "100%",
                                  marginBottom: "15px",
                                }}
                              />
                            </Box>

                            
                              
                          </Box> 

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          size="small"
          type="submit"
          sx={{
            m: "10px 0",
            p: "10px",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
            width: "20%",
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default EditProjectsAssignedForm;
