import {
  Box,
  TextField,
  Alert,
  Paper,
  Button,
  Grid,
  useTheme
} from "@mui/material";

import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import { useState } from "react";
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveDialog from "components/dialog";
import ProcurementRequisitionPrintPreviewForm from "./procurementRequestPrintPreviewForm";
import DocumentUploadForm from "scenes/global/documentUploadForm";
import { useUploadedDocumentList } from "FetchData";



const ProcurementRequisitionViewForm = () => {

  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const theme = useTheme();

  const projectId = location.state.related_document_request.related_project;
  const date_created = moment(location.state.related_document_request.date_added).format('LL');
  const title= location.state.title;
  const activity= location.state.related_activity.activity_name;
  const purpose=location.state.purpose;
  // const related_tenant= location.state.related_tenant.tenant_name;
  // const related_tenant_department= location.state.related_tenant_department.department_name;
  const related_project=location.state.related_document_request.related_project_document.related_project.project_name;
  const relatedDocumentRequest = location.state.related_document_request.id
  const currentApprovalStep = []
  const approval_steps = location.state.approval_steps;
  const to = location.state.to;
  const meta_fields = location.state.meta_fields;
  const supportingDocuments = []
  const cost_center = location.state.cost_center.cost_center_name
  const budget_category = location.state.budget_category
  const added_by = location.state.related_document_request.added_by.first_name + ' ' + location.state.related_document_request.added_by.last_name
  const {uploadedDocumentList, isLoadingUploadedDocumentList, setNewUploadedDocumentData, setSearchUploadedDocument} = useUploadedDocumentList(1,'project_request_document', relatedDocumentRequest)
  
  const [open, setOpen] = useState(false);
  const [openDocumentUpload, setOpenDocumentUpload] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
        setOpen(false);
      };
  
  const handleOpenDocumentUpload = () => {
    setOpenDocumentUpload(true);
  };
  
  const handleCloseDocumentUpload = () => {
        setOpenDocumentUpload(false);
      };
  
 

  return (
    <Box>
      <Navbar />
      <Box 
        sx={{
          display:"flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
          <Box 
            sx={{
              flexGrow: 1
            }}
          >
              <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999,
              paddingX: "40px",
              marginX: "40px",
              justifyContent: "space-between"
            }}
          >
            {
              isSuccess ? ( 
                  <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
              ) : isFailed ? (
                  <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
              ) : null                                  
            }
            
            <Grid container spacing={4} className="alignHorizantal">
              <Grid xs sx={{ mx: '4px'}} >
              <Paper 
                  sx={{ width: '100%',
                        padding: "40px",
                        margin: "40px",
                      }}
                  elevation={3}
              >
                
                  <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>RECTRACK</h3 >
                  {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
                  <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
                  {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h3 > */}
                  <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>PROCUREMENT REQUISITION</h3 >

                  <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around"
                    }}
                    >
                    
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width:"100%",
                        marginBottom: "15px"
                      }}
                    >
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                        <TableHead>
                          <TableRow >
                            <TableCell align="left" colSpan={6}>
                              <b>Procurement Reference Number (Refer to the Procurement Guidelines):</b> 
                            </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell
                                // style={{  minWidth: 50}}
                              >
                                PD Entity Code
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 50 }}
                              >
                                Wrks/Srvcs/Supplies?
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Budget Year 
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Sequence Number 
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                ITB Number 
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Contract Number 
                              </TableCell>
                                                                
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          
                          <TableRow  role="checkbox" tabIndex={-1} >
                              <TableCell >
                                {meta_fields.description}
                              </TableCell>
                              <TableCell >
                                {meta_fields.budget_code}
                              </TableCell>
                              <TableCell >
                                {meta_fields.amount}
                              </TableCell>
                              <TableCell >
                                {meta_fields.amount}
                              </TableCell>
                              <TableCell >
                                {meta_fields.amount}
                              </TableCell>
                              <TableCell >
                                {meta_fields.amount}
                              </TableCell>
                          </TableRow>
                             
                        </TableBody>
                      </Table>
                      </TableContainer>
                      <br/>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                        <TableHead>
                          <TableRow >
                            <TableCell align="left" colSpan={6}>
                              <b>SUBJECT OF PROCUREMENT:  </b> {title}
                            </TableCell>
                          </TableRow>
                          <TableRow >
                            <TableCell align="left" colSpan={2}>
                              <b>LOCATION FOR DELIVERY:</b> 
                            </TableCell>
                            <TableCell align="left" colSpan={2}>
                              
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                              <b>Date Required:</b> 
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                              
                            </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell
                                style={{  minWidth: 50}}
                              >
                                Item No
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 50 }}
                                align="center"
                              >
                                Description <br/>
                                (A detailed list, Statement of Requirements or Stock Management Information may be attached)
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Quantity
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Unit of Measure
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Estimated Unit Cost
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                Estimated Total Cost
                              </TableCell>
                                                                
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          
                          { meta_fields.map((row, index) => {
                            return (
                              <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                <TableCell >
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {row.description}
                                </TableCell>
                                <TableCell >
                                  {row.quantity}
                                </TableCell>
                                <TableCell >
                                  {row.unit_of_measure}
                                </TableCell>
                                <TableCell >
                                  {row.estimated_unit_cost}
                                </TableCell>
                                <TableCell >
                                  {row.estimated_total_cost}
                                </TableCell>
                            </TableRow>
                            );
                            })}
                          <TableRow   >
                              <TableCell >
                                
                              </TableCell>
                              <TableCell colSpan={2} align="center">
                                <b>Currency:</b>
                              </TableCell>
                              <TableCell colSpan={2} align="center">
                                <b>Overall Estimated total cost:</b>
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>
                              

                            </TableRow>
                             
                        </TableBody>
                      </Table>
                      </TableContainer>

                      <p><b>BUDGET CODE:</b> </p>
                      <TableContainer sx={{ maxHeight: "100vh" }}>
                        <Table stickyHeader aria-label="sticky table" size="small" maxHeight="100vh">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                // style={{  minWidth: 50}}
                              >
                                PROGRAM
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 50 }}
                              >
                                PROJECT
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                DONOR 
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                ACTIVITY 
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                RESP/COST CENTRE
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                ANNUAL BUDGET
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                EXP. TO DATE
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                BUDGET BALANCE
                              </TableCell>
                              <TableCell
                                // style={{  minWidth: 150 }}
                              >
                                AMOUNT REQUIRED
                              </TableCell>                        
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow  role="checkbox" tabIndex={-1} >
                              <TableCell >
                                
                              </TableCell>
                              <TableCell >
                                {related_project}
                              </TableCell>
                              <TableCell >
                                CDC
                              </TableCell>
                              <TableCell >
                                {activity}
                              </TableCell>
                              <TableCell >
                              {cost_center}
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>
                              <TableCell >
                                
                              </TableCell>

                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <br/>

                      <p><span style={{ textAlign: "left"}}><b>Requested By:</b> </span><span style={{ textAlign: "center", marginLeft:"15px"}}> <b>Name:</b> {added_by} </span> <span> <b>Date:</b> {date_created}</span></p>
                      
                      <Button 
                          // value={row.id} 
                          size="small" 
                          variant="contained"
                          sx={{ 
                            width: "20%",
                            my: "15px",
                          }}
                          onClick={handleClickOpen}
                        >
                          Print Preview
                        </Button>

                        <ResponsiveDialog
                          dialogButtonName = "Add Comment "
                          dialogHeading = ""
                          open = {open}
                          handleClickOpen = {handleClickOpen}
                          handleClose = {handleClose}
                          negativeResponse = "Cancel"
                          content = {
                                      <ProcurementRequisitionPrintPreviewForm data={location}/>
                                    }
                        />
                    </Box>
                    
                    
                  </Box>
            
                
              </Paper>

              <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width:"100%",
                    marginBottom: "15px"
                  }}
                >
                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <h3 >Approval List</h3 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  minWidth: 50}}
                              >
                                Approval Step
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Approver Role
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                Approver 
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Status
                              </TableCell>
                              
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!approval_steps ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (approval_steps
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                return (
                                  <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                    
                                      <TableCell key={row.id}>
                                        {row.related_document_approval_step.approval_step_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.generic_role_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                      </TableCell>
                                      <TableCell key={row.id}>
                                        {
                                          row.status === 0 ? ("PENDING") 
                                          :
                                          row.status === 1 ? ("APPROVED") 
                                          :
                                          row.status === 2 ? ("REJECTED") 
                                          :
                                          row.status === 3 ? ("DEFERRED") 
                                          :
                                          null 
                                        }
                                      </TableCell>
                                    
                                  </TableRow>
                                );
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                              
                  </Paper>

                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <h3 >Comments</h3 >
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  minWidth: 50}}
                              >
                                No.
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                COMMENT
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                CREATED BY
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                DATE CREATED
                              </TableCell>
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!approval_steps ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (approval_steps
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              
                                if (row.approval_comment !== null) {
                                  return (
                                    <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                  
                                        <TableCell key={row.id}>
                                          {index + 1}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.approval_comment}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {row.related_approver.related_user.first_name} {row.related_approver.related_user.last_name}
                                        </TableCell>
                                        <TableCell key={row.id}>
                                          {moment(row.date_performed).format('L')}
                                        </TableCell>
                                      
                                    </TableRow>)
                                }  
                                
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>                   
                    
                  </Paper>

                  <Paper 
                      sx={{ width: '100%',
                            height: '50%',
                            padding: "10px",
                            margin: "40px",
                          }}
                      elevation={3}
                  >
                    
                    <Box
                      sx={{ 
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "10px",
                        }}
                    >
                      <h3 >Supporting Documents</h3 >
                      <Button 
                        variant="contained" 
                        size="small" 
                        onClick={handleOpenDocumentUpload}
                        sx={{ 
                          width: "20%",
                          my: "15px",
                        }}
                      >
                        Add Document
                      </Button>
                    </Box>
                    <ResponsiveDialog
                        dialogButtonName = "Add Document "
                        dialogHeading = "Add a Supporting Document"
                        open = {openDocumentUpload}
                        handleClickOpen = {handleOpenDocumentUpload}
                        handleClose = {handleCloseDocumentUpload}
                        negativeResponse = "Cancel"
                        content = {
                                    <Box
                                      p="1rem"
                                      borderRadius="0.5rem"
                                      backgroundColor={theme.palette.background.alt}
                                    >
                                      <DocumentUploadForm 
                                        setOpen={setOpenDocumentUpload}  setIsSuccess={setIsSuccess} uploadedDocumentType={"SUPPORTING DOCUMENTS"} uploadedDocumentCategory={1} relatedDocumentRequest={relatedDocumentRequest} setNewUploadedDocumentData={setNewUploadedDocumentData}
                                      />
                                    </Box>
                                  }
                      />
                      
                    <TableContainer sx={{ maxHeight: "100vh" }}>
                      <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                        <TableHead>
                          <TableRow>
                              <TableCell
                                style={{  width: 20}}
                              >
                                No.
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Date Uploaded
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 75 }}
                              >
                                File Name
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 150 }}
                              >
                                Description
                              </TableCell>
                              <TableCell
                                style={{  minWidth: 50 }}
                              >
                                Actions
                              </TableCell>
                                      
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uploadedDocumentList.length===0 ? (
                            
                              <TableRow>
                                <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                  <h3>No Data found</h3>
                                </TableCell>
                              </TableRow>
                            
                          ): (uploadedDocumentList
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              return (
                                <TableRow  role="checkbox" tabIndex={-1} key={index}>
                                  
                                    <TableCell key={row.id}>
                                      {row.id}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {moment(row.date_added).format('L')}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.file_name}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      {row.description}
                                    </TableCell>
                                    <TableCell key={row.id}>
                                      <Button 
                                        value={row.id} 
                                        size="small" 
                                        // onClick={(e) => handleClickEditOpen(e)}
                                      >
                                        Download 
                                      </Button>
                                    </TableCell>
                                  
                                </TableRow>
                              );
                            }))}
                        </TableBody>
                      </Table>
                    </TableContainer>                 
                    
                  </Paper>
                  
                  <Box
                    sx={{
                      display:"flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      padding: "10px",
                      marginX: "40px",
                    }}
                  >
                    <Button 
                      variant="contained"  
                      sx={{marginX:"5px"}}
                      
                    >
                      EDIT
                    </Button>

                    <Button 
                      variant="contained"  
                      color="error"
                      sx={{marginX:"5px"}}
                      
                    >
                      DELETE
                    </Button>
                    
                  </Box>
                    

                    
                  
                </Box>
              </Grid> 
      
            </Grid>  
      
          </Box>
      </Box>
    </Box>  

                      
                
  );

}

export default ProcurementRequisitionViewForm;