import {
    Box,
    TextField,
    Grid,
    Button,
    Paper,
  } from "@mui/material";
  
  import { useRef } from "react";
  import moment from "moment/moment";
  import { useLocation } from "react-router-dom";
  import Table from '@mui/material/Table';
  import TableBody from '@mui/material/TableBody';
  import TableCell from '@mui/material/TableCell';
  import TableHead from '@mui/material/TableHead';
  import TableRow from '@mui/material/TableRow';
  import TableContainer from '@mui/material/TableContainer';
  import ReactToPrint from "react-to-print";
 
 


  const MemoPrintPreviewForm = ({data}) => {

    
    const date_created = moment(data.state.related_document_request.date_added).format('LL');
    const title= data.state.title;
    const background= data.state.background;
    const purpose=data.state.purpose;
    const related_project=data.state.related_document_request.related_project_document.related_project.project_name;
    const approval_steps = data.state.approval_steps;
    const to = data.state.to;
    const persons_through = data.state.meta_fields;
    const componentRef = useRef();

    return (
      <>
        
        <Paper 
            sx={{ width: '95%'}}
            elevation={3}
        >
          <Box
              sx={{ width: '95%',
              padding: "20px",
              margin: "20px",
            }} 
              ref={componentRef}
          >
                  
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>REC-TRACK</h3 >
                    {/* <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>UGANDA CATHOLIC MEDICAL BUREAU</h3 > */}
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>{related_project}</h3 >
                    {/* <h4 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>NU2GGH002289-01</h4 > */}
                    <h3 style={{ textAlign: "center", margin:"0px", padding:"0px"}}>PROCUREMENT MEMO</h3 >

                    <p> <b>Date:</b> {date_created}</p>
                    <p> <b>To:</b> {to.related_user.first_name} {to.related_user.last_name}</p>
                    {!persons_through ? (
                              
                              null
                            
                          ): (persons_through
                            
                            .map((row, index) => {
                              return (
                                <p> <b>Thru:</b> {row.persons_through.related_project_role ? row.persons_through.related_project_rolerole_name : null}</p>
                              );
                            }))}
                    <p> <b>From:</b> Business Development Unit Coordinator</p>
                    <br/>
                    <p><b>Title:</b> { title}</p>
        
                    <Box
                      sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around"
                      }}
                      >
                      
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width:"100%",
                          marginBottom: "15px"
                        }}
                      >
                        <p><b>Background:</b></p>
                        {
                          background
                        }
                        <p><b>Purpose:</b></p>
                        {purpose}
                      </Box>
                      
                    </Box>
              
                    </Box>
                
                </Paper>
                <ReactToPrint
                  trigger={()=> 
                    <Button 
                      size="small" 
                      variant="contained"
                      sx={{ 
                        width: "20%",
                        my: "15px",
                      }}
                      
                    >
                      Print
                    </Button>  
                  }
                  content={()=>componentRef.current}
                />
                
              </>            
                  
    );

  }

  export default MemoPrintPreviewForm;