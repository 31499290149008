
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  MenuItem,
  Switch,
  FormControlLabel
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {v4 as uuidv4} from 'uuid';
import {  useState } from "react";
import moment from "moment";

const itemSchema = yup.object().shape({
  activity_name: yup.string().required("required"),
  start_date: yup.string().required("required"),
  end_date: yup.string().required("required"),
  is_recurring: yup.boolean(),
  recurring_frequency: yup.string(),
  has_report: yup.boolean(),
});


const ActivityCreateForm = (props) => {
  const pageType = props.formType;
  let {id, data} = props; 
  const userId = useSelector((state) => state.user.userId);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token =  useSelector((state) => state.token);
    
  const initialValuesItem = {
    activity_name: data.activity_name,
    start_date: data.start_date,
    end_date: data.end_date,
    is_recurring:data.is_recurring,
    recurring_frequency:data.recurring_frequency,
    has_report: data.has_report
  };


  const[addMoreUnits, setAddMoreUnits]= useState(false);


  const[addHasReports, setAddHasReports]= useState(false);
  
  const handleAddingMoreUnits = (e) => {
    
    if (e.target.checked === true ) {
      setAddMoreUnits(true)
    }
    if (e.target.checked === false ) {
      setAddMoreUnits(false)
    }
  };  

  const handleHasReports = (e) => {
    
    if (e.target.checked === true ) {
      setAddHasReports(true)
    }
    if (e.target.checked === false ) {
      setAddHasReports(false)
    }
  }; 
  

  const saveItem = async (values, onSubmitProps) => {  

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/activity/add", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    
    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewActivityData(true);
      onSubmitProps.resetForm();
      navigate("/activities");
      props.setIsSuccess(true);
    }
    if (savedItem.status===400){
      props.setIsFailedError(savedItem.error)
      props.setIsFailed(true);
      onSubmitProps.resetForm();
    }
  };


  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <TextField
              label="Activity Name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.activity_name}
              name="activity_name"
              error={
                Boolean(touched.activity_name) && Boolean(errors.activity_name)
              }
              helperText={touched.activity_name && errors.activity_name}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Start Date"
              onBlur={handleBlur}
              onChange={handleChange}
              type="date"
              value={values.start_date}
              name="start_date"
              error={Boolean(touched.start_date) && Boolean(errors.start_date)}
              helperText={touched.start_date && errors.start_date}
              sx={{ gridColumn: "span 2" }}
            />

            <TextField
              label="End Date"
              onBlur={handleBlur}
              type="date"
              onChange={handleChange}
              value={values.end_date}
              name="end_date"
              error={Boolean(touched.end_date) && Boolean(errors.end_date)}
              helperText={touched.end_date && errors.end_date}
              sx={{ gridColumn: "span 2" }}
            />

            <Box sx={{ gridColumn: "span 2", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.is_recurring = addMoreUnits}
                name="is_recurring"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleAddingMoreUnits(e)
                    }}
                  />
                }
                label="Is this a recurring activity?:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

            {addMoreUnits === true ? (
              <TextField
                label="Reccurring Frequency"
                onBlur={handleBlur}
                select
                onChange={handleChange}
                value={values.recurring_frequency}
                name="recurring_frequency"
                error={Boolean(touched.recurring_frequency) && Boolean(errors.recurring_frequency)}
                helperText={touched.recurring_frequency && errors.recurring_frequency}
                sx={{ gridColumn: "span 2" }}
              >
                <MenuItem  value="DAILY">DAILY</MenuItem>
                <MenuItem  value="WEEKLY">WEEKLY</MenuItem>
                <MenuItem  value="BI-WEEKLY">BI-WEEKLY</MenuItem>
                <MenuItem  value="MONTHLY">MONTHLY</MenuItem>
              </TextField>
            ) : (
              null
            )}

            <Box sx={{ gridColumn: "span 4", display:"flex", alignItems:"center" }}>
              <FormControlLabel
                value={values.is_recurring = addHasReports}
                name="is_recurring"
                control={
                  <Switch 
                    color="primary" 
                    onChange={ (e)=>{
                      handleChange(e)
                      handleHasReports(e)
                    }}
                  />
                }
                label="Does this Activity require a report?:  No"
                labelPlacement="start"
              />
              <Typography px="5px">Yes</Typography>
            </Box>

          </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ActivityCreateForm;
