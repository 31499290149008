import { Box, Typography, useTheme, useMediaQuery, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [isFailedError, setIsFailedError] = useState("");
  const [isFailed, setIsFailed]  = useState(false);
  
  useEffect(() =>{
    const timer = setTimeout(() => {
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isFailed]);




  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box
        width="100%"
        backgroundColor={theme.palette.background.secondary}
        textAlign="center"
        flexDirection="column"
        display="flex"
      >
        <Box
          sx={{
            marginTop: "16rem"
          }}
        >
          <Typography fontWeight="bold" fontSize="50px" color="#FFF">
            Rec Track
          </Typography>
          <Typography fontSize="25px" color="#FFF">
            Office Document Management, Monitoring, Budgeting, Evaluation and Productivity
          </Typography>
        </Box>
      </Box>

      <Box
        width={isNonMobileScreens ? "60%" : "100"}
        p="10rem"
        m="2rem auto"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5" sx={{ textAlign: "center", mb: "1.5rem" }}>
          Login
        </Typography>

        {
          isFailed ? (
              <Alert sx={{ my:"15px" }} variant="filled" severity="error">{isFailedError}</Alert>
          ) : null
                                          
        }

        <Form setIsFailed={setIsFailed} setIsFailedError={setIsFailedError}/>
      </Box>
    </Box>
  );
};

export default LoginPage;
