
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Autocomplete,
  useTheme,
  MenuItem, 
  Select, 
  InputLabel
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { odmasApi } from "api/pesabackend";
import { useDepartmentStationList, useProjectRoleList } from "FetchData";

const itemSchema = yup.object().shape({
  related_participant: yup.number(),
  related_project: yup.number(),
  related_station: yup.number(),
  related_project_role: yup.number()
  
});


const ParticipantAttachForm = (props) => {
  const pageType = props.formType;
  let {id, projectId, departmentId} = props; 
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const {projectRoleList, isLoadingProjectRoleList, setNewProjectRoleData, setSearchProjectRole} = useProjectRoleList(projectId)
  const {departmentStationList, isLoadingDepartmentStationList,} = useDepartmentStationList(1);
  const [employeeList, setEmployeeList] = useState([]);
	const [isLoadingEmployeeList, setLoadingEmployeeList] = useState(true);
	const [errorLoadingEmployeeList, setErrorLoadingEmployeeList] = useState(false);
	const [searchEmployee, setSearchEmployee] = useState(false);

  const fetchEmployeeList = async (search) => {
    try {
      const response = await odmasApi.get(`/tenants/departments/employees?tenant_id=1`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setEmployeeList(response.data.data);
      setLoadingEmployeeList(false);
    } catch (e) {
      setErrorLoadingEmployeeList(true);
      setLoadingEmployeeList(false);
    }
};

const [station, setStation] = useState('');

  const handleChangeStation = (event) => {
    setStation(event.target.value);
  };


  const token =  useSelector((state) => state.token);

  const initialValuesItem = {
    related_participant: "",
    related_station: "",
    related_project_role: "",
  };
  

  const saveItem = async (values, onSubmitProps) => {

    values.related_project = projectId

    const savedItemResponse = await fetch("https://api.rectrack.iolabz.ug/api/v1/projects/participants", {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}` 
      },
      body: JSON.stringify(values),
    });
    const savedItem = await savedItemResponse.json();

    if (savedItem.status===201) {
      props.setOpen(false);
      props.setNewProjectParticipantData(true);
      onSubmitProps.resetForm();
      props.setIsSuccess(true);
    }
  };


  

  const handleFormSubmit = async (values, onSubmitProps) => {
    saveItem(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValuesItem }
      validationSchema={itemSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <Autocomplete
              onOpen={ ()=>{
                  fetchEmployeeList('false');
              }}
              onBlur={handleBlur}
              name="related_participant"
              error={Boolean(touched.related_participant) && Boolean(errors.related_participant)}
              helperText={touched.related_participant && errors.related_participant}
              onChange={(e, value)=>{
                  // fetchUnitsForGivenItemList(value.id);
                  // handleChange(e);
                  setFieldValue(
                    "related_participant",
                    value.id
                  );
              }}
              sx={{ gridColumn: "span 4" }}
              options={employeeList}
              getOptionLabel={(option) => option.related_user.first_name + " " + option.related_user.last_name}
              renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.related_user.first_name} {option.related_user.last_name} 
                  </Box>
                )}
              renderInput={(params) => <TextField 
                                          {...params} 
                                          inputProps={{
                                              ...params.inputProps
                                          }}
                                          label="Particicpant Name"
                                          value={values.related_participant}
                                          // onChange={(e)=>{
                                          //     fetchEmployeeList(e.currentTarget.value);
                                          // }}
                                            />}
            />

            <TextField
              label="Project Role"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.related_project_role}
              select
              name="related_project_role"
              error={Boolean(touched.related_project_role) && Boolean(errors.related_project_role)}
              helperText={touched.related_project_role && errors.related_project_role}
              sx={{ gridColumn: "span 4" }}
            >
              {projectRoleList.map((row, index) => { 
                return ( 
                  <MenuItem  value={row.id}>{row.role_name}</MenuItem>
                ) 
              })}
            </TextField>

            

            <InputLabel id="demo-simple-select-label">Stationed At:</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onBlur={handleBlur}
              value={values.related_station=station}
              onChange={(e)=>{
                handleChange(e);
                handleChangeStation(e);
              }}
              error={
                Boolean(touched.related_station) && Boolean(errors.related_station)
              }
              helperText={touched.related_station && errors.related_station}
              sx={{ gridColumn: "span 3" }}
            >
              {departmentStationList.map((row, index) => { 
                return ( 
                  <MenuItem  value={row.id}>{row.station_name}</MenuItem>
                ) 
              })}
            </Select>
            

            
           
      </Box>

      {/* BUTTONS */}
      <Box>
        <Button
          fullWidth
          type="submit"
          sx={{
            m: "1rem 0",
            p: "1rem",
            backgroundColor: palette.primary.main,
            color: palette.background.alt,
            "&:hover": { color: palette.primary.main },
          }}
        >
          Save
        </Button>
      </Box>
        </form>
      )}
    </Formik>
  );
};

export default ParticipantAttachForm;
