import { Box, useTheme, Grid, Button, Paper, InputBase, Alert, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useProjectRoleList} from "FetchData";
import { useSelector } from "react-redux";
import { useState } from "react";
import NativeSelectDemo from "components/variantSelectDropdown";
import axios from "axios";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import moment from "moment";



export default function TravelMatrixTable ({departmentId}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState('');
  const [clickedItem, setClickedItem] = useState("");
  const[searchContent, setSearchContent] = useState("");
  const tracvelMatrix = [];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isSidebar, setIsSidebar] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed]  = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleClickEditOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(``);
    setEditData(response.data);
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false)
  };

  const handleClickDeleteOpen = async (e) => {
    const clickedItem = setClickedItem(e.target.value)
    const response = await axios.get(``);
    setOpenDelete(true)
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false)
  };

  const handleClickDelete = async () => {
    const response = await axios.post(`http://localhost:8000/inventory/products/delete/${clickedItem}`);
    setIsSuccess(true)
    setOpenDelete(false);
  };

  const handleClickViewDetails = async (e) => {
    
  };

  const handleSearchInput = async (e) => {
    setSearchContent(e.target.value)
  }
  
  const handleClickSearch = async () => {

  };


  return (
    <Box>
      <Navbar />
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
          container spacing={2}
        >
          <Box 
            sx={{
              flexGrow: 1              
            }}
          >
            <Sidebar isSidebar={isSidebar}/>  
          </Box>
          <Box 
            sx={{
              flexGrow: 999,
              ml: "10px"
            }}
          > 
               {
                isSuccess ? ( 
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="success">Operation was successful!</Alert>
                ) : isFailed ? (
                    <Alert sx={{ m:"15px" }} variant="outlined" severity="error">Operation was not successful!</Alert>
                ) : null
                                                
              }
              
              <h3>Travel Matrix List</h3>
              <Box
                p="1rem"
                m ="1rem"
                borderRadius="0.5rem"
                
              >
                <Grid container spacing={4} className="alignHorizantal">
                  {/* <Grid xs >
                    <Paper
                      sx={{ my: '4px' ,p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 170, maxWidth:300}}
                    >
                      <InputBase placeholder="Search Week" 
                        value={searchContent}
                        onChange={handleSearchInput}
                        sx={{ ml: 1, flex: 1 }}
                        
                      />
                      <IconButton
                        onClick={handleClickSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Grid> */}
                  <Grid xs >
                    <Box
                      sx={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "space-around",
                          // gridColumn: "span 4", 
                          m:"8px"
                      }}
                    >
                      <h4>Filter By Month:</h4>
                      <TextField
                        label="Month"
                        type="month"
                        // onChange={(e)=>{handleChangeStartDate(e)}}
                        value={startDate}
                        name="start_date"
                        size="small"
                        // inputProps={{ 
                        //   min: moment().format('YYYY-MM-DD')
                        //  }}
                        sx={{ mx: "10px"}}
                      />
                      <Button 
                        size="small" 
                        variant="contained" 
                        onClick={() => {
                          //  setSearchClockingDate({"start_date": startDate,"end_date":endDate}) 
                          }}
                        sx={{ mx: "10px", marginBottom:"23px"}}
                      >
                        Filter
                      </Button>
                    </Box>
                  </Grid>
                  <Grid xs sx={{ textAlign:'right' }}>
                    <Button variant="contained" sx={{ m: "10px",}} onClick={ () => navigate("/travelMatrixCreateForm")}>Create Travel Matrix</Button>
                    
                  </Grid>
                  
                </Grid>

              </Box>
            
            <Box>
              <Paper sx={{ width: '100%' }}>
                  <TableContainer sx={{ maxHeight: "100vh" }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                      <TableHead>
                        <TableRow>
                            <TableCell
                              style={{  minWidth: 75 }}
                            >
                              Week
                            </TableCell>
                            
                            <TableCell
                              style={{  minWidth: 75 }}
                            >
                              Year
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 150 }}
                            >
                              Period
                            </TableCell>
                            
                                                      
                            <TableCell style={{ minWidth: 100 }}>
                              Created By
                            </TableCell>
                            <TableCell
                              style={{  minWidth: 50 }}
                            >
                              Date Created
                            </TableCell>
                            <TableCell style={{ textAlign: "center", minWidth: 150 }}>
                              Actions
                            </TableCell>
                            
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tracvelMatrix.length===0  ? (
                          
                            <TableRow>
                              <TableCell colSpan={12} style={{ textAlign: "center" }}>
                                <h3>No Data found</h3>
                              </TableCell>
                            </TableRow>
                          
                        ): (tracvelMatrix
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                
                                  
                                  <TableCell key={row.id}>
                                    {row.date_added}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.fields.title}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.fields.title}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.related_project_document.related_region_project.station_name}
                                  </TableCell>
                                  <TableCell key={row.id}>
                                    {row.added_by.first_name} {row.added_by.last_name}
                                  </TableCell>
                                
                                  <TableCell style={{ textAlign: "center" }}>
                                      <Button 
                                        value={row.id} 
                                        size="small" 
                                        onClick={(e) => {
                                        handleClickViewDetails(e);
                                      }}
                                      >
                                          View Details 
                                      </Button>
                                    
                                  </TableCell>
                                
                              </TableRow>
                            );
                          }))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tracvelMatrix.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              </Paper>
            </Box>
   
          </Box>
        </Box>
    </Box>
  );
};